import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import { emailRegex } from "../contact/contact";
import WordBreak from "../../components/wordbreak";
import {
  useCreatResetPasswordSession,
  usePostResetPassword,
  useVerifyCode,
} from "../../query/useAuthentication";
import OtpInput from "react-otp-input";
import { passwordRegex } from "../signup/signup";
import PasswordInput from "../../components/input/passwordinput";
import { LogoLoading } from "../../components/loading/loading";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [sessionId, setSessionId] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const { mutate, isLoading } = useCreatResetPasswordSession();
  const { mutate: verifyCode, isLoading: verifyCodeLoading } = useVerifyCode();
  const { mutate: resetPassword, isLoading: resetPasswordLoading } =
    usePostResetPassword();

  const isMobile = window.innerWidth <= 768;

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    const data = {
      email,
    };

    mutate(data, {
      onSuccess: (res) => {
        toast.success("Verification code has been sent!");
        setStep(2);
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleVerifyOtp = async (e) => {
    e.preventDefault();
    verifyCode(
      {
        code: otp,
        email: email,
      },
      {
        onSuccess: (res) => {
          setSessionId(res.session_id);
          toast.success("Code has been verified!");
          setStep(3);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleConfirmPassword = async (e) => {
    e.preventDefault();
    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      toast.error("Invalid password");
      return;
    } else {
      setIsPasswordValid(true);
    }

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    resetPassword(
      {
        session_id: sessionId,
        password: password,
      },
      {
        onSuccess: (res) => {
          toast.success("Password reset successfully!");
          navigate("/sign-in");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (isLoading || verifyCodeLoading || resetPasswordLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full container min-h-screen flex xs:justify-center xs:items-center py-10">
      <div className="flex flex-col items-center md:justify-center mt-10 mb-5 w-full">
        <Link to="/">
          <img
            loading="lazy"
            src="/new-logo-with-sidetext.svg"
            alt="logo"
            className=""
          />
        </Link>
        {step === 1 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Reset your password
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              Enter your email address below and we&apos;ll send <WordBreak />{" "}
              you a verification code to verify your email.
            </p>
            <form onSubmit={handleSubmit} className="w-full max-w-md">
              <div className="flex flex-col mb-4">
                <label
                  htmlFor="email"
                  className="text-[#585866] font-montserrat text-base mb-2"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  name="email"
                  value={email}
                  placeholder="e.g. mail@domain.com"
                  onChange={(e) => setEmail(e.target.value)}
                  className="border border-gray-400 py-3.5 px-3 rounded-[4px] focus:outline-none focus:ring-2 focus:ring-secondary focus:border-transparent font-montserrat text-[13.6px]"
                  required
                />
              </div>
              <button
                type="submit"
                disabled={!email.trim()}
                className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-secondary active:scale-95 w-full"
              >
                {/* {isLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : (
                  "Send Verification Code"
                )} */}
                Send Verification Code
              </button>
            </form>
            <div className="mt-6">
              <Link
                to="/sign-in"
                className="text-[#4F46BA] hover:text-[#4338CA] font-semibold font-montserrat"
              >
                Back to login
              </Link>
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Enter Verification Code
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              We sent a code to {email}.
            </p>
            <div className="">
              <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span></span>}
                renderInput={(props) => <input {...props} />}
                containerStyle={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-between",
                  columnGap: "5px",
                }}
                inputStyle={{
                  width: isMobile ? "35px" : "50px",
                  height: isMobile ? "35px" : "50px",
                  borderRadius: "8px",
                }}
              />
              <div className="flex items-center gap-x-1 font-montserrat text-[14px] leading-7 mt-3">
                <p className="inline">Didn&apos;t receive a code?</p>
                <p
                  className="inline text-secondary underline cursor-pointer"
                  onClick={() => setStep(1)}
                >
                  Click to resend
                </p>
              </div>
            </div>
            <button
              disabled={otp.length < 6}
              className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-secondary active:scale-95 w-full max-w-[360px]"
              onClick={handleVerifyOtp}
            >
              {/* {verifyCodeLoading ? (
                <div className="flex items-center justify-center">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                </div>
              ) : (
                "Verify Code"
              )} */}
              Verify Code
            </button>
          </>
        )}
        {step === 3 && (
          <>
            <h1 className="md:text-[32px] font-bold font-opensans text-[#323131] mb-4 mt-4 text-center">
              Reset your password
            </h1>
            <p className="text-[#10182899] mb-10 text-center font-montserrat text-[15px] leading-7">
              Enter your new password below to reset your password.
            </p>
            <form onSubmit={handleConfirmPassword} className="w-full max-w-md">
              <PasswordInput
                label="New Password"
                onChange={(e) => setPassword(e.target.value)}
                value={password}
                isPasswordValid={isPasswordValid}
              />
              <br />
              <PasswordInput
                label="Confirm New Password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                value={confirmPassword}
              />
              <button
                type="submit"
                className="bg-secondary border border-secondary  text-white py-2.5 rounded-lg mt-3 transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-secondary active:scale-95 w-full"
              >
                {/* {resetPasswordLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                  </div>
                ) : (
                  "Reset Password"
                )} */}
                Reset Password
              </button>
            </form>
          </>
        )}
      </div>
    </div>
  );
};

export default VerifyOTP;

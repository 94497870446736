/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Navigate, Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

const RequireAuth = ({ children }) => {
  const token =
    sessionStorage.getItem("tempToken") || localStorage.getItem("token");
  const location = useLocation();
  return token ? (
    <Outlet />
  ) : (
    <Navigate to={{ pathname: "/sign-in", state: { from: location } }} />
  );
};

export default RequireAuth;

import Apimanager from "./axios";

export const getSeoKeywords = async () => {
  const res = await Apimanager.get(`/entity/seokeyword`);

  return res.data;
};

export const postAddSeoKeyword = async (data) => {
  const res = await Apimanager.post(`/entity/seokeyword`, data);

  return res.data;
};

export const postAddBulkSeoKeywords = async (data) => {
  const res = await Apimanager.post(`/entity/bulk_seokeyword`, data, {
    headers: {
      "Content-Type": "multipart-formdata",
    },
  });

  return res.data;
};

export const deleteSeoKeyword = async (data) => {
  const res = await Apimanager.delete(`/entity/seokeyword`, {
    data: data,
  });

  return res.data;
};

export const getMustSeoKeywords = async () => {
  const res = await Apimanager.get(`/entity/mustseokeyword`);

  return res.data;
};

export const postAddMustSeoKeyword = async (data) => {
  const res = await Apimanager.post(`/entity/mustseokeyword`, data);

  return res.data;
};

export const deleteMustSeoKeyword = async (data) => {
  const res = await Apimanager.delete(`/entity/mustseokeyword`, {
    data: data,
  });

  return res.data;
};

/* eslint-disable react/prop-types */
import { PlusIcon } from "@heroicons/react/24/outline";
import { faqData } from "../../data/faq";
import { AnimatePresence, motion } from "framer-motion";
import React, { createContext, useContext, useState } from "react";

const AccordionContext = createContext({});
const useAccordion = () => useContext(AccordionContext);

function Accordion({ children, multiple, defaultIndex }) {
  const [activeIndex, setActiveIndex] = useState(
    multiple ? [defaultIndex] : defaultIndex
  );

  function onChangeIndex(index) {
    setActiveIndex((currentActiveIndex) => {
      if (!multiple) {
        return index === activeIndex ? -1 : index;
      }

      if (currentActiveIndex.includes(index)) {
        return currentActiveIndex.filter((i) => i !== index);
      }

      return currentActiveIndex.concat(index);
    });
  }

  return React.Children.map(children, (child, index) => {
    const isActive =
      multiple && Array.isArray(activeIndex)
        ? activeIndex.includes(index)
        : activeIndex === index;

    return (
      <AccordionContext.Provider value={{ isActive, index, onChangeIndex }}>
        {child}
      </AccordionContext.Provider>
    );
  });
}

function AccordionItem({ children }) {
  return (
    <div className=" rounded-lg bg-[#f7f7f7] px-3 md:px-12 py-3.5 text-left text-base md:text-xl text-[#3e3e3e] hover:bg-[#1000c50a] group overflow-hidden cursor-pointer  md:-tracking-[1.2px] font-montserrat">
      {children}
    </div>
  );
}

function AccordionHeader({ children }) {
  const { isActive, index, onChangeIndex } = useAccordion();

  return (
    <motion.div
      className={`flex justify-between items-center font-montserrat ${
        isActive ? "active" : ""
      }`}
      onClick={() => onChangeIndex(index)}
    >
      {children}
      <PlusIcon
        className={`${
          isActive && "-rotate-45 text-primary"
        } w-5 h-5 transition-all duration-500 flex-shrink-0`}
      />
    </motion.div>
  );
}

function AccordionPanel({ children }) {
  const { isActive } = useAccordion();

  return (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ type: "spring", duration: 0.5, bounce: 0 }}
        >
          <div className="px-4 py-7 text-base md:text-lg font-normal">
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const HeadlessFaq = () => {
  return (
    <div className="w-full space-y-3">
      <Accordion>
        {faqData.map((faq, i) => (
          <AccordionItem key={i}>
            <AccordionHeader>{faq.question}</AccordionHeader>
            <AccordionPanel>{faq.answer}</AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default HeadlessFaq;

/* eslint-disable react/prop-types */
import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { toast } from "sonner";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import WordBreak from "../../components/wordbreak";
import { useNavigate } from "react-router";
import AboutUsImg1 from "/aboutUsImg1.png";
import AboutUsImg2 from "/aboutUsImg2.svg";
import AboutUsIcon1 from "/aboutUsIcon1.svg";
// import { ChartNoAxesCombined } from "lucide-react";
// import { v4 as uuidv4 } from 'uuid';
import { v4 as uuidv4 } from "uuid";
import {
  BadgeCheck,
  ChartNoAxesCombined,
  Command,
  Eye,
  Handshake,
  Lightbulb,
  LineChart,
  QrCode,
  Star,
  WalletCards,
} from "lucide-react";
import { motion } from "framer-motion";
import { SubscribeApi } from "../../api/subscribeApi";
const AboutUs = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const delays = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.1, 1.2]; // Array of delays
  const valuesData = [
    {
      img: AboutUsIcon1,
      title: "Vision",
      desc: "Our vision extends beyond the present. We aspire to become the go-to platform for one-to-one outreach, setting the industry standard for effectiveness.",
    },
    {
      img: AboutUsIcon1,
      title: "Team work",
      desc: "Our team is dedicated to streamlining your B2B sales Journey through our AI-driven, hyper-personalized email outreach, ensuring your business flourishes.",
    },
    {
      img: AboutUsIcon1,
      title: "Excellence",
      desc: "MagicPitch is committed to being the best in the market when it comes to outreach strategies. ",
    },
    {
      img: AboutUsIcon1,
      title: "Responsibility",
      desc: "Their goal is to help clients promote products or services effectively, build brand awareness, and achieve business objectives.",
    },
  ];

  const handleSubmit = async () => {
    setIsLoading(true);

    try {
      if (!emailRegex.test(email)) {
        toast.error("Invalid Email");
        setIsLoading(false);
        return;
      }

      if (email === "") {
        setIsLoading(false);
        toast.error("Email is required");
        return;
      }
      const data = {
        email: email,
      };
      const result = await SubscribeApi(data);

      if (result.status === 201) {
        toast.success("Subscribed Successfully");
        setEmail("");
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      toast.error("Something went wrong");
    } finally {
      setIsLoading(false);
    }
  };
  const handleNavigate = () => {
    navigate("/contact-us");
    window.scrollTo(0, 0);
  };

  const handleChange = (e) => {
    setEmail(e.target.value);

    if (e.target.value === "") {
      setEmailError("Email is required");
    } else {
      setEmailError(null);
    }
  };

  const IconWrapper = ({ children, color, isBottom = false }) => (
    <div
      className={`size-[59px] flex justify-center items-center rounded-[7px] ${isBottom && "mt-auto mb-0"}`}
      style={{
        backgroundColor: color + "29",
      }}
    >
      {children}
    </div>
  );

  return (
    <div className="flex flex-col min-h-screen w-full bg-white">
      <Helmet>
        <title>About Us - Magicreview</title>
        <meta
          name="description"
          content="Discover how magicreview simplifies feedback collection with innovative solutions. Our platform enhances engagement and drives growth for businesses of all sizes."
        />
        <link rel="canonical" href="https://www.magicreview.ai/about-us" />
      </Helmet>

      <Mainnav />
      <div className="w-full h-full container flex flex-col mb-24">
        <motion.h1
          className="text-2xl lg:text-[50px]  text-[#333333] text-center font-semibold font-poppins lg:leading-tight lg:-tracking-[1.296px] mt-[20px] lg:mt-[50px] mb-5"
          initial={{ opacity: 0.5, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          About our company
        </motion.h1>

        <motion.h3
          className="text-[#2D2D2D] font-poppins text-[17px] font-normal text-center mb-2"
          initial={{
            opacity: 0,
            y: 30,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.2 }}
        >
          {/* Welcome to MagicReview, where our AI-powered rapid review tool makes
          collecting reviews quick and easy.
          <WordBreak /> At MagicReview, we&apos;re dedicated to simplifying the
          feedback process for businesses and customers alike.
          <WordBreak /> Our goal is to change how reviews are gathered, making
          it efficient and effective. */}
          Welcome to MagicReview, where our AI-powered rapid review{" "}
          <WordBreak /> tool makes collecting reviews quick and easy.
        </motion.h3>
        <div className="flex flex-col lg:flex-row lg:items-center lg:justify-center gap-5 mt-5">
          <input
            type="text"
            name="email"
            placeholder="Enter your email"
            value={email}
            onChange={handleChange}
            className="lg:w-[340px] w-full font-poppins border border-[#C2C2C2] rounded-[8px] p-3  h-[45px]"
          />
          <button
            className=" text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 h-[45px] px-10 rounded-[8px] font-poppins"
            onClick={handleNavigate}
          >
            Contact Us
          </button>
        </div>
      </div>

      {/* <div className="bg-[#f1f4ff] text-black w-full py-24 container">
        <p className="font-poppins font-semibold text-2xl md:text-[38px] leading-[48px] -tracking-[6%]">
          About our Company on how to <WordBreak /> accelerate the startup
          company and <WordBreak /> built trust over a decade
        </p>

        <div className="flex lg:flex-nowrap flex-wrap justify-evenly md:h-[150px] mt-24 gap-5 ">
          <IconWrapper color="#299F49">
            <ChartNoAxesCombined
              className={`size-[50px]`}
              style={{
                color: "#299F49",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#4869DF" isBottom={true}>
            <QrCode
              className={`size-[50px]`}
              style={{
                color: "#4869DF",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#753EE9">
            <WalletCards
              className={`size-[50px]`}
              style={{
                color: "#753EE9",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#FFAF19" isBottom={true}>
            <Star
              className={`size-[50px]`}
              style={{
                color: "#FFAF19",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#2A49B8">
            <Handshake
              className={`size-[50px]`}
              style={{
                color: "#2A49B8",
              }}
            />
          </IconWrapper>
          <IconWrapper color="#E5484D" isBottom={true}>
            <Command
              className={`size-[50px]`}
              style={{
                color: "#E5484D",
              }}
            />
          </IconWrapper>
        </div>

        <div className="flex lg:no-flexwrap flex-wrap mt-24 gap-24 lg:gap-0">
          <div className="w-full lg:w-1/2 space-y-7 lg:pr-28">
            <p className="font-poppins font-semibold text-[31px] leading-[28.8px]">
              What We Do
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              MagicReview is an AI-powered rapid review tool that utilizes NFC
              and QR technology to initiate the review process. it efficiently
              gathers customer feedback through MCQs.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Our trained AI then transforms these responses into SEO-optimized
              reviews. MagicReview encourages more feedback and attracts
              customers with each review process taking just 30 seconds.
              We&apos;re simplifying the review collection process for
              businesses worldwide.
            </p>
            <button
              className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px] font-poppins font-semibold"
              onClick={handleNavigate}
            >
              Contact Us
            </button>
          </div>
          <div className="w-full lg:w-1/2 space-y-7 lg:pr-28">
            <p className="font-poppins font-semibold text-[31px] leading-[28.8px]">
              Our Journey
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              MagicReview was started in January 2024, with a bright idea born
              from observing the struggles businesses face in collecting
              valuable reviews.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Our team recognized the common challenge of customers often
              neglecting to leave reviews due to the tiring process.
            </p>
            <p className="font-poppins text-[17px] leading-[26px] tracking-[1%]">
              Determined to make a change, MagicReview was created to streamline
              this process using AI and help businesses with genuine,
              SEO-optimized review collections.
            </p>
            <button
              className="mt-5 text-white bg-newPrimary hover:bg-white border hover:text-newPrimary  border-newPrimary  transition-all duration-300 active:scale-95 py-3 px-6 rounded-[7px] font-poppins font-semibold"
              onClick={handleNavigate}
            >
              Contact Us
            </button>
          </div>
        </div>
      </div> */}

      <div className="bg-[#f1f4ff] text-black w-full py-8 lg:py-24 container">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5">
          <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px]">
            Our Mission
          </h2>
          <div className="space-y-2">
            <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-[#333]">
              Our goal at MagicReview is simple: to give businesses the tools
              they need to succeed in a tough market. We&apos;re changing how
              feedback is collected, making it easy for both businesses and
              customers.{" "}
            </p>
            <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-[#333]">
              With innovation, simplicity, and a focus on real feedback,
              we&apos;re helping businesses everywhere thrive. Come join us in
              our mission to change the way feedback works and utilise the power
              of customer reviews with MagicReview.
            </p>
          </div>
        </div>
        <div className="w-full bg-newPrimary rounded-[20px] p-5 lg:p-16 mt-5 lg:mt-10">
          <div className="flex items-center justify-between flex-wrap gap-5">
            <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px] text-white">
              Who we are & how <WordBreak /> we got here?
            </h2>
            <button
              className="flex items-center justify-center gap-2 px-10 py-3 rounded-[12px] border border-newPrimary text-newPrimary transition-all duration-300 bg-white font-poppins w-full lg:w-fit"
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
            >
              Contact Us
            </button>
          </div>
          <div className="lg:pl-40 mt-5 lg:mt-10">
            <div className="space-y-5 ">
              <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-white">
                MagicReview is an AI-powered rapid review tool that utilises NFC
                and QR technology to initiate the review process. MagicReview
                encourages more feedback and attracts customers with each review
                process taking just 30 seconds. We&apos;re simplifying the
                review collection process for businesses worldwide.
              </p>
              <p className="font-poppins text-[17px] lg:leading-[26px] lg:tracking-[1%] text-white">
                MagicReview was started in Feb 2024, with a bright idea born
                from observing the struggles businesses face in collecting
                valuable reviews. Our team recognised the common challenge of
                customers often neglecting to leave reviews due to the tiring
                process. Determined to make a change, MagicReview was created to
                streamline this process using AI and help businesses with
                genuine, SEO-optimized review collections.
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="w-full py-24">
        <div className="container">
          <div className="text-[#333333] font-poppins w-full flex flex-col items-center text-center lg:w-[59%] mx-auto ">
            <h1 className="text-[37px] font-semibold mb-[31px]">Our Mission</h1>
            <p className="text-base">
              Our goal at MagicReview is simple: to give businesses the tools
              they need to succeed in a tough market. We&apos;re changing how
              feedback is collected, making it easy for both businesses and
              customers. With innovation, simplicity, and a focus on real
              feedback, we&apos;re helping businesses everywhere thrive. Come
              join us in our mission to change the way feedback works and
              utilize the power of customer reviews with MagicReview.
            </p>
          </div>
        </div>
      </div> */}
      <div className="w-full py-8 lg:py-24 container">
        <h2 className="font-poppins font-semibold text-2xl lg:text-[38px] lg:leading-[46px]">
          Our Values
        </h2>
        <div className="space-y-5 mt-5 lg:mt-24">
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#299F49">
                <ChartNoAxesCombined
                  className={`size-8`}
                  style={{
                    color: "#299F49",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Empowerment
              </p>
              <p className="font-poppins text-[17px]">
                Stress enabling businesses to achieve more through simplified
                and automated review management.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#4869df">
                <Eye
                  className={`size-8`}
                  style={{
                    color: "#4869df",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Transparency
              </p>
              <p className="font-poppins text-[17px]">
                Highlight honesty in processes, data usage, and communication.
              </p>
            </div>
          </div>
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#ffaf19">
                <Lightbulb
                  className={`size-8`}
                  style={{
                    color: "#ffaf19",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Innovation
              </p>
              <p className="font-poppins text-[17px]">
                Showcase a drive to adopt and create cutting-edge AI technology.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#CF3EE9">
                <Handshake
                  className={`size-8`}
                  style={{
                    color: "#CF3EE9",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Customer-Centric Approach
              </p>
              <p className="font-poppins text-[17px]">
                Emphasize a commitment to understanding and exceeding customer
                expectations.
              </p>
            </div>
          </div>
          <div className="flex gap-5 lg:flex-row flex-col">
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[55.5%] w-full space-y-5">
              <IconWrapper color="#753EE9">
                <BadgeCheck
                  className={`size-8`}
                  style={{
                    color: "#753EE9",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Quality Assurance
              </p>
              <p className="font-poppins text-[17px]">
                Underline a commitment to delivering consistent and exceptional
                results.
              </p>
            </div>
            <div className="bg-[#f1f4ff] rounded-[20px] lg:p-10 p-5 lg:w-[44.5%] w-full space-y-5">
              <IconWrapper color="#E5484D">
                <Command
                  className={`size-8`}
                  style={{
                    color: "#E5484D",
                  }}
                />
              </IconWrapper>
              <p className="font-poppins font-semibold text-[22px]">
                Collaboration
              </p>
              <p className="font-poppins text-[17px]">
                Highlight working with clients and partners to tailor solutions
                for unique needs.
              </p>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AboutUs;

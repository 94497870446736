import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getBuyCardHistory,
  getCancleSubscription,
  getCharges,
  getCheckCoupon,
  getCheckoutUrl,
  getInvoiceHistory,
  getNumberOfDaysLeft,
  postBuyCard,
  postDownloadInvoice,
  postToggleAutoRenew,
  postUpdatePaymentStatus,
} from "../api/paymentApi";
import { data } from "autoprefixer";

export const useGetCheckoutUrl = () => {
  return useQuery({
    queryKey: ["getCheckoutUrl"],
    queryFn: () => getCheckoutUrl(),
  });
};

export const useGetCheckoutUrlMutation = () => {
  return useMutation({
    mutationKey: ["getCheckoutUrlMutation"],
    mutationFn: () => getCheckoutUrl(),
  });
};

export const usePostUpdatePaymentStatus = () => {
  return useMutation({
    mutationKey: ["postUpdatePaymentStatus"],
    mutationFn: () => postUpdatePaymentStatus(),
  });
};

export const useGetNumberOfDaysLeft = () => {
  return useQuery({
    queryKey: ["getNumberOfDaysLeft"],
    queryFn: () => getNumberOfDaysLeft(),
  });
};

export const useGetInvoiceHistory = () => {
  return useQuery({
    queryKey: ["getInvoiceHistory"],
    queryFn: () => getInvoiceHistory(),
  });
};

export const useGetCancleSubsciption = () => {
  return useMutation({
    mutationKey: ["getCancleSubscription"],
    mutationFn: () => getCancleSubscription(),
  });
};

export const useGetCheckCoupon = (params, config) => {
  return useQuery({
    queryKey: ["getCheckCoupon", params],
    queryFn: () => getCheckCoupon(params),
    ...config,
    retry: 0,
    enabled: Boolean(params?.coupon),
  });
};

export const usePostToggleAutoRenew = () => {
  return useMutation({
    mutationKey: ["postToggleAutoRenew"],
    mutationFn: () => postToggleAutoRenew(),
  });
};

export const useGetCharges = () => {
  return useQuery({
    queryKey: ["getCharges"],
    queryFn: () => getCharges(),
  });
};

export const usePostDownloadInvoice = () => {
  return useMutation({
    mutationKey: ["postDownloadInvoice"],
    mutationFn: (id) => postDownloadInvoice(id),
  });
};

export const usePostBuyCard = () => {
  return useMutation({
    mutationKey: ["postBuyCard"],
    mutationFn: (data) => postBuyCard(data),
  });
};

export const useGetBuyCardHistory = () => {
  return useQuery({
    queryKey: ["getBuyCardHistory"],
    queryFn: () => getBuyCardHistory(),
  });
};

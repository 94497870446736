export const supportedLanguageLocales = [
  "ar-AE",
  "ar-BH",
  "ar-DZ",
  "ar-EG",
  "ar-IQ",
  "ar-JO",
  "ar-KW",
  "ar-LY",
  "ar-MA",
  "ar-OM",
  "ar-QA",
  "ar-SA",
  "ar-SY",
  "ar-YE",
  "bn-IN",
  "bg-BG",
  "ca-ES",
  "zh-CN",
  "zh-HK",
  "zh-TW",
  "hr-HR",
  "cs-CZ",
  "da-DK",
  "nl-NL",
  "en-AU",
  "en-CA",
  "en-GH",
  "en-HK",
  "en-IN",
  "en-IE",
  "en-KE",
  "en-NZ",
  "en-NG",
  "en-PH",
  "en-SG",
  "en-ZA",
  "en-TZ",
  "en-GB",
  "en-US",
  "et-EE",
  "fi-FI",
  "fr-CA",
  "fr-FR",
  "de-DE",
  "el-GR",
  "gu-IN",
  "he-IL",
  "hi-IN",
  "hu-HU",
  "id-ID",
  "ga-IE",
  "it-IT",
  "ja-JP",
  "kn-IN",
  "ml-IN",
  "ko-KR",
  "lv-LV",
  "lt-LT",
  "mt-MT",
  "mr-IN",
  "nb-NO",
  "pl-PL",
  "pt-BR",
  "pt-PT",
  "ro-RO",
  "ru-RU",
  "sk-SK",
  "sl-SI",
  "es-AR",
  "es-BO",
  "es-CL",
  "es-CO",
  "es-CR",
  "es-CU",
  "es-DO",
  "es-EC",
  "es-SV",
  "es-GQ",
  "es-GT",
  "es-HN",
  "es-MX",
  "es-NI",
  "es-PA",
  "es-PY",
  "es-PE",
  "es-PR",
  "es-ES",
  "es-UY",
  "es-US",
  "es-VE",
  "sv-SE",
  "ta-IN",
  "te-IN",
  "th-TH",
  "tr-TR",
  "uk-UA",
  "vi-VN",
];

export const pricingData = [
  "Instant tap to get AI generated reviews",
  "Real-Time Tracking of Customer Reviews",
  "Hassle-Free Review Collection under 30 seconds",
  "Faster Reviews Without Manual Typing",
];

export const csvData = [
  ["keyword1"],
  ["keyword2"],
  ["keyword3"],
  ["keyword4"],
  ["keyword5"],
];

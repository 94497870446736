import { showReportDialog, captureMessage } from "@sentry/react";
import { useEffect, useState } from "react";
import { Button } from "../../components/ui/button";
const SentryReportButton = () => {
  const handleOpenSentryReport = () => {
    const eventID = captureMessage("Bug report");
    showReportDialog({
      eventId: eventID,
    });
  };

  return (
    <>
      {window.location.origin.includes("magic-review-dev.vercel.app") ? (
        <Button
          onClick={handleOpenSentryReport}
          className="fixed left-5 bottom-5 z-[1000]"
          variant="destructive"
          shape="pill"
        >
          Report a Problem
        </Button>
      ) : null}
    </>
  );
};

export default SentryReportButton;

import WordBreak from "../../../components/wordbreak";
import { useOnBoarding } from "../../../context/onBoardingContext";
const Welcome = () => {
  const { handleSelectedScreen } = useOnBoarding();
  return (
    <div className="w-full flex flex-col justify-center items-center pt-[70px]">
      <img
        src="/new-logo.svg"
        alt=""
        className="w-[110px] h-[110px] object-contain mb-[36px]"
      />
      <h1 className="text-center text-[#323131] text-[26px] md:text-[45px] font-bold md:leading-[51px] mb-[21px]">
        Welcome <WordBreak /> to Magicreview
      </h1>
      <p className="text-[#10182899] font-montserrat md:leading-[28px] text-[15px] mb-[20px] text-center">
        Enter the fields below to get started
      </p>
      <button
        className="max-w-[333px] py-2 w-full bg-secondary text-white rounded-[9px] font-montserrat text-base leading-[28px]"
        onClick={() => handleSelectedScreen("questionsscreen")}
      >
        Get Started
      </button>
    </div>
  );
};

export default Welcome;

import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, EffectFade } from "swiper/modules";
import { Link } from "react-router-dom";
// import style from "../component/slider.module.scss";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-fade";
// eslint-disable-next-line react-refresh/only-export-components
export const HomeSliderData = [
  {
    name: "Musthafa",
    role: "Marketing Manager of Lallummas Restaurant",
    description:
      'As the Marketing Manager of Lallummas Restaurant, I believe Magic Review is an excellent tool for generating reviews that can enhance both our SEO and ratings. It addresses a common issue where restaurant reviews are often short and lack detail like "good food" or "great service". This system provides a solution by encouraging more content-rich feedback. The only challenge is guiding customers through the copy-paste process, but with our team\'s support, we can easily manage it. Appreciate the innovation behind this platform it\'s truly a problem solver!',
    image: "/lallummas.png",
  },
  {
    name: "Cecille",
    role: "Primestay",
    description:
      "MagicReview is a friendly tool, Easy use and access. Primestay team didn't encounter any issues using it. Would really recommend to any company especially for Hospitality Industry.",
    image: "/primestay.svg",
  },
  {
    name: "Prasun",
    role: "Store Manager of Mila Gold and Diamonds",
    description:
      "Being a shop manager at Mila Gold and Diamonds, I strongly believe MagicReview is excellent tool for generating reviews. On other hand it makes very easy to customers to put their reviews. MagicReview Team is doing great work.",
    image: "/mila.png",
  },
];

const HomeSlider = () => {
  const swiperRef = useRef(null);
  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handlePaginationClick = () => {};
  return (
    <div className="w-full flex justify-between items-center max-w-[1100px] landscape:min-h-[90vh] portrait:min-h-[40vh] mx-auto">
      <div
        className="prev-button cursor-pointer border bg-[#F1F4FF] w-fit rounded-[4px] sm:rounded-[12px] sm:px-[14px] py-[14px] sm:py-[32px] hidden xxs:flex"
        onClick={handlePrev}
      >
        <img
          loading="lazy"
          className="w-[9px] xxs:w-fit"
          src="/homeleftarr.svg"
          alt="homeleftarr"
        />
      </div>
      <Swiper
        ref={swiperRef}
        className="max-w-[300px] sm:max-w-[849px] w-full mx-auto mb-[40px] "
        autoHeight={true}
        // className={`mt-6 ${style.slider}`}
        spaceBetween={16}
        slidesPerView={1}
        loop={true}
        navigation={{
          nextEl: ".next-button",
          prevEl: ".prev-button",
        }}
        // pagination={{
        //   clickable: true,
        //   renderBullet: function (index, className) {
        //     return '<span class="' + className + '"></span>'; // Only the dots are rendered
        //   },
        // }}
        fadeEffect={{ crossFade: true }}
        modules={[Pagination, EffectFade]}
        effect="fade"
        onSwiper={(swiper) => {
          // Add event listener for pagination click
          swiper.on("paginationClick", handlePaginationClick);
        }}
      >
        {HomeSliderData.map((item, index) => (
          <SwiperSlide
            key={index}
            className="w-full bg-[#4F75FA] rounded-[5.8px] sm:rounded-[20px] flex flex-col px-[15px] md:px-[75px] py-[22px] md:py-[52px] font-poppins relative flex-grow h-auto justify-between"
          >
            <div>
              <img
                src="/leftquote.svg"
                alt="leftquote"
                className="w-[28px] md:w-[72px] mb-[10px] sm:mb-[28px]"
              />
              <p className="text-[12px] md:text-xl text-white w-full font-normal mb-[19px] sm:mb-[50px] font-poppins capitalize">
                {item.description}
              </p>
            </div>

            <div className="flex flex-col lg:flex-row gap-3 md:gap-7 lg:items-center">
              <img src={item.image} alt="happywoman" className="w-[100px]" />
              <div className="flex flex-col">
                <p className="text-white text-[12px] xxs:text-base md:text-xl font-semibold">
                  {item.name}
                </p>
                <p className="text-white text-[10px] xxs:text-sm md:text-lg font-normal">
                  {item.role}
                </p>
              </div>
            </div>
            {/* <img
              src="/slideprism.png"
              alt="prism"
              className="absolute -bottom-[16px] left-[50%] transform -translate-x-[50%]"
            /> */}

            {/* <img
              src="/rightquote.svg"
              alt="rightquote"
              className="absolute w-[28px] h-[29px] md:w-[71px] md:h-[71px] bottom-[125px] xxs:bottom-[100px] md:bottom-[125px] right-6"
            /> */}
          </SwiperSlide>
        ))}
      </Swiper>
      <div
        className="next-button cursor-pointer border bg-[#F1F4FF] w-fit rounded-[4px] sm:rounded-[12px] sm:px-[14px] py-[14px] sm:py-[32px] hidden xxs:flex"
        onClick={handleNext}
      >
        <img
          loading="lazy"
          className="w-[9px] xxs:w-fit"
          src="/homerightarr.svg"
          alt="homerightarr"
        />
      </div>
    </div>
  );
};

export default HomeSlider;

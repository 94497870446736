/* eslint-disable react/prop-types */
import { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { LoadingButton } from "../../../components/loading/loading";
import { Pencil, X } from "lucide-react";
import { toast } from "sonner";
import {
  usePostAddQuestionCategories,
  usePutEditQuestionCategories,
} from "../../../query/useEntity";

const CategoryDropdownForModal = ({
  selectedCategory,
  setSelectedCategory,
  setQuestion,
  isOpenDeleteCategory,
  setIsOpenDeleteCategory,
  setDeleteCategorySlug,
  categories,
  refetchCategory,
}) => {
  const categoryRef = useRef(null);
  const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [openCategoryEdit, setOpenCategoryEdit] = useState("");
  const [editCategory, setEditCategory] = useState("");
  const { mutate: addCategory, isLoading: addCategoryLoading } =
    usePostAddQuestionCategories();
  const { mutate: updateCategory, isLoading: editCategoryLoading } =
    usePutEditQuestionCategories();

  const handleAddCategory = () => {
    addCategory(
      {
        category: selectedCategory,
      },
      {
        onSuccess: (res) => {
          toast.success("Added new category successfully");
          refetchCategory();
          setSelectedCategory(res?.question_category?.category);
          setQuestion((prev) => {
            const current = { ...prev };
            current.category_link = res?.question_category?.id;
            return current;
          });
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateCategory = () => {
    updateCategory(
      {
        category: editCategory,
        category_slug: openCategoryEdit,
      },
      {
        onSuccess: (res) => {
          toast.success("Updated category successfully");
          setOpenCategoryEdit("");
          refetchCategory();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"]);
          } else {
            toast.error("Something went wrong");
          }
        },
      }
    );
  };
  useOnClickOutside({
    ref: categoryRef,
    setIsOpen: setOpenSuggestionBox,
    prevent: isOpenDeleteCategory,
  });

  useEffect(() => {}, []);
  return (
    <div className="space-y-3">
      <p className="text-base lg:text-[20px] dark:text-white">Category</p>
      <div className="flex items-center gap-x-5 w-full">
        <div className="relative max-w-md w-full" ref={categoryRef}>
          <input
            autoFocus={false}
            value={selectedCategory || ""}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedCategory(value);

              // If the input is empty, reset the category
              if (!value) {
                setQuestion((prev) => {
                  const current = { ...prev };
                  current.category_link = null;
                  return current;
                });
                setSelectedCategory(null); // Equivalent to selecting "No selected category"
              }
            }}
            onClick={() => {
              setOpenSuggestionBox((prev) => !prev);
              //   setOpenKeywordSuggestionBox(false);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddCategory();
              }
            }}
            autoComplete="off"
            id="category"
            placeholder="Select Category or Add New Category"
            className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:text-white dark:bg-darkLightBg dark:border-none"
          />

          {openSuggestionBox && (
            <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white dark:text-white dark:bg-darkLightBg dark:border-none z-[60] overflow-y-auto scrollbar-thin shadow-xl border ">
              <div
                className="py-2 hover:text-secondary cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setQuestion((prev) => {
                    const current = { ...prev };
                    current.category_link = null;
                    return current;
                  });
                  setSelectedCategory(null);
                  setOpenSuggestionBox(false);
                }}
              >
                - No selected category -
              </div>
              {
                <>
                  {categories?.length > 0 ? (
                    <>
                      {categories
                        ?.filter(
                          (item) =>
                            item.category
                              .toLowerCase()
                              .indexOf(
                                selectedCategory
                                  ? selectedCategory.toLowerCase()
                                  : ""
                              ) > -1
                        )
                        ?.map((item) => (
                          <div
                            key={item.id}
                            className="flex justify-between items-center"
                          >
                            {openCategoryEdit === item?.category_slug ? (
                              <div className="flex justify-between items-center mb-3 w-full gap-x-2">
                                <input
                                  type="text"
                                  placeholder="Edit category"
                                  className="border border-gray-300 px-3 py-1.5 rounded-lg w-full dark:bg-darkBg"
                                  value={editCategory}
                                  onChange={(e) =>
                                    setEditCategory(e.target.value)
                                  }
                                />
                                <button
                                  className="flex items-center justify-center rounded-[7px] border border-[#AAA] text-white text-base text-center font-medium px-2.5 py-1.5 bg-[#7190FF]"
                                  onClick={handleUpdateCategory}
                                >
                                  {editCategoryLoading ? (
                                    <LoadingButton />
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div
                                className="py-2 hover:text-secondary cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setQuestion((prev) => {
                                    const current = { ...prev };
                                    current.category_link = item?.id;
                                    return current;
                                  });
                                  setSelectedCategory(item?.category);
                                  setOpenSuggestionBox(false);
                                }}
                              >
                                {item?.category}
                              </div>
                            )}
                            <div className="flex justify-center items-center gap-x-2 text-gray-600 dark:text-white">
                              {openCategoryEdit !== item?.category_slug && (
                                <div className="flex gap-x-2">
                                  <Pencil
                                    className="w-4 h-4 cursor-pointer"
                                    onClick={() => {
                                      setOpenCategoryEdit(item?.category_slug);
                                      setEditCategory(item?.category);
                                    }}
                                  />
                                  <X
                                    className="w-4 h-4 cursor-pointer text-red-500"
                                    onClick={() => {
                                      setIsOpenDeleteCategory(true);
                                      setDeleteCategorySlug(
                                        item?.category_slug
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className="py-5">No result!</div>
                  )}
                </>
              }
            </div>
          )}
        </div>
        <button
          className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-2.5 px-4 bg-newPrimary"
          onClick={handleAddCategory}
          disabled={addCategoryLoading}
        >
          {addCategoryLoading ? <LoadingButton /> : "Add"}
        </button>
      </div>
    </div>
  );
};

export default CategoryDropdownForModal;

import React from "react";
import Layout from "../../dashboard/component/Layout";
import CreditsRequestTable from "./CreditsRequestTable";
import { useGetCreditsRequest } from "../../../query/useMaster";

const CreditsRequestPending = () => {
  const {
    data: pendingRequests,
    isLoading: pendingRequestsLoading,
    refetch,
  } = useGetCreditsRequest({
    status: "pending",
  });

  return (
    <Layout title={"Credits Request Pending"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-125px)] px-5 lg:px-[30.2px] font-pro ">
        <CreditsRequestTable
          items={pendingRequests?.data}
          isLoading={pendingRequestsLoading}
          isPendingSection={true}
          refetch={refetch}
        />
      </div>
    </Layout>
  );
};

export default CreditsRequestPending;

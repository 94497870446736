/* eslint-disable react/prop-types */

import { X } from "lucide-react";
import { useNavigate } from "react-router";

const CustomTooltipForJoyride = ({
  backProps,
  continuous,
  index,
  size,
  isLastStep,
  primaryProps,
  skipProps,
  step,
  tooltipProps,
  closeProps,
}) => {
  return (
    <div
      {...tooltipProps}
      className="bg-white w-[330px] rounded-[14px] p-7 relative overflow-hidden font-pro text-black"
    >
      <X className="absolute top-5 right-5 text-[#888]" {...closeProps} />
      <div className="space-y-2.5">
        {step.icon && <>{step.icon}</>}
        {step.title && (
          <p className="font-medium text-xl text-black">{step.title}</p>
        )}
        {step.content && <p className="text-[#4a4a4a]">{step.content}</p>}
      </div>
      <div className="flex items-center justify-between mt-2.5">
        <p className="text-[#888]">
          {index + 1} of {size}
        </p>

        {/* {index > 0 && <button {...backProps}>Back</button>} */}
        {step.referrer ? (
          <button
            {...primaryProps}
            className="px-5 py-2 bg-newPrimary rounded-lg text-white"
            onClick={() => {
              step.referrerFunc();
            }}
          >
            {step.referrer}
          </button>
        ) : (
          <button
            {...primaryProps}
            className="px-5 py-2 bg-newPrimary rounded-lg text-white"
          >
            {continuous && index + 1 !== size ? "Next" : "Close"}
          </button>
        )}
      </div>
    </div>
  );
};

export default CustomTooltipForJoyride;

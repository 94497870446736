import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { useState, useRef, useEffect } from "react";
import { scrollToSection } from "../../components/navigate";
import { Helmet } from "react-helmet";
const TermsAndConditions = () => {
  const acceptance = useRef(null);
  const privacy = useRef(null);
  const userRegistration = useRef(null);
  const userConduct = useRef(null);
  const changes = useRef(null);
  const termination = useRef(null);
  const disclaimer = useRef(null);
  const limitation = useRef(null);
  const governing = useRef(null);
  const contact = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  const isElementInView = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  const handleScroll = () => {
    const sections = [
      acceptance.current,
      privacy.current,
      userRegistration.current,
      userConduct.current,
      changes.current,
      termination.current,
      disclaimer.current,
      limitation.current,
      governing.current,
      contact.current,
    ];
    for (let i = 0; i < sections.length; i++) {
      if (sections[i] && isElementInView(sections[i])) {
        setActiveSection(i + 1); // Set the active section (1-based index)
        break;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Helmet>
        <link
          rel="canonical"
          href="https://www.magicreview.ai/terms-and-conditions"
        />
      </Helmet>
      <div className="w-full h-full">
        <Mainnav />

        <div className="w-full container">
          <div className="md:sticky md:top-[88px] md:bg-white md:z-10 md:pt-20 md:pb-7">
            <h3 className="text-center text-newPrimary font-opensans text-base font-bold">
              Terms And Conditions
            </h3>
            <h1 className="text-[#333] text-center text-2xl md:text-5xl font-bold mt-4 font-opensans md:leading-[63.2px] md:-tracking-[1.296px]">
              Navigating the Rules of Engagement
            </h1>
            <p className="mt-4 text-[#10182899] text-center font-montserrat font-medium text-base">
              Updated April 03, 2023
            </p>
          </div>
          <div className="w-full flex mt-[100px]">
            {/* side menu */}
            <div className="w-[30%] sticky h-full max-h-[418px] top-1/2 hidden md:flex">
              <div className="w-full md:flex flex-col pb-10">
                <button
                  onClick={() => scrollToSection(acceptance)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 1
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Acceptance of Terms
                </button>
                <button
                  onClick={() => scrollToSection(privacy)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 2
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Privacy and Data Usage
                </button>
                <button
                  onClick={() => scrollToSection(userRegistration)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 3
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  User Registration
                </button>
                <button
                  onClick={() => scrollToSection(userConduct)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 4
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  User Conduct
                </button>
                <button
                  onClick={() => scrollToSection(changes)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 5
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Changes to Terms and Services
                </button>
                <button
                  onClick={() => scrollToSection(termination)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 6
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Termination
                </button>
                <button
                  onClick={() => scrollToSection(disclaimer)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 7
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Disclaimer
                </button>
                <button
                  onClick={() => scrollToSection(limitation)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 8
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Limitation of Liability
                </button>
                <button
                  onClick={() => scrollToSection(governing)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 9
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Governing Law
                </button>
                <button
                  onClick={() => scrollToSection(contact)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 10
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Contact Us
                </button>
              </div>
            </div>

            <div className="w-full md:w-[70%] flex flex-col">
              <div
                id="1"
                ref={acceptance}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Acceptance of Terms
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  By accessing or using magicreview&apos;s review collection
                  card and analytics platform, you agree to be bound by these
                  Terms and Conditions. If you do not agree with any part of
                  these Terms, please refrain from using our Services.
                </p>
              </div>
              <div id="2" ref={privacy} className="w-full md:w-[91%] mb-[60px]">
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Privacy and Data Usage
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  Your use of our Services is also governed by our Privacy
                  Policy, which outlines how we collect, use, and protect your
                  personal information. By using our Services, you consent to
                  the practices described in the Privacy Policy.
                </p>
              </div>

              <div
                id="3"
                ref={userRegistration}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  User Registration
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-6">
                  To access certain features of our Services, you may be
                  required to register an account. You agree to provide accurate
                  and up-to-date information during the registration process.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  You are responsible for maintaining the confidentiality of
                  your account credentials and for any activity that occurs
                  under your account. Notify us immediately of any unauthorized
                  access or use of your account.
                </p>
              </div>
              <div
                id="4"
                ref={userConduct}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  User Conduct
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-6">
                  The content and materials available through our Services,
                  including but not limited to text, graphics, logos, and
                  software, are protected by intellectual property laws. You may
                  not reproduce, modify, or distribute such content without
                  prior written consent from magicreview.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  magicreview and its logo are trademarks owned by us. You may
                  not use these trademarks without our written permission.
                </p>
              </div>
              <div id="5" ref={changes} className="w-full md:w-[91%] mb-[60px]">
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Changes to Terms and Services
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-6">
                  We may modify these Terms from time to time. Any changes will
                  be effective immediately upon posting on our platform. It is
                  your responsibility to review these Terms periodically.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  We reserve the right to modify, suspend, or discontinue any
                  aspect of our Services at any time without notice.
                </p>
              </div>
              <div
                id="6"
                ref={termination}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Termination
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-6">
                  We may, in our sole discretion, terminate or suspend your
                  access to our Services for any reason, including violation of
                  these Terms.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  Upon termination, your right to use our Services will cease
                  immediately, and any content associated with your account may
                  be deleted.
                </p>
              </div>
              <div
                id="7"
                ref={disclaimer}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Disclaimer
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  Our Services are provided &quot;as is&quot; and &quot;as
                  available.&quot; We make no warranties or representations
                  regarding the accuracy, availability, or reliability of our
                  Services.
                </p>
              </div>
              <div
                id="8"
                ref={limitation}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Limitation of Liability
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  magicreview and its affiliates shall not be liable for any
                  indirect, incidental, special, or consequential damages
                  arising out of or in connection with your use of our Services.
                </p>
              </div>
              <div
                id="9"
                ref={governing}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Governing Law
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  These Terms are governed by and construed in accordance with
                  the laws of United Arabic Emirates, without regard to its
                  conflict of law principles.
                </p>
              </div>
              <div
                id="10"
                ref={contact}
                className="w-full md:w-[91%] mb-[60px]"
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Contact Us
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  If you have any questions or concerns about these Terms and
                  Conditions, please contact us at magicreview@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;

// import { LazyLoadImage } from "react-lazy-load-image-component";
/* eslint-disable react/prop-types */
import WordBreak from "../../components/wordbreak";
import { useMemo, useState } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useNavigate } from "react-router";
import MainNav from "../../components/mainNavbar/MainNav";
import { ChevronUpIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { motion, AnimatePresence } from "framer-motion";
import React, { createContext, useContext } from "react";
import { useGetCheckoutUrl, usePostBuyCard } from "../../query/usePayment";
import HomeSlider from "../../components/slider/homeslider";
import Footer from "../../components/footer/footer";
import { useGetPlans } from "../../query/useSubscription";
import { toast } from "sonner";

const featuresData = [
  {
    title: "Instant tap to get AI generated reviews",
    img: "/sound.svg",
  },
  {
    title: "Real-time tracking of customer reviews",
    img: "/bluestar.svg",
  },
  {
    title: "Hassle-free review collection under 30 seconds",
    img: "/sand.svg",
  },
  {
    title: "Faster reviews without manual typing",
    img: "/write.svg",
  },
];

const orderData = [
  {
    image: "/exit.svg",
    content: "Sign up with basic details and verify via email.",
  },
  {
    image: "/note.svg",
    content: "Log in, and fill in business information and preferences.",
  },
  {
    image: "/paymethod.svg",
    content: "Choose your payment method and complete the transaction.",
  },
  {
    image: "/embed.svg",
    content: "Receive Link Embedded Card delivered to your doorstep.",
  },
];

const AccordionContext = createContext({});
const useAccordion = () => useContext(AccordionContext);

function Accordion({ children, multiple, defaultIndex }) {
  const [activeIndex, setActiveIndex] = useState(
    multiple ? [defaultIndex] : defaultIndex
  );

  function onChangeIndex(index) {
    setActiveIndex((currentActiveIndex) => {
      if (!multiple) {
        return index === activeIndex ? -1 : index;
      }

      if (currentActiveIndex.includes(index)) {
        return currentActiveIndex.filter((i) => i !== index);
      }

      return currentActiveIndex.concat(index);
    });
  }

  return React.Children.map(children, (child, index) => {
    const isActive =
      multiple && Array.isArray(activeIndex)
        ? activeIndex.includes(index)
        : activeIndex === index;

    return (
      <AccordionContext.Provider value={{ isActive, index, onChangeIndex }}>
        {child}
      </AccordionContext.Provider>
    );
  });
}

function AccordionItem({ children }) {
  return (
    <div className=" py-3.5 text-left text-base md:text-xl   group overflow-hidden cursor-pointer  md:-tracking-[1.2px] font-poppins border-b border-[#e5e5e5]">
      {children}
    </div>
  );
}

function AccordionHeader({ children }) {
  const { isActive, index, onChangeIndex } = useAccordion();

  return (
    <motion.div
      className={`flex justify-between text-lg text-[#1E1E1E] dark:text-white items-center font-poppins ${
        isActive ? "active" : ""
      }`}
      onClick={() => onChangeIndex(index)}
    >
      {children}
      {isActive ? (
        <ChevronUpIcon
          className={`w-5 h-5 transition-all duration-500 flex-shrink-0`}
        />
      ) : (
        <ChevronDownIcon
          className={`w-5 h-5 transition-all duration-500 flex-shrink-0`}
        />
      )}
    </motion.div>
  );
}

function AccordionPanel({ children }) {
  const { isActive } = useAccordion();

  return (
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: "auto" }}
          exit={{ height: 0 }}
          transition={{ type: "spring", duration: 0.5, bounce: 0 }}
        >
          <div className="py-4 text-base md:text-lg font-normal space-y-1.5">
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const Ads = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dashboard = location.pathname.includes("dashboard");
  const [qty, setQty] = useState(1);

  const handleIncreaseQty = () => {
    setQty((prev) => prev + 1);
  };

  const handleDecreaseQty = () => {
    if (qty > 1) {
      setQty((prev) => prev - 1);
    }
  };

  const { mutate: buyCard, isLoading: buyCardLoading } = usePostBuyCard();

  const cardOneMotion = {
    hover: {
      rotate: [-14, 0],
      transition: {
        duration: 0.4,
      },
    },
  };

  const cardTwoMotion = {
    hover: {
      rotate: [12, 0],
      transition: {
        duration: 0.4,
      },
    },
  };

  const { data: plans } = useGetPlans();
  const currency = useMemo(() => {
    return plans?.data?.[0]?.currency;
  }, [plans]);

  const cardPrice = (quantity) => {
    switch (currency) {
      case "AED":
        return (
          <span>
            {(30 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "AED",
            })}
          </span>
        );

      case "GBP":
        return (
          <span>
            {(7 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "GBP",
            })}
          </span>
        );
      case "INR":
        return (
          <span>
            {(500 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "INR",
            })}
          </span>
        );
      default:
        return (
          <span>
            {(10 * quantity).toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
            })}
          </span>
        );
    }
  };

  const cardPriceForApi = () => {
    switch (currency) {
      case "AED":
        return 30;
      case "GBP":
        return 7;
      case "INR":
        return 500;
      default:
        return 10;
    }
  };

  const handleGoToBuyCard = () => {
    buyCard(
      {
        currency: currency,
        price_per_card: cardPriceForApi(),
        quantity: qty,
      },
      {
        onSuccess: (res) => {
          window.location.href = res?.url;
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  return (
    <>
      {!dashboard && (
        <>
          {" "}
          <Helmet>
            <title>
              Google Review Card - With NFC- Collect Hassle-Free Reviews
            </title>
            <meta
              name="title"
              content="Google Review Card  - With NFC- Collect Hassle-Free Reviews"
            />
            <meta
              name="description"
              content="Google Review Card for instant AI-generated reviews. Collect feedback quickly and effortlessly with MagicReview's NFC cards. Try now and enjoy 50% off!"
            />
            <meta
              name="keywords"
              content="Google Review Card , Google Review Cards ,  NFC Review Card , AI Review Card , AI-generated reviews card , nfc google review card"
            />
            <link
              rel="canonical"
              href="https://www.magicreview.ai/google-reviews-card"
            />
          </Helmet>
          <MainNav />
        </>
      )}
      <div
        className={`w-full ${!dashboard ? "container py-10 lg:py-20" : "py-5 pr-5"}`}
      >
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-x-5">
          <motion.div className="relative h-[300px] md:h-[350px] mt-10 lg:mt-20 mb-10 scale-90 md:scale-75 md:w-[600px] cursor-pointer group">
            <motion.img
              src="/card2.jpg"
              className="w-[330px] md:w-[400px] rounded-3xl drop-shadow-2xl relative z-20 -rotate-[14deg] group-hover:rotate-0 transition-transform duration-500 ease-in-out"
              // whileHover={{ rotate: 0 }}
              // transition={{ duration: 0.4, ease: "easeIn" }}
            />
            <motion.img
              src="/card1.jpg"
              className="w-[330px] md:w-[400px] rounded-3xl drop-shadow-2xl rotate-[14deg] absolute right-0 xl:right-[90px] bottom-5 group-hover:rotate-0 transition-transform duration-500 ease-in-out"
              // initial={{ rotate: 14 }}
              // whileHover={{ rotate: 0 }}
              // transition={{ duration: 0.4, ease: "easeIn" }}
            />
          </motion.div>

          <div className="space-y-10 font-poppins">
            <h1 className="font-poppins font-medium text-3xl lg:leading-[45px]">
              AI-Powered
              <WordBreak /> Google Review Card
              {/* <br className="hidden lg:block" /> Card */}
            </h1>
            {/* <div>
              <p className="text-[#222222] dark:text-white text-[14px] mt-[30px] line-through">
                AED {(qty > 9 ? qty * 56.0 : qty > 4 ? qty * 64.0 : 249.0) * 2}
              </p>
              <p className="text-[#222222] dark:text-white text-[18px]  font-semibold">
                AED {qty > 9 ? qty * 56.0 : qty > 4 ? qty * 64.0 : 249.0}{" "}
                <span className=" font-normal">({qty}cards)</span>
              </p>

              <div className="flex gap-[31px] items-center font-poppins mb-[27px] mt-[20px]">
                <p className="text-[18px] text-[#1F1E1E] dark:text-white">
                  Flat
                </p>
                <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-[18px] ">
                  50% OFF{" "}
                </button>
              </div>
              <p className="text-[#1F1E1E] dark:text-white text-[17px] font-medium">
                for the first 100 customers.
              </p>
            </div> */}

            {/* {country === "Gulf" && ( */}
            {/* <p className="font-poppins lg:text-[18px] lg:leading-[36px]">
              Get more than 4 cards at Dhs. 64.00 and more <WordBreak /> than 9
              cards at Dhs. 56.00 with custom printing <WordBreak /> and free
              shipping
            </p> */}
            {/* )} */}
            <div className="space-y-5">
              <p className="font-poppins text-base font-medium lg:leading-[36px]">
                Quantity
              </p>
              <div className="flex items-center">
                <button
                  className="text-center w-[57px] h-[57px] border bordder-[#e5e5e5] rounded-[5px]"
                  onClick={handleDecreaseQty}
                >
                  -
                </button>
                <input
                  type="number"
                  value={qty}
                  disabled
                  className="border-none w-16 pl-5 dark:text-white bg-transparent dark:bg-darkBg"
                />
                <button
                  className="text-center w-[57px] h-[57px] border bordder-[#e5e5e5] rounded-[5px]"
                  onClick={handleIncreaseQty}
                >
                  +
                </button>
              </div>
              <div className="flex justify-between items-center font-poppins font-medium text-base lg:leading-[46px]">
                <p>{qty} Cards</p>
                {/* {country === "Gulf" ? (
                  <p>
                    AED {qty > 9 ? qty * 56.0 : qty > 4 ? qty * 64.0 : 249.0}
                  </p>
                ) : country === "Greenwich" ? (
                  <p>GBP {qty > 4 ? qty * Math.round(55.0 / 3) : 55.0}</p>
                ) : (
                  <p>USD {qty > 4 ? qty * Math.round(68.0 / 3) : 68.0}</p>
                )} */}
                <p>{cardPrice(qty)}</p>
              </div>
              <p className="text-base text-[#1F1E1E] font-poppins md:hidden flex">
                MagicReview is an AI review card that simplifies the process for
                your customers to give genuine reviews, helping your business
                increase sales and rank on Google search.
              </p>
              <button
                className="bg-newPrimary border border-newPrimary  text-white py-5 text-sm rounded-[45px]  transition-all duration-300  font-montserrat font-medium hover:bg-white hover:text-newPrimary active:scale-95 w-full"
                onClick={handleGoToBuyCard}
              >
                Checkout
              </button>
            </div>
            {/* features */}
            <div className="flex flex-col space-y-[15px]">
              <h2 className=" font-poppins text-[#1F1E1E] dark:text-white text-[14px] font-medium">
                Features
              </h2>
              <div className="flex gap-3 flex-col text-[#1F1E1E] dark:text-white text-base font-poppins">
                {featuresData.map((feature, i) => (
                  <div
                    key={i}
                    className="flex items-center gap-x-[18px] text-[13px]"
                  >
                    <img loading="lazy" src={feature.img} />
                    <p>{feature.title}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="space-y-3 md:flex hidden flex-col">
              <Accordion>
                <AccordionItem>
                  <AccordionHeader>
                    <h1 className="text-sm font-medium">Description</h1>
                  </AccordionHeader>
                  <AccordionPanel>
                    <p className="text-[#595959] dark:text-white text-[13px]">
                      Weight: 23 grams
                    </p>
                    <p className="text-[#595959] dark:text-white text-[13px]">
                      Dimensions: 9cm (horizontal) x 5cm (vertical)
                    </p>
                    <p className="text-[#595959] dark:text-white text-[13px]">
                      Thickness: 1.5 mm
                    </p>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader>
                    <h1 className="text-sm font-medium">
                      Shipping information
                    </h1>
                  </AccordionHeader>
                  <AccordionPanel>
                    <p className="text-[#595959] dark:text-white text-[13px]">
                      Orders are printed & shipped within 72 hours of your
                      order.
                    </p>
                  </AccordionPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionHeader>
                    <h1 className="text-sm font-medium">Ask a question</h1>
                  </AccordionHeader>
                  <AccordionPanel>
                    <form
                      action=""
                      className="w-full  md:flex hidden flex-col text-[13px]"
                    >
                      <div className="flex flex-wrap justify-between w-full text-[#262626] text-base gap-3">
                        <div className="flex flex-col w-full lg:w-[48%] gap-2 ">
                          <label
                            htmlFor=""
                            className="text-[#595959] text-[13px] dark:text-white"
                          >
                            Name
                          </label>
                          <input
                            type="text"
                            className="rounded-[5px] border border-[#D9D9D9]"
                          />
                        </div>
                        <div className="flex flex-col w-full lg:w-[48%] gap-2 ">
                          <label
                            htmlFor=""
                            className="text-[#595959] dark:text-white text-[13px]"
                          >
                            Email
                          </label>
                          <input
                            type="text"
                            className="rounded-[5px] border border-[#D9D9D9]"
                          />
                        </div>
                        <div className="flex flex-col w-full gap-2 ">
                          <label
                            htmlFor=""
                            className="text-[#595959] dark:text-white text-[13px]"
                          >
                            Message
                          </label>
                          <textarea
                            name=""
                            id=""
                            cols="30"
                            rows="3"
                            className="rounded-[5px] border border-[#D9D9D9] resize-none"
                          ></textarea>
                        </div>
                      </div>
                      <button className="bg-newPrimary border border-newPrimary  text-white py-[9px] rounded-[45px]  transition-all duration-300  font-montserrat text-base font-medium hover:bg-white hover:text-newPrimary dark:hover:bg-darkLightBg active:scale-95 max-w-[157px] w-full mt-5">
                        Send
                      </button>
                    </form>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>
            {/* form */}
          </div>
        </div>
        {!dashboard && (
          <div className="w-full mt-[100px]">
            {/* howitworks */}
            <h2 className="text-[24px] md:text-[50px] text-[#333333] font-semibold font-poppins text-center">
              How it Works?
            </h2>
            <p className="text-center text-[#2D2D2D] font-poppins">
              Trusted By 500+ Companies over UAE
            </p>
            <div className="w-full grid grid-cols-2 lg:grid-cols-4 gap-10 mt-[130px]">
              <div className="flex flex-col items-center lg:-translate-y-[50px]">
                <div className="max-w-[160px] w-full flex items-center justify-center border-2 border-[#CCD7FF] bg-[#F1F4FF] p-10 rounded-[12px] relative">
                  <img loading="lazy" src="/sound.svg" className="w-full" />
                  <p className="absolute -top-5 -left-5 bg-[#4F75FA] text-white font-semibold font-poppins rounded-full w-[40px] h-[40px] text-center flex justify-center items-center">
                    {" "}
                    1{" "}
                  </p>
                </div>

                <h2 className="text-[20px] md:text-[24px] text-[#333333] font-semibold font-poppins mt-5">
                  Tap
                </h2>
                <p className="text-center text-[##1F1E1E] text-[14px] md:text-base font-poppins mt-3">
                  Just tap your MagicReview NFC Card on your customer&apos;s
                  phone.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="max-w-[160px] w-full flex items-center justify-center border-2 border-[#CCD7FF] bg-[#F1F4FF] p-10 rounded-[12px] relative">
                  <img loading="lazy" src="/paychoose.svg" className="w-full" />
                  <p className="absolute -top-5 -left-5 bg-[#4F75FA] text-white font-semibold font-poppins rounded-full w-[40px] h-[40px] text-center flex justify-center items-center">
                    {" "}
                    2{" "}
                  </p>
                </div>

                <h2 className="text-[20px] md:text-[24px] text-[#333333] font-semibold font-poppins mt-5">
                  Choose
                </h2>
                <p className="text-center text-[##1F1E1E] text-[14px] md:text-base font-poppins mt-3">
                  The customer can select the category to be reviewed.
                </p>
              </div>
              <div className="flex flex-col items-center">
                <div className="max-w-[160px] w-full flex items-center justify-center border-2 border-[#CCD7FF] bg-[#F1F4FF] p-10 rounded-[12px] relative">
                  <img loading="lazy" src="/howanswer.svg" className="w-full" />

                  <p className="absolute -top-5 -left-5 bg-[#4F75FA] text-white font-semibold font-poppins rounded-full w-[40px] h-[40px] text-center flex justify-center items-center">
                    {" "}
                    3{" "}
                  </p>
                </div>

                <h2 className="text-[20px] md:text-[24px] text-[#333333] font-semibold font-poppins mt-5">
                  Answer
                </h2>
                <p className="text-center text-[##1F1E1E] text-[14px] md:text-base font-poppins mt-3">
                  Feedback is shared quickly with a few simple MCQs.
                </p>
              </div>
              <div className="flex flex-col items-center lg:-translate-y-[50px]">
                <div className="max-w-[160px] w-full flex items-center justify-center border-2 border-[#CCD7FF] bg-[#F1F4FF] p-10 rounded-[12px] relative">
                  <img loading="lazy" src="/bluestar.svg" className="w-full" />

                  <p className="absolute -top-5 -left-5 bg-[#4F75FA] text-white font-semibold font-poppins rounded-full w-[40px] h-[40px] text-center flex justify-center items-center">
                    {" "}
                    4{" "}
                  </p>
                </div>

                <h2 className="text-[20px] md:text-[24px] text-[#333333] font-semibold font-poppins mt-5">
                  Review
                </h2>
                <p className="text-center text-[##1F1E1E] text-[14px] md:text-base font-poppins mt-3">
                  Get a well-written review crafted by AI based on those
                  feedbacks.
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {!dashboard && (
        <>
          <div className="w-full bg-[#F1F4FF] py-[80px] mt-[20px]">
            <div className="container font-poppins">
              <h2 className="text-center text-[#333333] font-semibold text-[24px] mb-2 md:text-[50px]">
                How to place an Order?
              </h2>
              <p className="text-center text-base md:text-lg text-[#2D2D2D]">
                Simplify Review Collection With Your Custom AI Review Card.
              </p>
              <div className="flex flex-col justify-center mt-[50px] gap-5">
                {orderData.map((order, i) => (
                  <div
                    key={i}
                    className="flex items-center gap-[17px] md:gap-[42px] max-w-[800px] w-full mx-auto border border-[#CCD7FF] bg-[#4F75FA] rounded-[15px] p-5 md:pl-[55px]"
                  >
                    <img loading="lazy" src={order.image} />
                    <p className="text-white text-sm md:text-xl font-normal font-poppins">
                      {order.content}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="w-full font-poppins container pb-[50px] md:pb-[150px] mt-[50px] md:mt-[100px]">
            <h2 className="font-semibold tracking-wide leading-snug text-[24px] md:text-[40px] text-center mb-5 text-[#333333]">
              Here is why you&apos;ll love <WordBreak /> working with us
            </h2>
            <p className="text-center text-[14px] md:text-base mb-[30px] text-[#2D2D2D]">
              More reviews mean more customers. Authentic feedback builds{" "}
            </p>
            <HomeSlider />
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default Ads;

const blobToFile = async (blobUrl, fileName) => {
  // Fetch the Blob from the Blob URL
  const response = await fetch(blobUrl);

  // Get the Blob object from the response
  const blob = await response.blob();

  // Convert the Blob to a File object
  const file = new File([blob], fileName, { type: blob.type });

  return file;
};

export default blobToFile;

import React from "react";
import WordBreak from "../../../../components/wordbreak";

export const QuestionsIllustrations = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[50vh] bg-white dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/questions.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[30px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
        Add Questions in the list
      </h1>
      <p className="mt-3 text-[#888888] dark:text-white text-center font-pro">
        Click on “Add Question” Button to get <WordBreak /> started on adding your
        questions to the list
      </p>
    </div>
  );
};


import { useState } from "react";
import InputBox from "./components/InputBox";
import { useGetPlans, usePostSubscribe } from "../../query/useSubscription";
import Footer from "../../components/footer/footer";
import { useGetIndustryTypes, useSignUp } from "../../query/useAuthentication";
import { toast } from "sonner";
import { emailRegex } from "../contact/contact";
import { LoadingButton } from "../../components/loading/loading";
import LoaderIcon from "react-loader-icon";
import {
  useGetQuestionsForVsl,
  usePostGenerateReviewsForVsl,
} from "../../query/useVsl";

const data = [
  "Pre-programmed Cards",
  "Instant tap to get AI generated reviews",
  "Real-Time Tracking of Customer Reviews",
  "Hassle-Free Review Collection under 30 seconds",
  "Faster Reviews Without Manual Typing",
];

const Vsl = () => {
  const [selectedService, setSelectedService] = useState("hotel");
  const [selectedQuestionId, setSelectedQuestionId] = useState([]);
  const [name, setName] = useState("");
  const [businessName, setBusinessName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [industry, setIndustry] = useState("hotel");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [selectedPlan, setSelectedPlan] = useState(0);
  const [answers, setAnswers] = useState({});
  const [reviews, setReviews] = useState();
  const [readMore, setReadMore] = useState(false);

  const { data: plans } = useGetPlans();
  const { data: questions, isLoading: questionsLoading } =
    useGetQuestionsForVsl({
      industryType: selectedService,
    });
  const { data: industryTypes } = useGetIndustryTypes({
    vsl_industry_type: true,
  });
  const { mutate: signup, isLoading: isSignUpLoading } = useSignUp();
  const { mutate: subscribe, isLoading: subscribeLoading } = usePostSubscribe();
  const { mutate: generateReviewsForVsl, isLoading: generateReviewLoading } =
    usePostGenerateReviewsForVsl();

  const handleSubmit = (planId) => {
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    if (password !== confirmPassword) {
      toast.error("Password does not match");
      return;
    }

    let data = {
      email: email,
      password: password,
      first_name: name?.split(" ")?.[0],
      last_name: name?.split(" ")?.[1] || "",
      entity: {
        name: businessName,
        place_id: null,
        industry: industry,
        company_link: null,
        google_review_link: null,
      },
      referral_code: null,
    };

    signup(data, {
      onSuccess: (data) => {
        localStorage.setItem("token", data.token);
        toast.success("Account created successfully");
        handleSubscribe(planId);
        setName("");
        setEmail("");
        setBusinessName("");
        setIndustry("");
        setPassword("");
        setPhone("");
      },
      onError: (error) => {
        if (error?.response?.data?.entity?.google_review_link) {
          toast.error(
            error.response.data.entity.google_review_link[0].split(".")[0] +
              " for google review link."
          );
        } else if (error?.response?.data?.entity?.company_link) {
          toast.error(
            error.response.data.entity.company_link[0].split(".")[0] +
              " for website link."
          );
        } else {
          toast.error(error.response.data.message);
        }
      },
    });
  };

  const handleSubscribe = (planId) => {
    subscribe(
      {
        plan: planId,
        coupon: null,
      },
      {
        onSuccess: (res) => {
          toast.success("Redirecting to checkout url");
          window.location.href = res.url;
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error("Cannot redirect to checkout url!");
          }
        },
      }
    );
  };

  const handleAnswerClick = (questionIndex, answer) => {
    setSelectedQuestionId((prev) => [...prev, questionIndex]);
    setAnswers((prev) => ({
      ...prev,
      [questionIndex]: answer,
    }));
  };

  const handleGenerateReview = () => {
    const sentQuestions = questions
      ?.filter((item) => selectedQuestionId.includes(item.question))
      ?.map((item) => {
        const selectedAnswer = answers[item.question];
        return {
          question_id: item.question_id,
          question: item.question,
          category: item.category,
          keywords: [item.keyword],
          response_options: item.response_options,
          selected_response: selectedAnswer,
        };
      });

    generateReviewsForVsl(
      { response: sentQuestions },
      {
        onSuccess: (res) => {
          toast.success("Reviews generated successfully");
          setReviews(res);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  return (
    <div className="min-h-screen bg-[#4F75FA] p-5 lg:p-20">
      <div className="bg-white min-h-[90vh] rounded-[16px] py-8 px-5 lg:p-12">
        <img src="/new-logo-with-sidetext.svg" alt="" className="w-[180px]" />
        <p className="font-poppins font-semibold text-2xl text-center -leading-4 mt-5">
          How it Works
        </p>
        <p className="font-poppins text-xs text-[#2d2d2d] text-center mt-2">
          Trusted By 500+ Companies over UAE{" "}
        </p>
        <iframe
          src="https://www.youtube.com/embed/V-ZplHf441E?si=FhNCiX1UxhlheIL2"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
          className="w-full h-[180px] rounded-xl mt-7 lg:w-[560px] lg:h-[315px] lg:mx-auto"
        ></iframe>
        <p className="font-poppins font-semibold text-2xl text-center mt-5">
          Try it out
        </p>
        <p className="font-poppins font-semibold text-[13px] mt-5 hidden lg:block text-center">
          Note:{" "}
          <span className="text-[#FE2121]">
            This is a basic representation of how our MagicReview service works;
            questions and output can be fully customised, and reviews generated
            will be unique every time.
          </span>
        </p>
        <p className="font-poppins font-semibold text-[13px] mt-5 lg:hidden">
          Note:{" "}
          <span className="text-[#FE2121]">
            {readMore
              ? "This is a basic representation of how our MagicReview service works; questions and output can be fully customised, and reviews generated will be unique every time."
              : "This is a basic representation of how our MagicReview service works; questions"}
          </span>
        </p>
        <p
          className="font-poppins text-xs mt-1 lg:hidden"
          onClick={() => setReadMore((prev) => !prev)}
        >
          {readMore ? "Read less" : "Read more..."}
        </p>
        <div className="flex items-center lg:justify-center gap-x-3 mt-5 w-full overflow-x-auto scrollbar-none">
          {industryTypes?.map((service, i) => (
            <button
              key={i}
              onClick={() => setSelectedService(service?.industry_identifier)}
              className={`px-5 py-3 rounded-lg font-heebo capitalize flex-shrink-0 ${selectedService === service?.industry_identifier ? "bg-[#4f75fa] text-white" : "bg-[#f1f4ff]"}`}
            >
              {service?.display_text}
            </button>
          ))}
        </div>
        <div className="space-y-5">
          {questionsLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "80vh",
              }}
            >
              <LoaderIcon type="cylon" color="#7190FF" />
            </div>
          ) : (
            questions?.map((item) => (
              <div key={item.question_id}>
                <p className="font-poppins font-semibold text-2xl mt-10">
                  {item?.question}
                </p>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 mt-5">
                  {item?.response_options?.map((answer, i) => (
                    <button
                      key={answer}
                      onClick={() => handleAnswerClick(item?.question, answer)}
                      className={`flex items-center px-4 py-4 border rounded-lg text-[#4A4A4A] 
                        ${
                          answers[item?.question] === answer
                            ? (i === 0 && "border-[#00CC00] bg-[#d6ffd6]") ||
                              (i === 1 && "border-[#fbda63] bg-[#fff9e5]") ||
                              (i === 2 && "border-[#F9812A] bg-[#FFFAE5]") ||
                              (i >= 3 && "border-[#FF5C5C] bg-[#FFF0F0]")
                            : "border-[#BBBBBB]"
                        }
                      `}
                    >
                      <span
                        className={`w-5 h-5 border rounded-full flex items-center justify-center mr-2 ${
                          answers[item?.question] === answer
                            ? (i === 0 && "bg-[#00CC00] ") ||
                              (i === 1 && "bg-[#fbda63] ") ||
                              (i === 2 && "bg-[#F9812A] ") ||
                              (i >= 3 && "bg-[#FF5C5C] ")
                            : "bg-white border-[#BBBBBB]"
                        }`}
                      >
                        {answers[item?.question] === answer ? (
                          <svg
                            width="12"
                            height="10"
                            viewBox="0 0 12 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M0.757324 6.16621L3.72686 9.13574L11.1507 1.71191"
                              stroke="white"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        ) : (
                          ""
                        )}
                      </span>
                      {answer}
                    </button>
                  ))}
                </div>
              </div>
            ))
          )}
          <button
            className="w-full py-4 border border-[#4F75FA] bg-[#4F75FA] rounded-lg text-white text-center lg:text-[20px] hover:text-[#4F75FA] hover:bg-white active:scale-95 disabled:cursor-not-allowed flex justify-center items-center gap-x-2 font-poppins font-medium"
            onClick={handleGenerateReview}
          >
            {generateReviewLoading ? <LoadingButton /> : "Generate Review"}
          </button>
        </div>
        <div className="mt-10 gap-5 grid md:grid-cols-2 lg:grid-cols-3">
          {reviews
            ?.sort((a, b) => a.length - b.length)
            ?.map((item, i) => (
              <div
                key={i}
                className="w-full border border-[#e3e3e3] rounded-lg px-5 py-10 font-heebo"
              >
                {item}
              </div>
            ))}
        </div>
        <div className="mt-10 space-y-5">
          <p className="font-poppins font-semibold text-2xl text-center mt-5">
            Enter your details
          </p>
          <div className="grid lg:grid-cols-2 gap-5">
            <InputBox
              title={"Name"}
              value={name}
              setValue={setName}
              disabled={isSignUpLoading}
            />
            <InputBox
              title={"Phone Number"}
              value={phone}
              setValue={setPhone}
              disabled={isSignUpLoading}
            />
            <InputBox
              title={"Email Address"}
              value={email}
              setValue={setEmail}
              disabled={isSignUpLoading}
            />
            <InputBox
              title={"Business Name"}
              value={businessName}
              setValue={setBusinessName}
              disabled={isSignUpLoading}
            />
            <div className="flex flex-col gap-y-2">
              <label htmlFor="industry" className="font-poppins font-medium">
                Industry
              </label>
              <select
                value={industry}
                onChange={(e) => setIndustry(e.target.value)}
                id="industry"
                className="p-3 rounded-lg border border-[#e3e3e3]"
              >
                {/* <option value="" className="text-[#585866]"></option> */}
                <option value="hotel">Hotel</option>
                <option value="restaurant">Restaurant</option>
                <option value="professional-service">
                  Professional Service
                </option>
                <option value="other">Other</option>
              </select>
            </div>
            <InputBox
              title={"Password"}
              value={password}
              setValue={setPassword}
              disabled={isSignUpLoading}
            />
            <InputBox
              title={"Confirm Password"}
              value={confirmPassword}
              setValue={setConfirmPassword}
              disabled={isSignUpLoading}
            />
          </div>
        </div>
        <div className="mt-10 space-y-5">
          <div>
            <p className="font-poppins font-semibold text-2xl text-center -leading-4 mt-5">
              Choose a Plan
            </p>
            <p className="font-poppins text-xs text-[#2d2d2d] text-center mt-2">
              Pick the plan that suits your needs the most.
            </p>
          </div>
          <section className="w-full relative font-poppins text-[#333333] space-y-6 z-10 pb-10 lg:mt-10">
            <div className="bg-[#f6f6ff] rounded-[19px] max-w-[716px] w-full px-5 space-y-6 py-5 lg:py-[70px] lg:m-auto">
              <div className="lg:w-[70%] mx-auto flex flex-col justify-center items-center">
                <div className=" bg-white w-full px-2 py-2 justify-center rounded-[16px] flex flex-nowrap gap-1 md:gap-2 items-center text-xs sm:text-sm md:text-base lg:text-lg text-nowrap">
                  {plans?.data?.map((item, id) => {
                    return (
                      <span
                        key={id}
                        className={`rounded-[9px] flex justify-center items-center px-3 sm:px-5 py-2 sm:py-3 ${selectedPlan == id && "bg-newPrimary text-white"} cursor-pointer transition-all w-1/4 text-center duration-500`}
                        onClick={() => setSelectedPlan(id)}
                      >
                        {item?.short_text}
                      </span>
                    );
                  })}
                </div>

                <div className="px-3 sm:px-5 py-2 text-[#FF0000] border border-[#FF0000] rounded-[4px] sm:rounded-[18px] mt-4 sm:mt-[44px] font-poppins text-[12px] sm:text-base mb-[13px] sm:mb-[15px] bg-white">
                  {plans?.data?.[selectedPlan]?.secondary_text}
                </div>

                <h2 className="text-[#333333] font-poppins text-[32px] sm:text-[40px] lg:text-[67px] font-extrabold text-center sm:mt-3">
                  AED{" "}
                  {plans?.data?.[selectedPlan]?.discount !== 0
                    ? plans?.data?.[selectedPlan]?.price_after_discount
                    : plans?.data?.[selectedPlan]?.price}
                </h2>
                {plans?.data?.[selectedPlan]?.discount !== 0 ? (
                  plans?.data?.[selectedPlan]?.is_relative_discount_price ? (
                    <div className="flex gap-2 sm:gap-[31px] items-center font-poppins mb-[27px]">
                      <h2 className="text-base sm:text-[32px] text-[#333333] line-through">
                        {plans?.data?.[selectedPlan]?.currency}{" "}
                        {plans?.data?.[selectedPlan]?.price}
                      </h2>
                      <button className="bg-[#73DF07] rounded-[3px] sm:rounded-[9px] px-2 sm:px-5 py-1 text-white text-[10px] sm:text-[20px] ">
                        {plans?.data?.[selectedPlan]?.discount}% OFF{" "}
                      </button>
                    </div>
                  ) : (
                    <div className="flex gap-[31px] items-center font-poppins mb-[27px]">
                      <h2 className="text-[32px] text-[#333333] line-through">
                        {plans?.data?.[selectedPlan]?.currency}{" "}
                        {plans?.data?.[selectedPlan]?.price}
                      </h2>
                      <button className="bg-[#73DF07] rounded-[9px] px-5 py-1 text-white text-[20px] ">
                        {plans?.data?.[selectedPlan]?.currency}{" "}
                        {plans?.data?.[selectedPlan]?.discount} OFF{" "}
                      </button>
                    </div>
                  )
                ) : null}
              </div>

              <div className="max-w-[476.79px] mx-auto w-full">
                {data.map((item, index) => (
                  <div
                    key={index}
                    className={`${index === data.length - 1 ? "border-none" : "border-b border-[#EAEAEA]"} flex font-poppins text-[#1F1E1E] lg:items-center items-start text-start gap-[13px] lg:text-lg text-xs w-full mb-3 pb-2`}
                  >
                    <img src="/check.svg" alt="check" className="w-4 h-4" />
                    {item}
                  </div>
                ))}

                <button
                  className="bg-[#4F75FA] border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white border  font-poppins w-full mt-10 py-3 rounded-[10px] text-white"
                  disabled={subscribeLoading || isSignUpLoading}
                  onClick={() =>
                    handleSubmit(plans?.data?.[selectedPlan]?.uuid)
                  }
                >
                  {subscribeLoading || isSignUpLoading ? (
                    <LoadingButton />
                  ) : (
                    "Subscribe Now"
                  )}
                </button>
              </div>
            </div>
          </section>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Vsl;

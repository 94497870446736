/* eslint-disable react/prop-types */

const NonContinuousProgressBar = ({ progress }) => {
  const segments = 6;

  // Calculate how many segments are fully or partially filled based on progress
  const segmentPercentage = 100 / segments;
  const segmentProgress = progress / segmentPercentage; // Calculate how many segments should be filled

  return (
    <div className="relative w-full flex space-x-2">
      {[...Array(segments)].map((_, index) => {
        // Determine if the current segment is fully or partially filled
        const isFull = segmentProgress >= index + 1; // Full segment if segmentProgress >= current segment index + 1
        const isPartial =
          segmentProgress > index && segmentProgress < index + 1; // Partially filled segment

        return (
          <div
            key={index}
            className="relative w-1/6 h-1.5 rounded-lg overflow-hidden bg-[#1AAD55]/20"
          >
            <div
              className={`absolute top-0 left-0 h-full bg-[#1AAD55] transition-all duration-300`}
              style={{
                width: isFull
                  ? "100%" // Fully fill this segment
                  : isPartial
                    ? `${(segmentProgress % 1) * 100}%` // Partially fill this segment
                    : "0%", // Unfilled segment
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
};

export default NonContinuousProgressBar;

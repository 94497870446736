/* eslint-disable react/prop-types */

const MainLogo = ({ className, color }) => {
  return (
    <div className={`flex items-center ${className}`}>
      <img src="/magiclogo.svg" alt="" className="w-[55px]" />
      <p
        className={`text-[24px] -tracking-[3%] leading-[30px] ${
          color === "white" ? "text-white" : "text-[#434343]"
        } font-avertaBold text-center -mt-2`}
      >
        magicreview
      </p>
    </div>
  );
};

export default MainLogo;

/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

const ScreenshotModal = ({ isOpenModal, setIsOpenModal, img }) => {
  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Screenshot Review</DialogTitle>
        </DialogHeader>
        <div className="px-6 flex justify-center items-center">
          <img src={img} alt="" className="w-full" />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ScreenshotModal;

import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { LayoutGrid, ChevronRight, Sparkles, LinkIcon } from "lucide-react";
import { Link, useSearchParams } from "react-router-dom";
import { data } from "autoprefixer";
import GetStartedModal from "./components/GetStartedModal";
import { Button } from "../../../components/ui/button";
import ConfettiComponent from "./components/ConfettiComponent";
const GetStarted = () => {
  const [params] = useSearchParams();
  const newUser = params.get("new");
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState(false);
  const [fireConfetti, setFireConfetti] = useState(false);

  const pages = [
    {
      title: "Overview",
      text: "View, track and download the AI generated reviews here",
      bg: "#f6f6ff",
      icon: <LayoutGrid className="text-[#4770FF] text-2xl " />,
      link: "/dashboard?referrer=verify-email",
      video_link:
        "https://www.youtube.com/embed/4RqSfKnFjgg?si=DGNJxwqkBbW6Gw9r",
    },
    {
      title: "Add Question",
      text: " Create question set(s) for your customers to facilitate an easy collection of reviews here",
      bg: "#FFEFD1",
      icon: <Sparkles className="text-[#FFAF19] text-2xl " />,
      link: "/dashboard/questions?referrer=verify-email",
      video_link:
        "https://www.youtube.com/embed/_iWMlf_I35E?si=ct_RhNaf7MV0_I1A",
    },
    {
      title: "Add MagicLink",
      text: " Add Magiclinks to monitor and manage reviews collected by different staff members here",
      bg: "#CCF0E4",
      icon: <LinkIcon className="text-[#00B67A] text-2xl " />,
      link: "/dashboard/staffs?referrer=verify-email",
      video_link:
        "https://www.youtube.com/embed/tvJ0m77zQEU?si=nPfXCJs9ACc5PBFV",
    },
  ];

  useEffect(() => {
    if (newUser) {
      setIsOpenWelcomeModal(true);
      setFireConfetti(true);
    }
  }, [newUser]);

  return (
    <Layout title={"Getting Started"}>
      <div className="flex flex-col mt-[20px] w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-200px)] 3xl:min-h-[calc(100vh-150px)] px-5 lg:px-[30.2px]">
        <h1 className="text-xl font-medium mb-[7px]">
          Welcome to MagicReview!
        </h1>
        <p className="text-[#4A4A4A] dark:text-white font-pro text-base">
          Describe your current or upcoming business in detail, and receive
          feedback oriented questions.
        </p>

        <div className="grid grid-cols-1  md:grid-cols-2 lg:grid-cols-3 w-full gap-5 mt-10">
          {pages.map((page, index) => (
            <div
              key={index}
              className="w-full border border-[#EBEBEB] dark:border-none rounded-2xl py-[29px] px-[25px] bg-white dark:bg-darkBg flex  flex-col"
            >
              <div
                className={`w-[46px] h-[46px] rounded-full bg-[${page.bg}] dark:bg-darkLightBg flex  justify-center items-center mb-[10px]`}
              >
                {page.icon}
              </div>
              <h1 className="text-base font-pro text-black dark:text-white font-medium mb-[10px]">
                {page.title}
              </h1>
              <p className="text-[13px] text-[#4A4A4A] dark:text-white font-normal font-pro lg:max-w-[278px] w-full mb-auto">
                {page.text}
              </p>
              <div className="my-[35px]">
                <iframe
                  id="videoSection"
                  className="rounded-[7px] object-cover flex align-middle justify-center mx-auto w-full lg:h-56 h-48"
                  src={page.video_link}
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>

              <Link
                to={page.link}
                className="flex gap-2 items-center text-[#2A49B8] font-pro text-base w-fit"
              >
                Take a tour
                <ChevronRight className="w-4 h-4" />
              </Link>
            </div>
          ))}
        </div>
      </div>

      <ConfettiComponent fire={fireConfetti} />
      <GetStartedModal
        isOpen={isOpenWelcomeModal}
        setIsOpen={setIsOpenWelcomeModal}
      />
    </Layout>
  );
};

export default GetStarted;

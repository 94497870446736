/* eslint-disable react/prop-types */
import { useCallback, useEffect, useRef, useState } from "react";
import { useReactToPrint, ReactToPrint } from "react-to-print";
import { LoadingButton } from "../../../components/loading/loading";
import * as htmlToImage from "html-to-image";
import html2canvas from "html2canvas";
import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "../../../components/ui/dialog";
import { DialogTitle } from "@radix-ui/react-dialog";
import MagicLogo from "/mainlogo.png";
import { toast } from "sonner";
import { Download, Printer, Share2 } from "lucide-react";

const QrContainer = ({ openEditModal, setOpenEditModal, qr, isLoading }) => {
  const printRef = useRef(null);
  const [pp, setPp] = useState("");
  const [isDownloading, setIsDownloading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [imageSrc, setImageSrc] = useState("");
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    pageStyle: `@media print {
      @page {
        size: 393px 810px;
        margin: 10px;
      }
    }`,
  });

  const handleSaveAsPng = async () => {
    if (printRef.current === null) {
      return;
    }
    setIsDownloading(true);
    // Remove unwanted TailwindCSS classes before taking screenshot

    printRef.current.classList.remove("hidden");

    // Add useCORS to handle cross-origin images
    html2canvas(printRef.current, { useCORS: true }).then((canvas) => {
      const link = document.createElement("a");
      link.href = canvas.toDataURL("image/png");
      link.download = "qr.png";
      link.click();

      setIsDownloading(false);
      printRef.current.classList.add("hidden");
    });
  };

  const handleShare = () => {
    if (printRef.current === null) {
      return;
    }
    setIsSharing(true);
    // Remove unwanted TailwindCSS classes before taking screenshot

    printRef.current.classList.remove("hidden");

    // Add useCORS to handle cross-origin images
    html2canvas(printRef.current, { useCORS: true }).then((canvas) => {
      canvas.toBlob((blob) => {
        if (blob) {
          const file = new File([blob], "qr.png", { type: "image/png" });

          // Check if the file can be shared
          if (navigator.canShare && navigator.canShare({ files: [file] })) {
            navigator
              .share({
                files: [file],
                title: "MagicReview QR",
                text: "Scan the QR to generate reviews!",
              })
              .then(() => {
                toast.success("Image shared successfully");
                setIsSharing(false);
                if (printRef.current) {
                  printRef.current.classList.add("hidden");
                }
              })
              .catch((error) => {
                toast.error(`Error sharing image: ${error}`);
                setIsSharing(false);
              });
          } else {
            toast.error(
              "File sharing not supported for this type or the browser does not support sharing."
            );
            setIsSharing(false);
          }
        } else {
          toast.error("Image generation failed");
          setIsSharing(false);
        }
      });
    });
  };

  useEffect(() => {
    // Create a URL for the Blob
    if (qr) {
      const url = URL.createObjectURL(qr);

      // Set the URL as the image source
      setImageSrc(url);

      // Clean up the URL object when the component unmounts
      return () => {
        URL.revokeObjectURL(url);
      };
    }
  }, [qr]);

  return (
    <Dialog
      open={openEditModal}
      onOpenChange={() => {
        setOpenEditModal(!openEditModal);
      }}
      className=""
    >
      <DialogContent className="lg:pb-5 lg:px-0 font-poppins flex flex-col items-center">
        <DialogHeader className={"hidden"}>
          <DialogTitle></DialogTitle>
        </DialogHeader>

        {/* what user sees */}

        {isDownloading || isSharing ? (
          <></>
        ) : (
          <div
            className="print:bg-white print:scale-100 bg-[#f8f8f8] print:w-[393px] -z-10 hidden print:block"
            ref={printRef}
          >
            <div className="w-[393px] mx-auto h-[293px] bg-[#4869df] rounded-br-[100px] relative">
              <div className="absolute bottom-0 w-full">
                <p className="text-center font-poppins font-semibold text-lg text-white mb-[100px]">
                  Scan QR Code
                </p>
                <div className="w-[333px] mx-auto bg-white rounded-t-[20px] flex flex-col items-center border border-[#F2F2F2] border-b-none relative">
                  <img
                    src={user?.profile?.profile_picture}
                    alt=""
                    className="w-[90px] h-[90px] rounded-full relative top-[-45px] z-30"
                    referrerPolicy="no-referrer"
                  />
                  {/* <p className="-translate-y-[40px] font-poppins font-semibold text-lg text-black">
                    {user?.entity?.name}
                  </p> */}
                </div>
              </div>
            </div>

            <div className="aspect-square w-[333px] border border-[#F2F2F2] rounded-b-[20px] border-t-none mx-auto overflow-hidden flex items-center justify-center shadow bg-white">
              {isLoading ? (
                <div className="flex items-center justify-center w-full h-full">
                  <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
                </div>
              ) : (
                <img src={imageSrc} alt="" className="size-[250px]" />
              )}
            </div>

            <div className="w-[333px] mx-auto mt-10 mb-5">
              <div className="flex items-center gap-x-5">
                <div className="w-full h-0.5 border-t border-t-[#ebebeb]"></div>
                <p className="flex-shrink-0 text-[#888] text-sm">Powered by</p>
              </div>
              <div className="flex justify-end">
                <div className="flex items-center max-w-[150px] -mt-3">
                  <img
                    src={MagicLogo}
                    alt=""
                    className="w-[50px]"
                    referrerPolicy="no-referrer"
                  />
                  <p className="text-[18.64px] -tracking-[3%] leading-[54px] text-[#434343] font-avertaBold text-center -mt-2">
                    magicreview
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* what user sees */}
        <div className="flex flex-col text-center pt-10">
          <p className="text-center font-poppins font-medium text-xl text-black dark:text-white mb-7">
            Scan QR Code
          </p>
          {/* <p className="text-[#888888] font-poppins text-[14px] mb-[56px]">
            Scan this code to view your magiclink
          </p> */}

          {isLoading ? (
            <div className="flex items-center justify-center w-[333px] h-[333px] mx-10">
              <div className="w-5 h-5 border-t-2 border-b-2 border-secondary rounded-full animate-spin"></div>
            </div>
          ) : (
            <img
              src={imageSrc}
              alt=""
              className="w-[55%] border border-[#BFBFBF] mx-auto rounded-[12px] mb-10"
            />
          )}
        </div>
        <div className="grid grid-cols-3 items-center gap-3">
          {/* <ReactToPrint
            trigger={() => (
              <button className="rounded-[7px]  text-white bg-newPrimary border border-newPrimary text-base text-center px-6 py-2 hover:bg-white hover:text-newPrimary transition-all duration-200 h-12 dark:hover:bg-darkLightBg w-full">
                Print
              </button>
            )}
            content={() => printRef.current}
          /> */}
          <button
            className="rounded-[7px] bg-[#f0f5ff] text-newPrimary border-[#f0f5ff] dark:bg-newPrimary dark:text-white dark:border-none  hover:text-white hover:bg-newPrimary border hover:border-newPrimary text-base text-center px-6 py-2 transition-all duration-200 h-12 dark:hover:bg-darkLightBg w-full flex items-center justify-center gap-3"
            onClick={handlePrint}
          >
            <Printer className="size-5" /> Print
          </button>
          <button
            className="rounded-[7px] bg-[#f0f5ff] text-newPrimary border-[#f0f5ff] dark:bg-newPrimary dark:text-white dark:border-none  hover:text-white hover:bg-newPrimary border hover:border-newPrimary text-base text-center px-6 py-2 transition-all duration-200 h-12 dark:hover:bg-darkLightBg w-full flex items-center justify-center gap-3"
            onClick={handleShare}
          >
            {isSharing ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white group-hover:border-secondary rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                <Share2 /> Share
              </>
            )}
          </button>
          <button
            className="rounded-[7px] bg-[#f0f5ff] text-newPrimary border-[#f0f5ff] dark:bg-newPrimary dark:text-white dark:border-none  hover:text-white hover:bg-newPrimary border hover:border-newPrimary text-base text-center px-6 py-2 transition-all duration-200 h-12 dark:hover:bg-darkLightBg w-full flex items-center justify-center gap-3"
            onClick={handleSaveAsPng}
          >
            {isDownloading ? (
              <div className="flex items-center justify-center">
                <div className="w-5 h-5 border-t-2 border-b-2 border-white group-hover:border-secondary rounded-full animate-spin"></div>
              </div>
            ) : (
              <>
                <Download className="size-5" /> Save
              </>
            )}
          </button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default QrContainer;

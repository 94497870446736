import React from "react";
import { useGetCreditsRequest } from "../../../query/useMaster";
import Layout from "../../dashboard/component/Layout";
import CreditsRequestTable from "./CreditsRequestTable";

const CreditsRequestRejected = () => {
  const { data: rejectedRequests, isLoading: rejectedRequestsLoading } =
    useGetCreditsRequest({
      status: "rejected",
    });

  return (
    <Layout title={"Credits Request Rejected"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-125px)] px-5 lg:px-[30.2px] font-pro ">
        <CreditsRequestTable
          items={rejectedRequests?.data}
          isLoading={rejectedRequestsLoading}
          isPendingSection={false}
        />
      </div>
    </Layout>
  );
};

export default CreditsRequestRejected;

import React from "react";
import Nav from "../../components/secondarynavbar/nav";
import Welcome from "./components/welcome";
import Industry from "./components/industry";
import Company from "./components/company";
import Questions from "./components/questions";
import Link from "./components/link";
import { useOnBoarding } from "../../context/onBoardingContext";
import { useLocation } from "react-router";
import AddQuestionsModal from "./components/modal";
const Onboarding = () => {
  const location = useLocation();
  // const selectedScreen = useOnBoarding();
  const queryparams = new URLSearchParams(location.search);
  const selectedScreen = queryparams.get("selectedscreen");

  return (
    <div className="container w-full">
      <Nav />
      <div className="w-full flex flex-col justify-center items-center pb-5">
        {!selectedScreen && <Welcome />}
        {/* {selectedScreen === "industryscreen" && <Industry />}
        {selectedScreen === "companyscreen" && <Company />} */}
        {selectedScreen === "questionsscreen" && <Questions />}
        {/* {selectedScreen === "linkscreen" && <Link />} */}

        <div className="flex justify-center gap-3 mt-[40px]">
          <span
            className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out  
          ${!selectedScreen ? "bg-secondary" : "bg-[#EFEEEE]"}`}
          ></span>

          {/* <span
            className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out  ${
              selectedScreen === "industryscreen"
                ? "bg-[#594DDD]"
                : "bg-[#EFEEEE]"
            }`}
          ></span> */}
          {/* <span
            className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out ${
              selectedScreen === "companyscreen"
                ? "bg-[#594DDD]"
                : "bg-[#EFEEEE]"
            }`}
          ></span> */}
          <span
            className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out ${
              selectedScreen === "questionsscreen"
                ? "bg-secondary"
                : "bg-[#EFEEEE]"
            }`}
          ></span>
          <span
            className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out ${
              selectedScreen === "linkscreen" ? "bg-secondary" : "bg-[#EFEEEE]"
            }`}
          ></span>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;

/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { SquarePlus } from "lucide-react";
import { toast } from "sonner";
import { usePostPositivePrompt } from "../../../../query/useReview";

const AddConditionModal = ({ openModal, setOpenModal, refetchPositive }) => {
  const [title, setTitle] = useState("");
  const [condition, setCondition] = useState("");
  const { mutate: addPositivePrompt, isLoading: addPositivePromptLoading } =
    usePostPositivePrompt();

  const handleAddPositivePrompt = () => {
    addPositivePrompt(
      {
        title: title,
        condition: condition,
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchPositive();
          setOpenModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  return (
    <Dialog open={openModal} onOpenChange={() => setOpenModal(!openModal)}>
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Add Condition</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5">
          <div className="w-full space-y-3">
            <label htmlFor="title" className="font-medium text-sm">
              Title
            </label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="title"
            />
          </div>
          <div className="w-full space-y-3">
            <label htmlFor="condition" className="font-medium text-sm">
              Condition
            </label>
            <Textarea
              value={condition}
              onChange={(e) => setCondition(e.target.value)}
              id="condition"
            />
          </div>
          <div className="flex justify-end items-center gap-3">
            <Button onClick={handleAddPositivePrompt}>Save</Button>
            <Button variant="secondary" onClick={() => setOpenModal(false)}>
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AddConditionModal;

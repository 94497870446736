import { useNavigate } from "react-router";
import { useGetPlans } from "../../query/useSubscription";
import { useState } from "react";
import { pricingData } from "../../data/data";
import { CircleCheck } from "lucide-react";
import { RadioGroup, RadioGroupItem } from "../../components/ui/radio-group";

const Plans = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    window.scrollTo(0, 0);
    navigate("/sign-up");
  };
  const { data: plans } = useGetPlans();
  const updatedPlans = Array.isArray(plans?.data)
    ? plans.data.sort((a, b) => b.price - a.price)
    : [];
  const [selectedPlan, setSelectedPlan] = useState(0);

  // Currency symbol will be rendered in "en-US" locale for every currency.
  const planCurrency = updatedPlans[selectedPlan]?.currency;

  const cardPrice = () => {
    switch (planCurrency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };
  return (
    <div className="font-poppins flex flex-col lg:flex-row lg:items-center lg:justify-between gap-5 mb-20 w-full">
      <div className="w-fit lg:ml-12">
        <p className="font-medium text-2xl lg:text-[28px] lg:leading-[46px] lg:-tracking-[1%] text-[#333]">
          Features
        </p>

        <div className="divide-y mt-5 mb-3">
          {pricingData.map((item, i) => (
            <div key={i} className="flex items-center gap-5 py-2 lg:py-0">
              <CircleCheck className="fill-newPrimary text-white" />
              <p className="text-sm lg:text-base lg:leading-[48px] text-[#1f1e1e]">
                {item}
              </p>
            </div>
          ))}
        </div>
        <p className="text-sm lg:text-base text-[#1f1e1e]">
          <span className="text-[#ff0000]">Note:</span>{" "}
          <ul className="list-disc list-inside">
            <li className="font-medium">3 Free Cards</li>
            <li>
              <span className="font-medium">Additional Card:</span>{" "}
              {cardPrice()}
              /card{" "}
            </li>
          </ul>
        </p>
      </div>

      <div className="space-y-3">
        <RadioGroup
          defaultValue={selectedPlan}
          onValueChange={(e) => setSelectedPlan(e)}
        >
          {updatedPlans?.map((item, i) => (
            <div
              key={i}
              className={`flex items-center w-full gap-3 border-2 rounded-xl px-5 py-3 cursor-pointer ${selectedPlan === i ? "border-newPrimary bg-[#F6F8FF]" : "border-[#ddd] bg-[#fcfcfc]"}`}
              htmlFor={i}
            >
              <RadioGroupItem value={i} id={i} />
              <label
                className="flex items-center justify-between gap-3 w-full lg:min-w-[450px] cursor-pointer"
                htmlFor={i}
              >
                <div className="space-y-2">
                  <p className="text-xl lg:text-2xl font-medium text-[#333]">
                    {item?.secondary_text}
                  </p>
                  <div className="flex items-center gap-2">
                    <p className="lg:text-lg text-[#333]">{item?.short_text}</p>
                    {item?.discount !== 0 ? (
                      item?.is_relative_discount_price ? (
                        <button className="bg-[#D9FFE5] rounded-[8px] px-3 py-1 text-[#0BA649] text-xs lg:text-sm font-medium ">
                          Save {item?.discount}% OFF{" "}
                        </button>
                      ) : (
                        <button className="bg-[#D9FFE5] rounded-[8px] px-3 py-1 text-[#0BA649] text-xs lg:text-sm font-medium ">
                          Save {item?.discount_percent}% OFF{" "}
                        </button>
                      )
                    ) : null}
                  </div>
                </div>
                <div className="text-black">
                  <p className="font-inter font-bold text-2xl lg:text-[32px] lg:leading-[58px]">
                    {item?.currency &&
                      item?.price_after_discount?.toLocaleString("en-US", {
                        style: "currency",
                        currency: item?.currency,
                      })}
                  </p>
                  <p className="font-inter lg:text-xl font-medium line-through">
                    {item?.currency &&
                      item?.price?.toLocaleString("en-US", {
                        style: "currency",
                        currency: item?.currency,
                      })}
                  </p>
                </div>
              </label>
            </div>
          ))}
        </RadioGroup>
        <button
          className="flex items-center justify-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white w-full text-center font-poppins"
          onClick={handleNavigate}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Plans;

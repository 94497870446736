/* eslint-disable react/prop-types */
import { useState } from "react";
import { useUpdateCard } from "../../../query/useCard";
import { useGetEmployeesList } from "../../../query/useEmployees";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "../../../components/ui/dialog";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";
import { toast } from "sonner";

const RedirectModal = ({ isOpenModal, setIsOpenModal }) => {
  return (
    <Dialog open={isOpenModal} onOpenChange={setIsOpenModal}>
      <DialogContent className="lg:max-w-[450px] pb-5">
        <DialogHeader>
          <DialogTitle
            className={
              "text-[#3D3D3D] dark:text-white text-base font-medium font-pro"
            }
          >
            Redirect to MagicLink
          </DialogTitle>
        </DialogHeader>
        <div className="w-full px-6 font-pro">
          <p className="text-black dark:text-white text-[13px] font-medium font-pro">
            Enter Redirect Magiclink
          </p>
          <input
            type="text"
            className="w-full mt-2 py-[6px] px-3 border border-[#EBEBEB] rounded-[8px] focus:outline-none focus:border-[#252323] text-[12px] mb-[16px]"
            placeholder="Paste Magiclink"
          />

          <div className="w-full flex justify-end gap-3">
            <button className="w-[100px] py-[8px] px-3 bg-[#F0F5FF] text-[#4F75FA] rounded-[8px] focus:outline-none text-sm font-medium">
              Cancel
            </button>
            <button className="w-[100px] py-[8px] px-3 bg-[#4F75FA] text-white rounded-[8px] focus:outline-none text-sm font-medium">
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RedirectModal;

export const EditMagicCardModal = ({
  isEditModalOpen,
  setIsEditModalOpen,
  selectedItem,
  refetch,
}) => {
  const [formData, setFormData] = useState({
    name: selectedItem?.name,
    redirect_link: selectedItem?.redirect_link,
    code: selectedItem?.code,
    active: selectedItem?.active,
  });
  const userInfo =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const { mutate, isLoading } = useUpdateCard();
  const { data } = useGetEmployeesList();

  const handleUpdateCard = () => {
    mutate(formData, {
      onSuccess: () => {
        setIsEditModalOpen(false);
        toast.success("Card updated successfully");
        refetch();
      },
      onError: () => {
        toast.error("Something went wrong");
      },
    });
  };
  const baseUrl = "https://www.magicreview.ai";
  return (
    <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
      <DialogContent className="lg:max-w-[450px] pb-5">
        <DialogHeader>
          <DialogTitle
            className={
              "text-[#3D3D3D] dark:text-white text-base font-medium font-pro"
            }
          >
            Edit Magic Card
          </DialogTitle>
        </DialogHeader>
        <div className="w-full px-6 font-pro">
          <p className="text-black dark:text-white text-[13px] font-medium font-pro">
            Name
          </p>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className="w-full mt-2 py-[6px] px-3 border border-[#EBEBEB] rounded-[8px] focus:outline-none focus:border-[#252323] text-[12px] mb-[16px] text-black  dark:text-white dark:bg-darkLightBg dark:border-[#454545]"
            placeholder="Edit name"
          />
          <p className="text-black dark:text-white text-[13px] font-medium font-pro mb-2">
            Redirect Link
          </p>

          <Select
            value={formData.redirect_link}
            onValueChange={(value) =>
              setFormData({ ...formData, redirect_link: value })
            }
            className="w-full mt-2 py-[6px] border border-[#EBEBEB] rounded-[8px] focus:outline-none focus:border-[#252323] text-[12px] mb-[16px] text-black  dark:text-white  dark:bg-darkLightBg dark:border-[#454545]"
          >
            <SelectTrigger>
              <SelectValue>{formData.redirect_link}</SelectValue>
            </SelectTrigger>
            <SelectContent>
              <SelectGroup className="h-[200px]">
                {data?.map((item, index) => {
                  const value =
                    baseUrl +
                    "/" +
                    userInfo?.entity?.magic_link?.split("?")?.[0] +
                    `?employee=${item?.employee_code}`;
                  return (
                    <SelectItem
                      value={value}
                      key={index}
                      className="dark:text-white"
                    >
                      <div className="flex gap-2 items-center">
                        <p>{item.name}</p>
                        {item.employee_code && (
                          <p className="bg-[#7190ff1f] border border-[rgba(107,141,208,0.41)] rounded-lg px-3 w-fit text-[10px]">
                            {item.employee_code}
                          </p>
                        )}
                      </div>
                    </SelectItem>
                  );
                })}
              </SelectGroup>
              {/* <SelectGroup>
                <SelectItem value="https://google.com">
                  https://google.com
                </SelectItem>
                <SelectItem value="https://facebook.com">
                  https://facebook.com
                </SelectItem>
                <SelectItem value="https://twitter.com">
                  https://twitter.com
                </SelectItem>
              </SelectGroup> */}
            </SelectContent>
          </Select>
          {/* <input
            type="text"
            value={formData.redirect_link}
            onChange={(e) =>
              setFormData({ ...formData, redirect_link: e.target.value })
            }
            className="w-full mt-2 py-[6px] px-3 border border-[#EBEBEB] rounded-[8px] focus:outline-none focus:border-[#252323] text-[12px] mb-[16px] text-black  dark:text-white  dark:bg-darkLightBg dark:border-[#454545]"
            placeholder="Edit redirect link"
          /> */}

          <div className="w-full flex justify-end gap-3 mt-20">
            <button
              className="w-[100px] py-[8px] px-3 bg-[#F0F5FF] text-[#4F75FA] rounded-[8px] focus:outline-none text-sm font-medium"
              onClick={() => setIsEditModalOpen(false)}
            >
              Cancel
            </button>
            <button
              className="w-[100px] py-[8px] px-3 bg-[#4F75FA] text-white rounded-[8px] focus:outline-none text-sm font-medium"
              onClick={handleUpdateCard}
              disabled={
                isLoading ||
                !formData.name.trim() ||
                !formData.redirect_link.trim()
              }
            >
              Save
            </button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

import { data } from "autoprefixer";
import Apimanager from "./axios";

export const getCheckoutUrl = async () => {
  const res = await Apimanager.get(`/subscriptions/get_checkout_url`);
  return res.data;
};

export const getCancleSubscription = async () => {
  const res = await Apimanager.get(`/subscriptions/cancel`);
  return res.data;
};

export const getPlans = async () => {
  const res = await Apimanager.get(`/subscriptions/plans`);
  return res.data;
};

export const postUpdatePaymentStatus = async () => {
  const res = await Apimanager.post(`/payments/subscriptions/success`);
  return res.data;
};

export const getNumberOfDaysLeft = async () => {
  const res = await Apimanager.get(`/payments/get_number_of_days_left`);
  return res.data;
};

export const getInvoiceHistory = async () => {
  const res = await Apimanager.get(`/payments/get_invoice_history`);
  return res.data;
};

export const getCheckCoupon = async (params) => {
  const res = await Apimanager.get(`/subscriptions/check_coupon`, {
    params: params,
  });

  return res.data;
};

export const postToggleAutoRenew = async () => {
  const res = await Apimanager.post(`/subscriptions/toggle_auto_renew`);

  return res.data;
};

export const getCharges = async () => {
  const res = await Apimanager.get(`/subscriptions/charges`);
  return res.data;
};

export const postDownloadInvoice = async (id) => {
  const res = await Apimanager.post(
    `/subscriptions/charges/${id}/invoice`,
    {},
    {
      responseType: "blob",
    }
  );
  return res;
};

export const postBuyCard = async (data) => {
  const res = await Apimanager.post(`/subscriptions/buy_card`, data);
  return res.data;
};

export const getBuyCardHistory = async () => {
  const res = await Apimanager.get(`/subscriptions/card_payment_history`);
  return res.data;
};

import Apimanager from "./axios";

export const getAllCardsApi = async () => {
    const res = await Apimanager.get("/entity/nfccard");
    return res.data;
    }

export const createCardApi = async (data) => {
    const res = await Apimanager.post("/entity/nfccard", data);
    return res.data;
    }

export const updateCardApi = async (data) => {
    const res = await Apimanager.put("/entity/nfccard", data);
    return res.data;
    }
/* eslint-disable react/prop-types */

import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

const ConfirmUserModal = ({ isOpenModal, setIsOpenModal, handleAction }) => {
  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal(false)}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Confirm to create new user</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <p>
            Please make sure to save the password securely somewhere, as it
            won&apos;t be viewable again after it has been set.
          </p>
          <div className="flex justify-end gap-3 mt-5">
            <Button
              variant="secondary"
              // className=" bg-lightpurple text-newPrimary flex items-center justify-center rounded-lg p-2 gap-x-3 text-sm font-pro font-medium"
              onClick={() => setIsOpenModal(false)}
            >
              Cancel
            </Button>
            <Button
              // className="w-full md:w-[120px] bg-newPrimary text-white flex items-center justify-center rounded-lg p-2 gap-x-3 text-sm font-pro font-medium"
              onClick={handleAction}
            >
              Confirm
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmUserModal;

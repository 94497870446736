import Layout from "../component/Layout";
import {
  useGetGmbReviews,
  useGetGmbReviewsInfinity,
} from "../../../query/useAuthentication";
import { ReviewsIllustration } from "../../onboarding/components/illustrations/Reviews";
import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../../context/DarkModeProvider";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import CardStack from "./components/CardStack";
import ReviewCardGoogle from "./components/ReviewCardGoogle";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import LoaderIcon from "react-loader-icon";

const GoogleReviews = () => {
  const { darkMode } = useDarkMode();
  const { inView, ref } = useInView();
  // const { data: gmbReviews, isLoading: gmbReviewsLoading } = useGetGmbReviews();
  const {
    data: gmbReviews,
    hasNextPage,
    fetchNextPage,
    isLoading: gmbReviewsLoading,
  } = useGetGmbReviewsInfinity({ reply: false });
  const flattenedGmbReviews = gmbReviews?.pages?.flatMap(
    (page) => page?.results
  );
  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);
  return (
    <Layout title={"Google Reviews"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px]">
        {gmbReviewsLoading ? (
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5 mt-5">
            {new Array(3).fill("").map((_, i) => (
              <div
                className="flex flex-col rounded-[12px] p-9 bg-[#FFF] dark:bg-darkBg gap-y-5"
                key={i}
              >
                <Skeleton
                  circle
                  className="w-[50px] h-[50px]"
                  baseColor={darkMode && "#262626"}
                />
                <Skeleton height={120} baseColor={darkMode && "#262626"} />
                <Skeleton height={20} baseColor={darkMode && "#262626"} />
              </div>
            ))}
          </div>
        ) : flattenedGmbReviews?.length > 0 ? (
          <>
            <div className="font-poppins flex-shrink-0 text-sm text-newPrimary font-medium bg-[#dbe7ff] px-5 h-10 flex justify-center items-center rounded-[8px] w-fit">
              Total Reviews -{" "}
              <span className="text-base font-semibold">
                {gmbReviews?.pages?.[0]?.total_results}
              </span>
            </div>

            <ResponsiveMasonry
              columnsCountBreakPoints={{
                200: 1,
                1280: 2,
                1440: 3,
              }}
              className="mt-5"
            >
              <Masonry gutter="20px">
                {flattenedGmbReviews?.map((review, i) => (
                  <div
                    key={i}
                    className="dark:bg-darkBg duration-500 bg-white dark:border-none rounded-lg shadow-md flex justify-center items-center cursor-pointer p-5 lg:p-9 "
                  >
                    <ReviewCardGoogle review={review} />
                  </div>
                ))}
              </Masonry>
            </ResponsiveMasonry>
            {hasNextPage && (
              <div ref={ref} className="text-center mt-10">
                <LoaderIcon type="cylon" color="#7190FF" />
              </div>
            )}
          </>
        ) : (
          <ReviewsIllustration />
        )}
      </div>
    </Layout>
  );
};

export default GoogleReviews;

/* eslint-disable react/prop-types */
import { useRef, useState } from "react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { toast } from "sonner";
import {
  usePostAddQuestionKeyword,
  usePutEditQuestionKeyword,
} from "../../../query/useEntity";
import { Pencil, X } from "lucide-react";
import { LoadingButton } from "../../../components/loading/loading";


const KeywordDropdownForModal = ({
  selectedKeyword,
  setSelectedKeyword,
  isOpenDeleteKeyword,
  setIsOpenDeleteKeyword,
  setDeleteKeywordSlug,
  setQuestion,
  keywords,
  refetchKeyword,
}) => {
  const keywordRef = useRef(null);
  const [openKeywordSuggestionBox, setOpenKeywordSuggestionBox] =
    useState(false);
  const [openKeywordEdit, setOpenKeywordEdit] = useState("");
  const [editKeyword, setEditKeyword] = useState("");
  const { mutate: addKeyword, isLoading: addKeywordLoading } =
    usePostAddQuestionKeyword();
  const { mutate: updateKeyword, isLoading: editKeywordLoading } =
    usePutEditQuestionKeyword();
  const handleAddKeyword = () => {
    addKeyword(
      {
        keyword: selectedKeyword,
      },
      {
        onSuccess: (res) => {
          toast.success("Added new keyword successfully");
          refetchKeyword();
          setSelectedKeyword(res?.question_keyword?.keyword);
          setQuestion((prev) => {
            const current = { ...prev };
            current.keyword_link = res?.question_keyword?.id;
            return current;
          });
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleUpdateKeyword = () => {
    updateKeyword(
      {
        keyword: editKeyword,
        keyword_slug: openKeywordEdit,
      },
      {
        onSuccess: (res) => {
          toast.success("Updated keyword successfully");
          setOpenKeywordEdit("");
          refetchKeyword();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };
  useOnClickOutside({
    ref: keywordRef,
    setIsOpen: setOpenKeywordSuggestionBox,
    prevent: isOpenDeleteKeyword,
  });
  return (
    <div className="my-5 space-y-3">
      <p className="text-base lg:text-[20px] dark:text-white">Keyword</p>
      <div className="flex items-center gap-x-5 w-full">
        <div className="relative max-w-md w-full" ref={keywordRef}>
          <input
            autoFocus={false}
            value={selectedKeyword || ""}
            onChange={(e) => {
              const value = e.target.value;
              setSelectedKeyword(value);

              //if  the  value is empty, set the keyword_link to null
              if (value === "") {
                setQuestion((prev) => {
                  const current = { ...prev };
                  current.keyword_link = null;
                  return current;
                });
                setSelectedKeyword(null);
              }
            }}
            onClick={() => {
              setOpenKeywordSuggestionBox((prev) => !prev);
            }}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleAddKeyword();
              }
            }}
            autoComplete={"off"}
            id="keyword"
            placeholder="Select Keyword or Add New Keyword"
            className="border border-gray-300 px-3 py-3 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans w-full placeholder:text-[#a6a6a6] dark:bg-darkLightBg dark:text-white dark:border-none"
          />
          {openKeywordSuggestionBox && (
            <div className="w-full 2xl:h-[300px] h-[200px] p-5 rounded-lg absolute top-12 bg-white dark:bg-darkLightBg dark:border-none dark:text-white z-[60] overflow-y-auto scrollbar-thin shadow-xl border ">
              <div
                className="py-2 hover:text-secondary cursor-pointer"
                onClick={(e) => {
                  e.preventDefault();
                  setQuestion((prev) => {
                    const current = { ...prev };
                    current.keyword_link = null;
                    return current;
                  });
                  setSelectedKeyword(null);
                  setOpenKeywordSuggestionBox(false);
                }}
              >
                {"- No selected keyword -"}
              </div>
              {
                <>
                  {keywords?.length > 0 ? (
                    <>
                      {keywords
                        ?.filter(
                          (item) =>
                            item?.keyword
                              .toLowerCase()
                              .indexOf(
                                selectedKeyword
                                  ? selectedKeyword.toLowerCase()
                                  : ""
                              ) > -1
                        )
                        ?.map((item) => (
                          <div
                            key={item?.id}
                            className="flex justify-between items-center"
                          >
                            {openKeywordEdit === item?.keyword_slug ? (
                              <div className="flex justify-between items-center mb-3 w-full gap-x-2">
                                <input
                                  type="text"
                                  placeholder="Edit keyword"
                                  className="border border-gray-300 px-3 py-2 rounded-lg w-full dark:text-white dark:bg-darkBg dark:border-none"
                                  value={editKeyword}
                                  onChange={(e) =>
                                    setEditKeyword(e.target.value)
                                  }
                                />
                                <button
                                  className="flex items-center justify-center rounded-[7px] border border-[#AAA] text-white text-base text-center font-medium px-2.5 py-1.5 bg-[#7190FF]"
                                  onClick={handleUpdateKeyword}
                                >
                                  {editKeywordLoading ? (
                                    <LoadingButton />
                                  ) : (
                                    "Confirm"
                                  )}
                                </button>
                              </div>
                            ) : (
                              <div
                                className="py-2 hover:text-secondary cursor-pointer"
                                onClick={(e) => {
                                  e.preventDefault();
                                  setQuestion((prev) => {
                                    const current = { ...prev };
                                    current.keyword_link = item?.id;
                                    return current;
                                  });
                                  setSelectedKeyword(item.keyword);
                                  setOpenKeywordSuggestionBox(false);
                                }}
                              >
                                {item.keyword}
                              </div>
                            )}
                            <div className="flex justify-center items-center gap-x-2 text-gray-600 dark:text-white">
                              {openKeywordEdit !== item?.keyword_slug && (
                                <>
                                  <Pencil
                                    className="w-4 h-4 cursor-pointer"
                                    onClick={() => {
                                      setOpenKeywordEdit(item?.keyword_slug);
                                      setEditKeyword(item?.keyword);
                                    }}
                                  />
                                  <X
                                    className="w-4 h-4 cursor-pointer text-red-500"
                                    onClick={() => {
                                      setIsOpenDeleteKeyword(true);
                                      setDeleteKeywordSlug(item?.keyword_slug);
                                    }}
                                  />
                                </>
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className="py-5">No result!</div>
                  )}
                </>
              }
            </div>
          )}
        </div>
        <button
          className="flex items-center justify-center rounded-[7px] border border-newPrimary text-white text-base text-center font-medium py-2.5 px-4 bg-newPrimary "
          onClick={handleAddKeyword}
          disabled={addKeywordLoading}
        >
          {addKeywordLoading ? <LoadingButton /> : "Add"}
        </button>
      </div>
    </div>
  );
};

export default KeywordDropdownForModal;

import { motion } from "framer-motion";
import { useState } from "react";
import { useNavigate } from "react-router";
import Nav from "../../../components/secondarynavbar/nav";
import WordBreak from "../../../components/wordbreak";
import { useGetUniqueEntityUrl } from "../../../query/useEntity";
const Link = () => {
  const navigate = useNavigate();
  // Assuming you have some way of determining the environment
  const [isCopied, setIsCopied] = useState(false);

  // Define the base URL based on the environment
  const baseUrl = window.location.origin; // Replace with your actual Vercel URL

  const { data, isLoading } = useGetUniqueEntityUrl();

  const redirectTo = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(baseUrl + "/" + data?.magic_link);
      setIsCopied(true);
    } catch (error) {
      console.error("Failed to copy link to clipboard", error);
    }
  };
  return (
    <div className="container">
      <Nav />
      <div className="flex h-full min-h-[calc(100vh-120px)]">
        <div className="w-full lg:w-[60%] mx-auto flex flex-col">
          <div className="w-full flex flex-col justify-center items-center pt-[70px]">
            {/* <img
              src="/orangearc.png"
              alt=""
              className="w-[75px] h-[75px] object-contain mb-[36px]"
            /> */}
            <motion.h1
              className="text-center text-[#323131] text-[26px] md:text-[45px] font-bold md:leading-[51px] mb-[21px] font-opensans"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.4, ease: "easeIn" }}
            >
              Link Generated
            </motion.h1>
            <motion.p
              className="text-[#10182899] font-montserrat md:leading-[28px] text-[15px] mb-[20px] text-center"
              initial={{ opacity: 0, x: 50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.4, ease: "easeIn" }}
            >
              Describe your existing or new business in detail, and receive{" "}
              <WordBreak /> feedback oriented questions.
            </motion.p>

            <div className="max-w-[600px] w-full mx-auto">
              <div className="w-full rounded-[7px] bg-[#EFEEEE] py-[10px] text-[#717171] flex flex-wrap pl-[8px] md:pl-[55px] pr-[8px] justify-between items-center mb-[23px]">
                <p className="text-base font-medium font-montserrat my-2 break-words w-[350px]">
                  {isLoading ? "Loading..." : baseUrl + "/" + data?.magic_link}
                </p>
                <button
                  onClick={handleCopy}
                  className={`bg-secondary text-white rounded-[7px] py-3 max-w-[175px] w-full font-montserrat font-semibold border hover:bg-white hover:text-secondary hover:border-secondary transition-all duration-200`}
                  // disabled={isCopied}
                >
                  {isCopied ? "Copied!" : "Copy"}
                </button>
              </div>
              {/* <p className="text-center text-[#F00] mb-[46px] font-montserrat">
                Note : Link will be expired in 14 Days
              </p> */}

              <div className="w-full flex items-center gap-[12px] mb-[27px]">
                <div className="w-1/2 border-b border-[#E1E1E1]"></div>
                <p className="mx-2 font-montserrat text-base text-[#10182899]">
                  Or
                </p>
                <div className="w-1/2 border-b border-[#E1E1E1]"></div>
              </div>

              <div className="flex flex-row justify-center gap-[7px]">
                {/* <button
                  className="bg-white text-[#4B4343] rounded-[7px] py-3 max-w-[175px] w-full font-montserrat border border-[#4B4343] font-semibold"
                  onClick={() => redirectTo("/pricing")}
                >
                  Subscribe Now
                </button> */}
                <button
                  className="bg-secondary text-white rounded-[7px] py-3 max-w-[175px] w-full font-montserrat font-semibold border hover:bg-white hover:text-secondary hover:border-secondary transition-all duration-200"
                  onClick={() => redirectTo("/dashboard")}
                >
                  Dashboard
                </button>
              </div>
            </div>
          </div>

          <div className="flex justify-center gap-3 mt-[40px]">
            <span
              className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out bg-[#EFEEEE]  
          `}
            ></span>

            <span
              className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out bg-[#EFEEEE]`}
            ></span>
            <span
              className={`w-2 h-2 rounded-full transition-all duration-300 ease-in-out bg-secondary `}
            ></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Link;

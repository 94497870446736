/* eslint-disable react/prop-types */
import { useState, createContext, useContext } from "react";
import Loading from "../components/loading/loading";
import { useUser } from "../query/useAuthentication";
export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState("");
  const [userInfo, setUserInfo] = useState(null); // [TODO: Remove this line]
  const [id, setId] = useState(null); // [TODO: Remove this line]
  // const [email, setEmail] = useState(localStorage.getItem("email") || null); // [TODO
  const [token, setToken] = useState(localStorage.getItem("token") || null);
  const [profile, setProfile] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false); // [TODO
  const [isNewUser, setIsNewUser] = useState(false); // [TODO: Remove this line]
  const [isExpired, setIsExpired] = useState(false); // [TODO: Remove this line]
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [otpVerified, setOtpVerified] = useState(false);
  const [passwordReset, setPasswordReset] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useUser({
    onSuccess: (data) => {
      setUser(data);
      if (
        data?.profile.subscription_expiration_date === null ||
        data?.profile.subscription_start_date === null
      ) {
        setIsNewUser(true);
      } else if (
        new Date(userInfo?.profile?.subscription_expiration_date).getTime() *
          1000 <
        new Date().getTime()
      ) {
        setIsExpired(true);
      }
    },
    revalidateOnFocus: false,
    enabled: Boolean(user),
  });

  // useEffect(() => {
  //   const timeoutId = setTimeout(() => {
  //     setIsLoading(false);
  //   }, 1500);

  //   return () => {
  //     clearTimeout(timeoutId);
  //   };
  // }, [token]);

  const login = (token) => {
    // TODO: Implement login logic
    localStorage.setItem("token", token.accessToken);
    localStorage.setItem("refreshToken", token.refreshToken);
  };

  const logout = () => {
    // TODO: Implement logout logic
    setUser(null);
    localStorage.removeItem("token");
    window.location.reload();
  };
  // isLoading ? null : ()
  return isLoading ? (
    <Loading />
  ) : (
    <AuthContext.Provider
      value={{
        user,
        token,
        login,
        logout,
        otpVerified,
        setOtpVerified,
        passwordReset,
        setPasswordReset,
        profile,
        setProfile,
        isSubscribed,
        setIsSubscribed,
        isLoading,
        SetIsLoading: setIsLoading,
        subscriptionPlan,
        setSubscriptionPlan,
        id,
        setUserInfo,
        userInfo,
        isNewUser,
        setIsNewUser,
        isExpired,
        setIsExpired,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

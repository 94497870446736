import React from "react";
import WordBreak from "../../../../components/wordbreak";
export const MagiclinkIllustrations = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/magiclink.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[22px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
        Add Magiclink
      </h1>
      <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
        Your Magiclinks will appear here!
      </p>
    </div>
  );
};
export const MagicCardIllustrations = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/magiclink.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[22px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
        Add Magiccard
      </h1>
      <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
        Your Magiccards will appear here!
      </p>
    </div>
  );
};

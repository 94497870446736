/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { CircleCheck, Info } from "lucide-react";
import CountdownProgressBar from "./CountdownProgressBar";

const CopyModal = ({ isOpenModal, setIsOpenmodal }) => {
  return (
    <Modal
      popup
      dismissible
      show={isOpenModal}
      onClose={() => {
        setIsOpenmodal(false);
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative w-full h-auto p-0 !border-none !outline-0",
          inner: "bg-white rounded-lg",
        },
      }}
      size={"md"}
    >
      <Modal.Body>
        <div className="p-5 flex items-center justify-center gap-2 font-heebo">
          <CircleCheck className="fill-secondary text-white" />
          <p className="font-medium text-[#252323]">
            Your review has been copied!
          </p>
        </div>
        <CountdownProgressBar />
        <hr />
        <div className="p-5 flex flex-col items-center justify-center font-heebo gap-5">
          <p className="text-center text-sm">
            Please go to the review section and paste your review and add
            ratings.
          </p>
          <img src="/review-popup.svg" alt="" />
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default CopyModal;

import { useEffect } from "react";

export const useOnClickOutside = ({ ref, setIsOpen, prevent = false }) => {
  useEffect(() => {
    if (prevent === false) {
      const handleClickOutside = (event) => {
        // Close the Platform dropdown if clicked outside
        if (ref.current && !ref.current.contains(event.target)) {
          setIsOpen(false);
        }
      };

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, []);
};

/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import RaReviewsCard from "./RaReviewsCard";

const RaReviewsModal = ({ isOpen, setIsOpen, data }) => {
  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen()}>
      <DialogContent
        className="lg:max-w-6xl xl:max-w-7xl bg-transparent dark:bg-transparent border-none"
        hideClose={true}
      >
        <DialogHeader className={"hidden"}>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        <div className="pt-6 px-6 flex flex-col lg:flex-row h-[70vh] lg:h-auto lg:overflow-x-auto overflow-y-auto scrollbar-thin divide-y-2 lg:divide-y-0 gap-5">
          {data?.all_reviews
            ?.filter((item) => item?.is_posted_ai_review)
            .map((item, i) => (
              <RaReviewsCard
                review={item}
                key={i}
                name={data?.reviewer_name}
                createdAt={data?.created_at}
                magicLink={data?.magiclink_used}
              />
            ))}
          {data?.all_reviews
            ?.filter((item) => !item?.is_posted_ai_review)
            .map((item, i) => (
              <RaReviewsCard
                review={item}
                key={i}
                name={data?.reviewer_name}
                createdAt={data?.created_at}
                magicLink={data?.magiclink_used}
              />
            ))}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RaReviewsModal;

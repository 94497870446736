import { useState, createContext, useContext } from "react";
import { useNavigate } from "react-router";
export const OnBoardingContext = createContext(null);

export const OnBoardingProvider = ({ children }) => {
  const navigate = useNavigate();
  const [selectedScreen, setSelectedScreen] = useState("");
  const [selectedIndustry, setSelectedIndustry] = useState("");

  const handleSelectedScreen = (screen) => {
    window.scrollTo(0, 0);
    setSelectedScreen(screen);
    navigate(`/onboarding?selectedscreen=${screen}`);
  };

  return (
    <OnBoardingContext.Provider
      value={{
        selectedScreen,
        handleSelectedScreen,
        selectedIndustry,
        setSelectedIndustry,
      }}
    >
      {children}
    </OnBoardingContext.Provider>
  );
};

export const useOnBoarding = () => useContext(OnBoardingContext);

/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { Asterisk, CalendarDays } from "lucide-react";
import { forwardRef, useState } from "react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import { useGetDownloadReviews } from "../../../../query/useReview";
import { toast } from "sonner";
import { LogoLoading } from "../../../../components/loading/loading";
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { usePostRequestReview } from "../../../../query/useEntity";

const RequestReviewModal = ({ openModal, setOpenModal }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [magiclink, setMagiclink] = useState("");

  const { mutate: requestReviews, isLoading } = usePostRequestReview();

  const handleClick = () => {
    requestReviews(
      {
        name: name,
        email: email,
        magiclink: magiclink,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setOpenModal(false);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
      }}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Request Reviews</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5 dark:text-[#bfbfbf]">
          <div className="flex flex-col gap-3">
            <p className="flex items-center gap-0.5">
              Name <Asterisk className="text-red-500 size-4" />
            </p>
            <Input value={name} onChange={(e) => setName(e.target.value)} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="flex items-center gap-0.5">
              Email <Asterisk className="text-red-500 size-4" />
            </p>
            <Input value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <div className="flex flex-col gap-3">
            <p className="flex items-center gap-0.5">
              Magic Link <Asterisk className="text-red-500 size-4" />
            </p>
            <Input
              value={magiclink}
              onChange={(e) => setMagiclink(e.target.value)}
            />
          </div>
          <div className="flex justify-center items-center gap-x-3 mt-7">
            <Button
              className="w-full"
              variant="secondary"
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="w-full"
              onClick={handleClick}
              disabled={!name || !email || !magiclink}
            >
              Request
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default RequestReviewModal;

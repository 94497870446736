/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { toast } from "sonner";
import { useEffect, useMemo, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDeleteEntityQuestion,
  useDeleteEntityQuestionsV2,
  useDeleteQuestionCategories,
  useDeleteQuestionKeyword,
  useGetQuestionCategories,
  useGetQuestionKeywords,
  useGetQuestionSets,
  useGetSetIdsForQuestions,
  usePostAddEntityQuestion,
  usePostAddQuestionCategories,
  usePostAddQuestionKeyword,
  usePostAddQuestionSet,
  usePostUpdateEntityQuestionsV2,
  usePutEditQuestionCategories,
  usePutEditQuestionKeyword,
  usePutUpdateEntityQuestion,
} from "../../../query/useEntity";
import {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import { Asterisk, CirclePlus, CircleX, Pencil, X } from "lucide-react";
import { Switch } from "@headlessui/react";
import { useOnClickOutside } from "../../../hooks/useOnClickOutside";
import { DeleteModal } from "./modal";
import QuestionSetDropdownForModal from "./QuestionSetDropdownForModal";
import CategoryDropdownForModal from "./CategoryDropdownForModal";
import KeywordDropdownForModal from "./KeywordDropdownForModal";
import ResponseOptionsForModal from "./ResponseOptionsForModal";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Button } from "../../../components/ui/button";

const EditQuestionModal = ({
  openEditModal,
  setOpenEditModal,
  refetchQuesitons,
  item,
  // question,
  // setQuestion,
}) => {
  const inputRef = useRef(null);
  // const categoryRef = useRef(null);
  // const keywordRef = useRef(null);
  // const questionSetRef = useRef(null);
  // const [openSuggestionBox, setOpenSuggestionBox] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  // const [newCategory, setNewCategory] = useState("");
  // const [openCategoryEdit, setOpenCategoryEdit] = useState("");
  // const [editCategory, setEditCategory] = useState("");
  const [question, setQuestion] = useState({
    question: "",
    category_link: null,
    keyword_link: null,
    response_options: ["Excellent", "Good", "Average", "Bad"],
    has_multiple_responses: false,
    show_as: "CHIPS",
    sets: [],
    is_mandatory: false,
  });

  // const [openKeywordSuggestionBox, setOpenKeywordSuggestionBox] =
  //   useState(false);
  const [selectedKeyword, setSelectedKeyword] = useState("");
  // const [newKeyword, setNewKeyword] = useState("");
  // const [openKeywordEdit, setOpenKeywordEdit] = useState("");
  // const [editKeyword, setEditKeyword] = useState("");

  // const [openQuestionSetBox, setOpenQuestionSetBox] = useState(false);
  const [selectedQuestionSets, setSelectedQuestionSets] = useState([]);
  // const [newQuestionSet, setNewQuestionSet] = useState("");

  const [isOpenDeleteCategory, setIsOpenDeleteCategory] = useState(false);
  const [deleteCategorySlug, setDeleteCategorySlug] = useState("");

  const [isOpenDeleteKeyword, setIsOpenDeleteKeyword] = useState(false);
  const [deleteKeywordSlug, setDeleteKeywordSlug] = useState("");

  const { data: categories, refetch: refetchCategory } =
    useGetQuestionCategories();
  // const { mutate: addCategory, isLoading: addCategoryLoading } =
  //   usePostAddQuestionCategories();
  // const { mutate: updateCategory, isLoading: editCategoryLoading } =
  //   usePutEditQuestionCategories();
  const { mutate: deleteCategory, isLoading: deleteCategoryLoading } =
    useDeleteQuestionCategories();

  const { data: keywords, refetch: refetchKeyword } = useGetQuestionKeywords();
  // const { mutate: addKeyword, isLoading: addKeywordLoading } =
  //   usePostAddQuestionKeyword();
  // const { mutate: updateKeyword, isLoading: editKeywordLoading } =
  //   usePutEditQuestionKeyword();
  const { mutate: deleteKeyword, isLoading: deleteKeywordLoading } =
    useDeleteQuestionKeyword();

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();
  // const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
  //   usePostAddQuestionSet();

  const { data: setIdsForQuestions, isLoading: setIdsForQuesitonsLoading } =
    useGetSetIdsForQuestions(item?.id, {
      enabled: Boolean(item?.id),
    });

  const { mutate: addQuestion, isLoading: addQuestionLoading } =
    usePostAddEntityQuestion();
  const { mutate: updateQuestion, isLoading: updateQuestionLoading } =
    usePutUpdateEntityQuestion();
  const queryClient = useQueryClient();

  // const handleAddCategory = () => {
  //   addCategory(
  //     {
  //       category: selectedCategory,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Added new category successfully");
  //         refetchCategory();
  //         setSelectedCategory(res?.question_category?.category);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           current.category_link = res?.question_category?.id;
  //           return current;
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleUpdateCategory = () => {
  //   updateCategory(
  //     {
  //       category: editCategory,
  //       category_slug: openCategoryEdit,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Updated category successfully");
  //         setOpenCategoryEdit("");
  //         refetchCategory();
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error("Something went wrong");
  //         }
  //       },
  //     }
  //   );
  // };

  const handleDeleteCategory = () => {
    deleteCategory(deleteCategorySlug, {
      onSuccess: (res) => {
        toast.success("Deleted category successfully");
        refetchCategory();
        setIsOpenDeleteCategory(false);
        const deleteCategory = deleteCategorySlug?.split("-")?.join(" ");
        if (deleteCategory === selectedCategory?.toLowerCase()) {
          setSelectedCategory(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.category_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  // const handleAddKeyword = () => {
  //   addKeyword(
  //     {
  //       keyword: selectedKeyword,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Added new keyword successfully");
  //         refetchKeyword();
  //         setSelectedKeyword(res?.question_keyword?.keyword);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           current.keyword_link = res?.question_keyword?.id;
  //           return current;
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  // const handleUpdateKeyword = () => {
  //   updateKeyword(
  //     {
  //       keyword: editKeyword,
  //       keyword_slug: openKeywordEdit,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success("Updated keyword successfully");
  //         setOpenKeywordEdit("");
  //         refetchKeyword();
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else if (err.response.data["question:"]) {
  //           toast.error(err.response.data["question:"]);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  const handleDeleteKeyword = () => {
    deleteKeyword(deleteKeywordSlug, {
      onSuccess: (res) => {
        toast.success("Deleted keyword successfully");
        refetchKeyword();
        setIsOpenDeleteKeyword(false);
        const deleteKeyword = deleteKeywordSlug?.split("-")?.join(" ");
        if (deleteKeyword === selectedKeyword?.toLowerCase()) {
          setSelectedKeyword(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.keyword_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  // const handleAddQuestionSet = () => {
  //   addQuestionSet(
  //     {
  //       name: newQuestionSet,
  //     },
  //     {
  //       onSuccess: (res) => {
  //         toast.success(res.message);
  //         setNewQuestionSet("");
  //         setSelectedQuestionSets((prev) => [
  //           ...prev,
  //           { name: res?.Set?.name },
  //         ]);
  //         setQuestion((prev) => {
  //           const current = { ...prev };
  //           const currentSets = [...current.sets, { name: res?.Set?.name }];
  //           return { ...current, sets: currentSets };
  //         });
  //       },
  //       onError: (err) => {
  //         if (err.response.data.message) {
  //           toast.error(err.response.data.message);
  //         } else {
  //           toast.error(
  //             `Something went wrong, try again later ${err.response.status}`
  //           );
  //         }
  //       },
  //     }
  //   );
  // };

  const handlePush = () => {
    updateQuestion(
      {
        id: item.id,
        data: {
          question: question.question,
          category_link: question.category_link,
          keyword_link: question.keyword_link,
          response_options: question.response_options,
          has_multiple_responses: question.has_multiple_responses,
          show_as: question.show_as,
          sets: question.sets,
          is_mandatory: question.is_mandatory,
        },
      },
      {
        onSuccess: (res) => {
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestions"],
          });
          queryClient.invalidateQueries({
            queryKey: ["getDefaultQuestionsForSets"],
          });
          setOpenEditModal(false);
          toast.success("Question updated successfully");
          setQuestion();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else if (err.response.data.category_link) {
            toast.error(err.response.data.category_link[0]);
          } else if (err.response.data.keyword_link) {
            toast.error(err.response.data.keyword_link[0]);
          } else if (err.response.data["question:"]) {
            toast.error(err.response.data["question:"][0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleResponseOptionChange = (value, index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      current.response_options[index] = value;
      return current;
    });
  };

  const handleResponseOptionDelete = (index) => {
    setQuestion((prev) => {
      const current = { ...prev };
      const options = [...current.response_options];
      current.response_options = options.filter((_, i) => i !== index);
      return current;
    });
  };

  const handleAddOption = () => {
    setQuestion((prev) => {
      const current = { ...prev };
      let options;
      if (current.response_options === null) {
        options = ["New Option"];
      } else {
        options = [...current.response_options];
        options.push("New Option");
      }

      current.response_options = options;
      return current;
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setQuestion((prev) => {
          const current = { ...prev };
          const addedValues = e.target.result
            ?.split("\n")
            ?.map((item) => item.trim());
          let options;
          if (current.response_options === null) {
            options = [...addedValues];
          } else {
            options = [...current.response_options, ...addedValues];
          }
          current.response_options = options;
          return current;
        });
      };
      reader.readAsText(file);
    }
    e.target.value = "";
  };

  const isLoading = addQuestionLoading || updateQuestionLoading;

  const sets = useMemo(() => {
    return questionSets
      ?.filter((item) => setIdsForQuestions?.set_ids?.includes(item?.id))
      ?.map((item) => {
        return {
          name: item?.name,
        };
      });
  }, [questionSets, setIdsForQuestions]);

  useEffect(() => {
    if (item) {
      setSelectedCategory(item?.category);
      setSelectedKeyword(item?.keyword);
      setSelectedQuestionSets(sets);
      setQuestion((prev) => {
        const current = { ...prev };
        (current.question = item?.question),
          (current.category_link = item?.category_link);
        current.keyword_link = item?.keyword_link;
        current.response_options = item?.response_options;
        current.has_multiple_responses = item?.has_multiple_responses;
        current.show_as = item?.show_as;
        current.sets = sets;
        current.is_mandatory = item?.is_mandatory;
        return current;
      });
    }
  }, [item, sets]);

  if (isLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <Dialog
        // popup
        // dismissible={isOpenDeleteCategory || isOpenDeleteKeyword ? false : true}
        open={openEditModal}
        onOpenChange={() => {
          setOpenEditModal(false);
        }}
      >
        <DialogContent className="lg:max-w-4xl">
          <DialogHeader>
            <DialogTitle className="text-[20px] font-medium font-pro ">
              Edit Question
            </DialogTitle>
          </DialogHeader>
          <div className="px-6 flex flex-col scrollbar-none font-pro">
            <div className="h-[70vh] overflow-y-auto scrollbar-thin p-1">
              <QuestionSetDropdownForModal
                questionSets={questionSets}
                selectedQuestionSets={selectedQuestionSets}
                setSelectedQuestionSets={setSelectedQuestionSets}
                setQuestion={setQuestion}
              />
              <CategoryDropdownForModal
                selectedCategory={selectedCategory}
                setSelectedCategory={setSelectedCategory}
                setQuestion={setQuestion}
                isOpenDeleteCategory={isOpenDeleteCategory}
                setIsOpenDeleteCategory={setIsOpenDeleteCategory}
                setDeleteCategorySlug={setDeleteCategorySlug}
                categories={categories}
                refetchCategory={refetchCategory}
              />
              <KeywordDropdownForModal
                selectedKeyword={selectedKeyword}
                setSelectedKeyword={setSelectedKeyword}
                setQuestion={setQuestion}
                isOpenDeleteKeyword={setIsOpenDeleteKeyword}
                setIsOpenDeleteKeyword={setIsOpenDeleteKeyword}
                setDeleteKeywordSlug={setDeleteKeywordSlug}
                keywords={keywords}
                refetchKeyword={refetchKeyword}
              />

              <ResponseOptionsForModal
                question={question}
                setQuestion={setQuestion}
              />

              <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end mt-5">
                <Button
                  className="px-10"
                  variant="secondary"
                  onClick={() => {
                    setOpenEditModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="px-10"
                  onClick={() => {
                    handlePush();
                  }}
                  disabled={isLoading || selectedQuestionSets?.length === 0}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <DeleteModal
        openModal={isOpenDeleteCategory}
        setOpenModal={setIsOpenDeleteCategory}
        title={`Are you sure want to delete the category "${categories?.find((item) => item?.category_slug === deleteCategorySlug)?.category}"`}
        handleDelete={handleDeleteCategory}
        isLoading={deleteCategoryLoading}
      />
      <DeleteModal
        openModal={isOpenDeleteKeyword}
        setOpenModal={setIsOpenDeleteKeyword}
        title={`Are you sure want to delete the keyword "${keywords?.find((item) => item?.keyword_slug === deleteKeywordSlug)?.keyword}"`}
        handleDelete={handleDeleteKeyword}
        isLoading={deleteKeywordLoading}
      />
    </>
  );
};

export default EditQuestionModal;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Input } from "../../../../components/ui/input";
import { Textarea } from "../../../../components/ui/textarea";
import { Button } from "../../../../components/ui/button";
import { usePutPositivePrompt } from "../../../../query/useReview";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { LogoLoading } from "../../../../components/loading/loading";

const EditConditionModal = ({ openModal, setOpenModal, condition }) => {
  const queryClient = useQueryClient();
  const [title, setTitle] = useState("");
  const [conditionStatement, setConditionStatement] = useState("");
  const {
    mutate: updatePositivePrompt,
    isLoading: updatePositivePromptLoading,
  } = usePutPositivePrompt();

  const handleUpdatePositivePrompt = () => {
    updatePositivePrompt(
      {
        id: condition?.id,
        data: {
          title: title,
          condition: conditionStatement,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          setOpenModal(false);
          queryClient.invalidateQueries(["getPositivePrompt"]);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    setTitle(condition?.title);
    setConditionStatement(condition?.condition);
  }, [condition]);

  if (updatePositivePromptLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(false);
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Edit Condition</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro space-y-5">
          <div className="w-full space-y-3">
            <label htmlFor="title" className="font-medium text-sm">
              Title
            </label>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              id="title"
            />
          </div>
          <div className="w-full space-y-3">
            <label htmlFor="condition" className="font-medium text-sm">
              Condition
            </label>
            <Textarea
              value={conditionStatement}
              onChange={(e) => setConditionStatement(e.target.value)}
              id="condition"
            />
          </div>
          <div className="flex justify-end gap-3">
            <Button className="gap-3" onClick={handleUpdatePositivePrompt}>
              Confirm
            </Button>
            <Button
              variant={"secondary"}
              onClick={() => {
                setOpenModal(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default EditConditionModal;

/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react";
import { dayFormatter } from "../../../../utils/formatter";
import { Check, Link, Play, Square } from "lucide-react";
import Booking from "../../../../assets/reviewservices/booking.svg";
import Google from "../../../../assets/reviewservices/google.svg";
import TripAdvisor from "../../../../assets/reviewservices/tripadvisor.svg";
import TrustPilot from "../../../../assets/reviewservices/trustpilot.svg";
import Yelp from "../../../../assets/reviewservices/yelp.svg";

const RaReviewsCard = ({ review, createdAt, name, magicLink }) => {
  const audioRef = useRef(null);
  const [url, setUrl] = useState("");
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  const handleGoToMagiclink = (link) => {
    window.open(link);
  };

  useEffect(() => {
    const audio = audioRef.current;

    const handleMetadataLoaded = () => {
      setDuration(audio.duration);
    };

    const handleTimeUpdate = () => {
      setCurrentTime(audio.currentTime);
    };

    audio.addEventListener("timeupdate", handleTimeUpdate);
    audio.addEventListener("durationchange", handleMetadataLoaded);

    return () => {
      audio.removeEventListener("timeupdate", handleTimeUpdate);
      audio.removeEventListener("durationchange", handleMetadataLoaded);
    };
  }, [url]);

  useEffect(() => {
    if (Math.round(duration) === Math.round(currentTime)) {
      setIsPlaying(false);
    }
  }, [currentTime, duration]);

  return (
    <div
      className="flex flex-col gap-y-5 bg-white dark:bg-darkBg p-5 lg:max-w-[350px] xl:max-w-[380px] 2xl:max-w-[400px] rounded-[10px]"
      key={review?.uuid}
    >
      <div className="flex flex-col gap-[35px]">
        <div className="flex items-center justify-between">
          <div className="flex gap-4 items-center">
            <img
              src={review?.profile_photo_url || "/new-logo.svg"}
              alt=""
              className="w-[50px] h-[50px]"
              referrerPolicy="no-referrer"
            />
            <div>
              <h1 className="font-pro text-base font-medium mb-1">
                {name || "MagicReview"}
              </h1>
              <p className="text-[#777] text-[13px] font-pro ">
                {createdAt && dayFormatter(createdAt)}
              </p>
            </div>
          </div>
          <div className="flex items-center gap-3">
            <audio ref={audioRef}></audio>
            {review?.voice_review && (
              <>
                {isPlaying ? (
                  <button
                    className="p-2 rounded-full bg-newPrimary text-white"
                    onClick={() => {
                      setIsPlaying(false);
                      audioRef.current.pause();
                      audioRef.current.value = "";
                      setUrl("");
                    }}
                  >
                    <Square className="fill-white" />
                  </button>
                ) : (
                  <button
                    className="p-2 rounded-full bg-newPrimary text-white"
                    //   onClick={() => handleGetAudioUrl(review?.original_voice)}
                    //   disabled={audioUrlLoading}
                  >
                    {/* {audioUrlLoading ? (
                        <div className="flex items-center justify-center">
                          <div className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full animate-spin"></div>
                        </div>
                      ) : ( */}
                    <Play className="fill-white" />
                    {/* )} */}
                  </button>
                )}
              </>
            )}
            {review?.is_posted_ai_review && (
              <div className="p-2 rounded-lg bg-[#34a853]/15 text-[#34a853] font-pro flex items-center gap-2">
                <Check className="size-4" /> Posted
              </div>
            )}
            {/* {review?.is_posted_ai_review && (
              <div className="p-2 rounded-full bg-[#F0F5FF] text-[#4F75FA]">
                <Check />
              </div>
            )} */}
          </div>
        </div>
      </div>

      <p className="text-[#777] dark:text-white text-sm font-pro mt-[10px]">
        {review?.text}
      </p>

      {/* <div className="flex items-center gap-x-2 mt-2">
        {review?.source === "G" && (
          <div className="p-2 rounded-full bg-[#F6F6FF]">
            <img src={Google} className="w-6 h-6" />
          </div>
        )}
        {review?.source === "T" && (
          <div className="p-2 rounded-full bg-[#F6F6FF]">
            <img src={TrustPilot} className="w-6 h-6" />
          </div>
        )}
        {review?.source === "Y" && (
          <div className="p-2 rounded-full bg-[#F6F6FF]">
            <img src={Yelp} className="w-6 h-6" />
          </div>
        )}
        {review?.source === "B" && (
          <div className="p-2 rounded-full bg-[#F6F6FF]">
            <img src={Booking} className="w-6 h-6" />
          </div>
        )}
        {review?.source === "TA" && (
          <div className="p-2 rounded-full bg-[#F6F6FF]">
            <img src={TripAdvisor} className="w-6 h-6" />
          </div>
        )}
      </div> */}

      {/* <div className="flex justify-end items-center gap-x-2 mt-2">
        {review?.is_posted_ai_review && (
          <div className="p-2 rounded-lg bg-[#F0F5FF] text-[#4F75FA] font-pro">
            Posted
          </div>
        )}
      </div> */}
    </div>
  );
};

export default RaReviewsCard;

/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { dayFormatter } from "../../../../utils/formatter";
import { Star } from "lucide-react";
import { Button } from "../../../../components/ui/button";
import { Textarea } from "../../../../components/ui/textarea";
import {
  useDeleteGmbReviewReply,
  usePutEditGmbReviewReply,
} from "../../../../query/useAuthentication";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import { LogoLoading } from "../../../../components/loading/loading";
import { DeleteModal } from "../../component/modal";
import { useNavigate } from "react-router";

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const ReviewCardGoogle = ({ review }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [reviewReply, setReviewReply] = useState("");
  const [originalReviewReply, setOriginalReviewReply] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const { mutate: editReviewReply, isLoading: editReviewReplyLoading } =
    usePutEditGmbReviewReply();

  const { mutate: deleteReviewReply, isLoading: deleteReviewReplyLoading } =
    useDeleteGmbReviewReply();

  const handleEditReviewReply = () => {
    editReviewReply(
      {
        review_id: review?.review_id,
        comment: reviewReply,
      },
      {
        onSuccess: (res) => {
          toast.success("Your reply has been posted");
          queryClient.invalidateQueries(["getGmbReviews"]);
          setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteReviewReply = () => {
    deleteReviewReply(
      {
        review_id: review?.review_id,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries(["getGmbReviews"]);
          setIsOpenDeleteModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleNavigate = (reviewId) => {
    navigate(`/dashboard/ai/auto-reply?reviewId=${reviewId}`);
  };

  useEffect(() => {
    if (review?.review_reply) {
      setReviewReply(review?.review_reply);
      setOriginalReviewReply(review?.review_reply);
    }
  }, [review]);

  if (editReviewReplyLoading) {
    return <LogoLoading />;
  }

  return (
    <div
      className="flex flex-col gap-y-5 bg-white dark:bg-darkBg w-full"
      key={review?.review_id}
    >
      <div className="flex flex-col gap-[35px]">
        <div className="flex items-center justify-between flex-wrap gap-5">
          <div className="flex gap-4 items-center">
            <img
              src={review?.profile_photo_url || "/new-logo.svg"}
              alt=""
              className="w-[50px] h-[50px]"
              referrerPolicy="no-referrer"
            />
            <div>
              <h1 className="font-pro text-base font-medium mb-1">
                {review?.display_name || "MagicReview"}
              </h1>
              <p className="text-[#777] text-[13px] font-pro ">
                {review?.update_time && dayFormatter(review?.update_time)}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-[3px]">
        {[...Array(5)].map((_, index) => (
          <svg key={index} width="20" height="20" fill="none">
            <path
              fill={
                index < wordsToNumber[review?.star_rating] ? "#F9C33C" : "#DDD"
              }
              d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
            />
          </svg>
        ))}
      </div>

      <p className="text-[#777] dark:text-white text-sm font-pro mt-[10px]">
        {review?.comment}
      </p>

      {review?.review_reply ? (
        <div className="p-5 rounded-[12px] bg-[#f0f5ff] font-pro text-sm text-[#4a4a4a]">
          {isEditable ? (
            <>
              <Textarea
                value={reviewReply}
                onChange={(e) => setReviewReply(e.target.value)}
              />
              <div className="flex gap-1 mt-3">
                <Button variant="secondary" onClick={handleEditReviewReply}>
                  Confirm
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditable(false);
                    setReviewReply(originalReviewReply);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <>
              {reviewReply}
              <div className="flex gap-1 mt-3">
                <Button
                  variant="secondary"
                  onClick={() => handleNavigate(review?.review_id)}
                >
                  Edit
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => setIsOpenDeleteModal(true)}
                >
                  Delete
                </Button>
              </div>
            </>
          )}
        </div>
      ) : (
        <>
          {isEditable ? (
            <>
              <Textarea
                value={reviewReply}
                onChange={(e) => setReviewReply(e.target.value)}
              />
              <div className="flex gap-1 mt-3">
                <Button variant="secondary" onClick={handleEditReviewReply}>
                  Confirm
                </Button>
                <Button
                  variant="secondary"
                  onClick={() => {
                    setIsEditable(false);
                    setReviewReply(originalReviewReply);
                  }}
                >
                  Cancel
                </Button>
              </div>
            </>
          ) : (
            <div className="flex justify-end">
              <Button
                variant="secondary"
                onClick={() => handleNavigate(review?.review_id)}
              >
                Reply
              </Button>
            </div>
          )}
        </>
      )}

      <DeleteModal
        openModal={isOpenDeleteModal}
        setOpenModal={setIsOpenDeleteModal}
        title={`Are you sure you want to delete the reply?`}
        isLoading={deleteReviewReplyLoading}
        handleDelete={handleDeleteReviewReply}
      />
    </div>
  );
};

export default ReviewCardGoogle;

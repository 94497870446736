import { useState } from "react";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { useGetBuyCardHistory } from "../../../query/usePayment";
import { InvoiceIllustrations } from "../../onboarding/components/illustrations/InvoiceIllustrations";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import Skeleton from "react-loading-skeleton";
import { dayFormatter } from "../../../utils/formatter";
import Layout from "../component/Layout";

const BuyCardHistory = () => {
  const { darkMode } = useDarkMode();
  // const [selectedDownload, setSelectedDownload] = useState("");
  const { data: charges, isLoading } = useGetBuyCardHistory();

  // const handleDownload = (id, date) => {
  //   setSelectedDownload(id);
  //   downloadInvoice(id, {
  //     onSuccess: (res) => {
  //       const blob = new Blob([res.data], { type: res.data.type });
  //       const link = document.createElement("a");
  //       link.href = window.URL.createObjectURL(blob);
  //       link.download = `Invoice ${date && dayFormatter(date)}.pdf`;
  //       link.click();
  //       toast.success("Invoice downloaded successfully");
  //     },
  //     onError: (err) => {
  //       if (err.response.data.message) {
  //         toast.error(err.response.data.message);
  //       } else {
  //         toast.error(
  //           `Something went wrong, try again later ${err.response.status}`
  //         );
  //       }
  //     },
  //   });
  // };

  return (
    <Layout title={"Card Payment History"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]">
        <div className="overflow-x-auto">
          {charges?.length <= 0 ? (
            <InvoiceIllustrations />
          ) : (
            // <div className="w-full min-h-[400px] flex flex-col justify-center items-center space-y-3">
            //   <img src="/empty/no-invoice.svg" alt="" />
            //   <p className="font-pro text-[#888] text-sm">
            //     Your invoices will appear here
            //   </p>
            // </div>
            <div className="rounded-xl overflow-hidden">
              <Table>
                <TableHeader>
                  <TableRow>
                    {[
                      "Payment Id",
                      "Customer Id",
                      "Date",
                      "Amount",
                      "Shipping Name",
                      "Shipping Address",
                    ].map((item) => (
                      <TableHead
                        key={item}
                        className="text-[#808080] dark:text-white text-[15px]"
                      >
                        {item}
                      </TableHead>
                    ))}
                  </TableRow>
                </TableHeader>
                <TableBody className="divide-y">
                  {isLoading ? (
                    <TableRow>
                      {new Array(6).fill("").map((_, i) => (
                        <TableCell key={i} className="bg-white dark:bg-darkBg">
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </TableCell>
                      ))}
                    </TableRow>
                  ) : charges?.length > 0 ? (
                    charges?.map((charge, i) => (
                      <TableRow key={i} className="text-[15px]">
                        <TableCell>{charge?.payment_id}</TableCell>
                        <TableCell>{charge?.customer_id}</TableCell>
                        <TableCell className="">
                          {charge?.payment_time &&
                            dayFormatter(charge?.payment_time)}
                        </TableCell>
                        <TableCell>
                          {charge?.currency &&
                            charge?.total_amount &&
                            Number(charge?.total_amount)?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: charge?.currency,
                              }
                            )}
                        </TableCell>
                        <TableCell>{charge?.shipping_address_name}</TableCell>
                        <TableCell>
                          {[
                            charge?.address_line_1,
                            charge?.address_line_2,
                            charge?.city,
                            charge?.state,
                            charge?.country,
                            charge?.postal_code,
                          ]?.join(", ")}
                        </TableCell>
                        {/* <TableCell>
                          {invoiceLoading && selectedDownload === charge?.uuid ? (
                            <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
                          ) : (
                            <Download
                              className="text-[#a6a6a6] cursor-pointer"
                              onClick={() => {
                                handleDownload(charge?.uuid, charge?.created_at);
                              }}
                            />
                          )}
                        </TableCell> */}
                      </TableRow>
                    ))
                  ) : null}
                </TableBody>
              </Table>
            </div>
            // <Card padding={0}>
            //   <IndexTable
            //     loading={false}
            //     itemCount={charges?.results?.length}
            //     headings={[
            //       { title: <span className="text-lg">Date</span> },
            //       { title: <span className="text-lg">Amount</span> },
            //       { title: <span className="text-lg">Status</span> },
            //       { title: <span className="text-lg">Download</span> },
            //     ]}
            //     selectable={false}
            //   >
            //     {isLoading ? (
            //       <IndexTable.Row>
            //         {new Array(4).fill("").map((_, i) => (
            //           <IndexTable.Cell
            //             key={i}
            //             className="bg-white dark:bg-darkBg"
            //           >
            //             <Skeleton height={30} baseColor={darkMode && "#262626"} />
            //           </IndexTable.Cell>
            //         ))}
            //       </IndexTable.Row>
            //     ) : (
            //       charges?.results?.map((charge, i) => (
            //         <IndexTable.Row key={i}>
            //           <IndexTable.Cell className="">
            //             {charge?.created_at && dayFormatter(charge?.created_at)}
            //           </IndexTable.Cell>
            //           <IndexTable.Cell>
            //             {charge?.currency}{" "}
            //             {charge?.unit_amount / charge?.currency_unit_multiplier}{" "}
            //             {"("}
            //             {charge?.plan_text}
            //             {")"}
            //           </IndexTable.Cell>
            //           <IndexTable.Cell className="capitalize">
            //             {charge?.status}
            //           </IndexTable.Cell>
            //           <IndexTable.Cell>
            //             {invoiceLoading && selectedDownload === charge?.uuid ? (
            //               <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
            //             ) : (
            //               <Download
            //                 className="text-[#a6a6a6] cursor-pointer"
            //                 onClick={() => {
            //                   handleDownload(charge?.uuid, charge?.created_at);
            //                 }}
            //               />
            //             )}
            //           </IndexTable.Cell>
            //         </IndexTable.Row>
            //       ))
            //     )}
            //   </IndexTable>
            // </Card>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default BuyCardHistory;

import Layout from "../dashboard/component/Layout";
import { Mic, Sparkles, SquareCheckBig, Star, UsersRound } from "lucide-react";
import {
  useGetBillingStats,
  useGetMasterStats,
  useGetPerformance,
  useGetSubUsers,
} from "../../query/useMaster";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useDarkMode } from "../../context/DarkModeProvider";
import AiPostedGraph from "./components/AiPostedGraph";

const Master = () => {
  const baseUrl = window.location.pathname.includes("magicreview.ai")
    ? "https://magicreviewstorage.blob.core.windows.net/magicreview/"
    : "https://magicreviewstorage.blob.core.windows.net/magicreview-dev/";
  const { data: subusers, isLoading: subUsersLoading } = useGetSubUsers();
  const { data: topPerformance } = useGetPerformance("top");
  const { data: bottomPerformance } = useGetPerformance("down");

  const { data: stats, isLoading: statsLoading } = useGetMasterStats();
  const { data: billingStats, isLoading: billingStatsLoading } =
    useGetBillingStats();
  const { darkMode } = useDarkMode();
  const isMainLoading = statsLoading;
  return (
    <Layout>
      <div className="flex flex-col mt-[20px] w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-200px)] 3xl:min-h-[calc(100vh-150px)] px-5 lg:px-[30.2px]">
        <div className="flex flex-col md:flex-row gap-5 w-full mb-5 font-pro">
          <div className="flex w-full md:max-w-[230px] flex-col rounded-[12px]   bg-newPrimary p-5 shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-secondary">
                  <UsersRound className="text-white w-5 h-5" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-base text-white">
                  My Users
                </p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className="mt-5"
                height={50}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[38px] text-white text-center">
                  {subusers?.total_sub_users}
                </p>
                {/* <Link
                  className="w-auto mx-auto rounded-full border border-[#e5ebff] text-[#e5ebff] px-3 py-1.5 mt-5"
                  to={"/master/users"}
                >
                  See details
                </Link> */}
              </>
            )}
          </div>
          {/* <div className="grid grid-cols-1 lg:grid-cols-3 w-full gap-5"> */}
          <div className="flex w-full md:max-w-[230px] flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-[#FFAF1933] dark:bg-darkLightBg">
                  <Sparkles className="text-[#FFAF19] w-5 h-5" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-base ">
                  Reviews Generated
                </p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className="mt-5"
                height={50}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[38px] text-center">
                  {stats?.total_ai_generated_reviews}
                </p>
                {/* <p className="text-[#888888] text-[14px] text-center mt-5">
                  reviews generated
                </p> */}
              </>
            )}
          </div>
          <div className="flex w-full md:max-w-[230px] flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-[#00b67a]/20 dark:bg-darkLightBg">
                  <SquareCheckBig className="text-[#00b67a] w-5 h-5" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-base ">
                  Reviews redirected
                </p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className="mt-5"
                height={50}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[38px] text-center">
                  {stats?.total_ai_posted_reviews}
                </p>
                {/* <p className="text-[#888888] text-[14px] text-center mt-5">
                  redirected reviews
                </p> */}
              </>
            )}
          </div>
          <div className="flex w-full md:max-w-[230px] flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-newPrimary/20 dark:bg-darkLightBg">
                  <Mic className="text-newPrimary w-5 h-5" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-base ">Voice Reviews</p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className="mt-5"
                height={50}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[38px] text-center">
                  {stats?.total_voice_review_redirected}
                </p>
                {/* <p className="text-[#888888] text-[14px] text-center mt-5">
                  redirected voice reviews
                </p> */}
              </>
            )}
          </div>
          {/* </div> */}
        </div>
        <div className="flex flex-col lg:flex-row gap-5 w-full mb-5 font-pro">
          <div className="lg:w-[60%] w-full h-full flex-grow">
            <AiPostedGraph />
          </div>
          <div className="flex flex-col w-full lg:w-[39%] min-h-full flex-grow gap-5">
            <div className="flex  w-full h-full flex-col rounded-[12px] p-5 pb-10 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              <div className="flex items-center gap-x-3 mb-[30px]">
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-pro font-medium text-lg ">Reviews Today</p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton
                  className="mt-5"
                  height={50}
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="flex items-center justify-between flex-wrap">
                  <p className="font-pro font-bold text-[38px] ">
                    {stats?.today_reviews}
                  </p>
                  <img src="/master-charts.svg" />
                </div>
              )}
            </div>
            <div className="flex  w-full h-full flex-col rounded-[12px] p-5 pb-10 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              <div className="flex items-center gap-x-3 mb-[30px]">
                {isMainLoading ? (
                  <Skeleton
                    containerClassName="flex-1"
                    inline
                    className="w-full"
                    baseColor={darkMode && "#262626"}
                  />
                ) : (
                  <p className="font-pro font-medium text-lg ">
                    Monthly Credit Summary
                  </p>
                )}
              </div>
              {isMainLoading ? (
                <Skeleton
                  className="mt-5"
                  height={50}
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="flex items-center justify-between flex-wrap">
                  <p className="font-pro font-bold text-[38px] ">
                    {billingStats?.total_usage_used}
                  </p>
                  <img src="/master-charts.svg" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row gap-5 w-full mb-5 font-pro">
          <div className="grid gird-cols-1 lg:grid-cols-2 w-full gap-5 min-h-[45vh]">
            <div className="flex w-full flex-col rounded-[12px] p-5 3xl:p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                  height={200} // Adjust height for the loading state
                />
              ) : (
                <>
                  <div className="flex items-center gap-x-3 mb-5">
                    <p className="font-pro font-medium text-lg ">
                      Top Performing Accounts
                    </p>
                  </div>
                  <div className="space-y-2">
                    {topPerformance?.length > 0 ? (
                      topPerformance.slice(0, 5).map((item) => (
                        <div
                          className="flex justify-between items-center"
                          key={item?.email}
                        >
                          <div className="flex items-center gap-2">
                            <img
                              src={
                                item?.pfp_url ? item?.pfp_url : "/new-logo.svg"
                              }
                              alt=""
                              className="size-[30px] rounded-full"
                            />
                            <div>
                              <p className="font-poppins text-sm">
                                {item?.first_name + " " + item?.last_name}
                              </p>
                              <p className="font-poppins text-[10px] text-[#888]">
                                {item?.email}
                              </p>
                            </div>
                          </div>
                          <div className="rounded-full py-2 px-5 flex justify-center items-center bg-lightpurple">
                            <p className="font-poppins font-medium text-[#615E83]">
                              {item?.review_count}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <p>No data found</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            <div className="flex w-full flex-col rounded-[12px] p-5 3xl:p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                  height={200} // Adjust height for the loading state
                />
              ) : (
                <>
                  <div className="flex items-center gap-x-3 mb-5">
                    <p className="font-pro font-medium text-lg ">
                      Under Performing Accounts
                    </p>
                  </div>
                  <div className="space-y-2">
                    {bottomPerformance?.length > 0 ? (
                      bottomPerformance.slice(0, 5).map((item) => (
                        <div
                          className="flex justify-between items-center"
                          key={item?.email}
                        >
                          <div className="flex items-center gap-2">
                            <img
                              src={
                                item?.pfp_url ? item?.pfp_url : "/new-logo.svg"
                              }
                              alt=""
                              className="size-[30px] rounded-full"
                            />
                            <div>
                              <p className="font-poppins text-sm">
                                {item?.first_name + " " + item?.last_name}
                              </p>
                              <p className="font-poppins text-[10px] text-[#888]">
                                {item?.email}
                              </p>
                            </div>
                          </div>
                          <div className="rounded-full py-2 px-5 flex justify-center items-center bg-lightpurple">
                            <p className="font-poppins font-medium text-[#615E83]">
                              {item?.review_count}
                            </p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div className="flex items-center justify-center w-full h-full">
                        <p>No data found</p>
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Master;

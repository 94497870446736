/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  useGetSubUserMasterPlan,
  usePostSubUserMasterPlan,
} from "../../../../query/useAuthentication";
import { Input } from "../../../../components/ui/input";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import { Navigation, Pagination } from "swiper/modules";
import { Button } from "../../../../components/ui/button";
import { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { useQueryClient } from "@tanstack/react-query";
import ImageDragAndDrop from "../../component/ImageDragAndDrop";

const CreditRequestModal = ({ isOpenModal, setIsOpenModal }) => {
  const [credits, setCredits] = useState(100);
  const [step, setStep] = useState(1);
  const [masterPlan, setMasterPlan] = useState();
  const [screenshot, setScreenshot] = useState(null);
  const [transactionNumber, setTransactionNumber] = useState("");
  const [message, setMessage] = useState("");
  const queryClient = useQueryClient();
  useGetSubUserMasterPlan(
    {
      credit: credits,
    },
    {
      onSuccess: (res) => {
        setMasterPlan(res);
      },
    }
  );

  const { mutate: request, isLoading: requestLoading } =
    usePostSubUserMasterPlan();

  const handleRequestCredit = () => {
    request(
      {
        credit: Number(credits),
        screenshot: screenshot,
        transaction_number: transactionNumber,
        message: message,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          setIsOpenModal(false);
          queryClient.invalidateQueries(["getSubUserBilling"]);
          queryClient.invalidateQueries(["getSubUserCharges"]);
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (requestLoading) {
    return <LogoLoading />;
  }

  return (
    <Dialog
      open={isOpenModal}
      onOpenChange={() => setIsOpenModal(!isOpenModal)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Request Credits</DialogTitle>
        </DialogHeader>
        <div className="px-6 max-h-[70vh] overflow-y-auto scrollbar-thin">
          {step === 1 && (
            <>
              <div className="space-y-5 mb-5">
                <p className="text-[#333333] dark:text-white font-pro text-xl  font-semibold text-center capitalize">
                  {masterPlan?.plan?.slug}
                </p>
                <p className="font-pro text-[32px]  font-extrabold text-center">
                  {masterPlan?.currency &&
                    masterPlan?.["Price of Credits"]?.toLocaleString("en-US", {
                      style: "currency",
                      currency: masterPlan?.currency,
                    })}{" "}
                </p>
                <div className="space-y-3 mb-5">
                  <p className="text-center font-pro text-lg font-medium">
                    Tiers
                  </p>

                  <div className="flex flex-col gap-3">
                    {masterPlan?.plan?.tiers?.map((item) => (
                      <div
                        key={item?.amount_per_credit}
                        className="font-pro flex justify-between items-center p-4 bg-accent rounded-md"
                      >
                        <p className="">
                          {item?.credits_min} - {item?.credits_max} credits
                        </p>

                        <p>
                          {masterPlan?.plan?.currency &&
                            item?.amount_per_credit &&
                            Number(item?.amount_per_credit)?.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: masterPlan?.plan?.currency,
                              }
                            )}{" "}
                          / credit
                        </p>
                      </div>
                    ))}
                    <div className="font-pro flex justify-between items-center p-4 bg-accent rounded-md">
                      <p className="">
                        More than{" "}
                        {
                          masterPlan?.plan?.tiers[
                            masterPlan?.plan?.tiers?.length - 1
                          ]?.credits_max
                        }{" "}
                        credits
                      </p>

                      <p>
                        {masterPlan?.plan?.currency &&
                          Number(
                            masterPlan?.plan?.amount_per_credit
                          )?.toLocaleString("en-US", {
                            style: "currency",
                            currency: masterPlan?.plan?.currency,
                          })}{" "}
                        / credit
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="">
                <p className="font-pro mb-1">No. of credits</p>
                <Input
                  value={credits}
                  type="number"
                  onChange={(e) => {
                    const number = e.target.value;
                    if (number >= 0) {
                      setCredits(e.target.value);
                    }
                  }}
                  className="font-pro"
                />
              </div>
            </>
          )}
          {step === 2 && (
            <>
              <div className="space-y-3 font-pro">
                <div className="space-y-1">
                  <p>No. of credits</p>
                  <Input value={credits} readOnly disabled />
                </div>
                <div className="space-y-1">
                  <p>Amount</p>
                  <Input
                    value={
                      masterPlan?.currency &&
                      masterPlan?.["Price of Credits"]?.toLocaleString(
                        "en-US",
                        {
                          style: "currency",
                          currency: masterPlan?.currency,
                        }
                      )
                    }
                    readOnly
                    disabled
                  />
                </div>
                <div className="space-y-1">
                  <p>Transaction No.</p>
                  <Input
                    value={transactionNumber}
                    onChange={(e) => setTransactionNumber(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <p>Message</p>
                  <Input
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                  />
                </div>
                <div className="space-y-1">
                  <p>Screenshot</p>
                  <ImageDragAndDrop setImage={setScreenshot} />
                </div>
              </div>
            </>
          )}
          <div className="flex justify-end gap-3 mt-5">
            {step === 1 && (
              <>
                <Button
                  variant="secondary"
                  onClick={() => setIsOpenModal(false)}
                >
                  Cancel
                </Button>
                <Button onClick={() => setStep(2)}>Continue</Button>
              </>
            )}
            {step === 2 && (
              <>
                <Button onClick={() => setStep(1)} variant="secondary">
                  Back
                </Button>
                <Button onClick={handleRequestCredit}>Confirm</Button>
              </>
            )}
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CreditRequestModal;

/* eslint-disable no-unused-vars */
import { Link, useSearchParams } from "react-router-dom";
import PasswordInput from "../../components/input/passwordinput";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";
import {
  useGoogleSignIn,
  usePostDecryptedToken,
  useSignIn,
} from "../../query/useAuthentication";
import { Helmet } from "react-helmet";
import { LoadingButton, LogoLoading } from "../../components/loading/loading";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import {
  useGetOrganizationLoginCheckvalid,
  usePostOrganizationLogin,
  usePostOrganizationLoginGoogle,
} from "../../query/useMaster";

const SignIn = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [params] = useSearchParams();
  const idtoken = params.get("idtoken");
  const exchangeToken = params.get("token");
  const [isLoginValid, setIsLoginValid] = useState(true);

  const referrer = document.referrer;

  const { mutate: decryptedToken, isLoading: decryptedTokenLoading } =
    usePostDecryptedToken();

  useGetOrganizationLoginCheckvalid(idtoken, {
    onSuccess: (res) => {
      setIsLoginValid(res.valid);
    },
    enabled: Boolean(idtoken),
  });

  const { mutate: login, isLoading: loginLoading } = useSignIn();
  const { mutate: googleLogin, isLoading: googleLoginLoading } =
    useGoogleSignIn();

  const { mutate: orgLogin, isLoading: orgLoginLoading } =
    usePostOrganizationLogin();
  const { mutate: orgLoginGoogle, isLoading: orgLoginGoogleLoading } =
    usePostOrganizationLoginGoogle();

  const handleDecryptedToken = () => {
    decryptedToken(
      {
        encrypted_token: exchangeToken,
      },
      {
        onSuccess: (data) => {
          if (data.token) {
            sessionStorage.setItem("tempToken", data.token);
            toast.success("Logged in successfully");
            navigate("/dashboard");
          }
        },
        onError: (error) => {
          if (error.response.status === 500) {
            toast.error(
              `Something went wrong, try again later ${error.response.status}`
            );
          } else {
            toast.error(error.response.data.error);
          }
        },
      }
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const data = {
      email,
      password,
    };

    if (idtoken) {
      orgLogin(
        { id: idtoken, data: data },
        {
          onSuccess: (data) => {
            toast.success(data.message);
          },
          onError: (error) => {
            if (error.response.status === 500) {
              toast.error(
                `Something went wrong, try again later ${error.response.status}`
              );
            } else {
              toast.error(error.response.data.detail);
            }
          },
        }
      );
    } else {
      login(data, {
        onSuccess: (data) => {
          if (data.token) {
            localStorage.setItem("token", data.token);
            toast.success("Logged in successfully");
            navigate("/dashboard");
          } else if (data.verificiation_id) {
            toast.success(data.message);
            sessionStorage.setItem("verificationId", data.verificiation_id);
            navigate(`/verify-email?profileId=${data.profile_uuid}`);
          } else {
            toast.success(data.message);
            navigate(`/register-entity?profileId=${data.profile_uuid}`);
          }
        },
        onError: (error) => {
          if (error.response.status === 500) {
            toast.error(
              `Something went wrong, try again later ${error.response.status}`
            );
          } else {
            toast.error(error.response.data.detail);
          }
        },
      });
    }
  };

  const handleGoogleLogin = (res) => {
    if (idtoken) {
      orgLoginGoogle(
        {
          id: idtoken,
          data: {
            id_token: res.credential,
          },
        },
        {
          onSuccess: (res) => {
            toast.success(res.message);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      googleLogin(
        {
          id_token: res.credential,
        },
        {
          onSuccess: (res) => {
            if (res.token) {
              localStorage.setItem("token", res.token);
              toast.success("Logged in successfully");
              navigate("/dashboard");
            } else if (res.profile_uuid) {
              toast.success(res.message);
              navigate(`/register-entity?profileId=${res.profile_uuid}`);
              sessionStorage.setItem(
                "profileExtraFields",
                JSON.stringify(res.extra_fields)
              );
            } else if (res.status === "error") {
              toast.error(res.message);
            }
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    }
  };

  useEffect(() => {
    if (
      exchangeToken &&
      (referrer.includes("https://magicreview-admin-dev.vercel.app") ||
        referrer.includes("https://admin.magicreview.ai"))
    ) {
      handleDecryptedToken();
    }
  }, [exchangeToken, referrer]);

  if (
    loginLoading ||
    googleLoginLoading ||
    orgLoginLoading ||
    orgLoginGoogleLoading ||
    decryptedTokenLoading
  ) {
    return <LogoLoading />;
  }

  return (
    <div
      className={`w-full min-h-screen container flex lg:items-center lg:justify-center  lg:bg-[#F7F7F7]`}
    >
      <Helmet>
        <link rel="canonical" href="https://www.magicreview.ai/sign-in" />
      </Helmet>
      <div className="w-full lg:max-w-lg flex flex-col md:justify-center md:items-center bg-white rounded-[20px] p-5 lg:p-10 font-poppins ">
        {isLoginValid ? (
          <>
            <div className="flex flex-col w-full  mb-5">
              <Link to="/">
                <img
                  src="/new-logo-with-sidetext.svg"
                  alt="logo"
                  className="object-contain w-auto mx-auto"
                />
              </Link>
              <h1 className="text-[22px] leading-8 md:-tracking-[0.5px] font-semibold text-[#4a4a4a] mt-[20px] ">
                Log in to your account
              </h1>
              <p className="text-[#4a4a4a] text-sm mt-1">
                Enter your details below to login
              </p>

              <div className="flex justify-center mt-7">
                <GoogleLogin
                  onSuccess={(res) => handleGoogleLogin(res)}
                  onError={(err) => console.log(err)}
                  size="large"
                  shape="pill"
                  width={300}
                  text="continue_with"
                  useOneTap
                />
                {/* <button onClick={handleGoogleLogin}>Google</button> */}
              </div>
              <div className="flex items-center gap-3 my-5">
                <div className="w-full h-[1px] bg-[#ebebeb]"></div>
                <div className="text-sm text-[#999] flex-shrink-0">or</div>
                <div className="w-full h-[1px] bg-[#ebebeb]"></div>
              </div>
              <form className="flex flex-col gap-5">
                <div className="flex flex-col gap-1">
                  <label
                    htmlFor="email"
                    className=" text-[#757575] font-medium"
                  >
                    Email address
                  </label>
                  <input
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    id="email"
                    placeholder="e.g. mail@domain.com"
                    className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans placeholder:text-[#81818F] h-[45px] w-full text-sm text-black"
                  />
                </div>
                <PasswordInput
                  label="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  loginPage={true}
                />
                {!idtoken && (
                  <div className="flex items-center w-full justify-end">
                    <Link
                      to="/forgot-password"
                      type="button"
                      className="text-sm text-[#2a49b8] hover:underline text-right font-medium"
                    >
                      Forgotten password?
                    </Link>
                  </div>
                )}
                <button
                  disabled={!email.trim() || !password.trim()}
                  onClick={handleSubmit}
                  type="submit"
                  className="bg-newPrimary border border-newPrimary  text-white py-2.5 rounded-full mt-3 transition-all duration-300  font-montserrat text-base font-medium active:scale-95"
                >
                  Log In
                </button>
              </form>
            </div>

            {!idtoken && (
              <p className="text-gray-600 text-center text-sm">
                Don&apos;t have an account?{" "}
                <Link to="/sign-up" className="text-[#2a49b8] font-semibold">
                  Sign up
                </Link>
              </p>
            )}
          </>
        ) : (
          <div className="flex flex-col w-full  mb-5">
            <Link to="/">
              <img
                src="/new-logo-with-sidetext.svg"
                alt="logo"
                className="object-contain w-auto mx-auto"
              />
            </Link>
            <h1 className="text-[22px] leading-8 md:-tracking-[0.5px] font-semibold text-[#4a4a4a] mt-[20px] ">
              The link is expired.
            </h1>
            <p className="text-[#4a4a4a] text-sm mt-1">
              Please ask your organization to resent the inviation link
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default SignIn;

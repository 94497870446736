/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { useEffect, useState } from "react";
import { toast } from "sonner";
import { useShortLink } from "../../../../query/useContact";
import { LogoLoading } from "../../../../components/loading/loading";
import { Button } from "../../../../components/ui/button";

const LinkModal = ({ openModal, setOpenModal, link }) => {
  const [shortLink, setShortLink] = useState("");
  const [originalLink, setOriginalLink] = useState("");

  const { mutate: changeShortLink, isLoading: changeShortLinkLoading } =
    useShortLink();
  const handleCopy = () => {
    if (link) {
      navigator.clipboard.writeText(shortLink);
      setOpenModal(false);
      toast.success("Copied to clipboard");
    } else {
      toast.error("No magic link!");
    }
  };
  const handleChangeShortLink = () => {
    if (shortLink === originalLink) {
      changeShortLink(
        {
          original_url: originalLink,
        },
        {
          onSuccess: (res) => {
            toast.success("Link is shortened successfully");
            setShortLink(res.short_url);
          },
          onError: (err) => {
            if (err.response.data.message) {
              toast.error(err.response.data.message);
            } else if (err.response.data.original_url) {
              toast.error(err.response.data.original_url);
            } else {
              toast.error(
                `Something went wrong, try again later ${err.response.status}`
              );
            }
          },
        }
      );
    } else {
      setShortLink(originalLink);
    }
  };
  useEffect(() => {
    setShortLink(link);
    setOriginalLink(link);
  }, [link]);

  // if (changeShortLinkLoading) {
  //   return <LogoLoading />;
  // }

  return (
    <Modal
      dismissible
      show={openModal}
      onClose={() => {
        setOpenModal();
        // setData();
      }}
      className="z-[1000]"
      theme={{
        content: {
          base: "relative p-4 h-auto",
          inner: "bg-white dark:bg-darkBg rounded-lg",
        },
        header: {
          base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
          close: {
            base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
          },
        },
      }}
      popup
    >
      <Modal.Header>
        <p className="px-5 pt-1 font-pro text-lg">MagicLink</p>
      </Modal.Header>
      <Modal.Body className="h-auto lg:w-[500px]">
        <input
          type="text"
          value={shortLink}
          className="w-full border-x-0 border-t-0 appearance-none font-pro text-sm text-black"
          readOnly
        />
        <div className="flex items-center gap-x-1.5 mt-3">
          <input
            type="checkbox"
            onChange={handleChangeShortLink}
            id="shortenLink"
            checked={shortLink !== originalLink}
            className="checked:bg-newPrimary"
          />
          <label
            htmlFor="shortenLink"
            className="font-pro text-sm text-[#4a4a4a]"
          >
            Shorten Link
          </label>
          {changeShortLinkLoading && (
            <div className="flex items-center justify-center">
              <div className="w-4 h-4 border-t-2 border-b-2 border-newPrimary  rounded-full animate-spin"></div>
            </div>
          )}
        </div>
        <div className="flex justify-end items-center gap-x-3 mt-7">
          <Button
            variant="secondary"
            onClick={() => {
              setOpenModal(null);
            }}
          >
            Cancel
          </Button>
          <Button onClick={handleCopy}>Copy</Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default LinkModal;

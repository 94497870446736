/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";

import {
  Copy,
  DollarSign,
  HandCoins,
  Landmark,
  LogIn,
  Mic,
  Pencil,
  Sparkles,
  SquareCheckBig,
  Star,
} from "lucide-react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { Button } from "../../../components/ui/button";
import { toast } from "sonner";
import { useDarkMode } from "../../../context/DarkModeProvider";
import Skeleton from "react-loading-skeleton";
import {
  usePostLoginAsSubuser,
  usePostSubUsersStats,
} from "../../../query/useMaster";
import ConfirmLoginUserModal from "./ConfirmLoginUserModal";
import { useNavigate } from "react-router";
import IconButton from "../../dashboard/component/IconButton";

const Overview = ({
  profile,
  entity,
  first_name,
  last_name,
  mail,
  id,
  totalAmount,
  currency,
  creditLimit,
}) => {
  const navigate = useNavigate();
  const baseUrl = window.location.origin;
  const [stats, setStats] = useState();
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const { darkMode } = useDarkMode();
  const { mutate: subUsersStats, isLoading: subUsersStatsLoading } =
    usePostSubUsersStats();

  const { mutate: loginAsSubuser, isLoading: loginAsSubuserLoading } =
    usePostLoginAsSubuser();

  const handleLoginAsSubuser = () => {
    const masterToken = localStorage.getItem("token");
    const masterUser = localStorage.getItem("user");
    if (masterToken) {
      sessionStorage.setItem("masterToken", masterToken);
      sessionStorage.setItem("masterUser", masterUser);
    }
    loginAsSubuser(
      {
        pk: id,
      },
      {
        onSuccess: (data) => {
          if (data.token) {
            localStorage.setItem("token", data.token);
            navigate("/dashboard", { replace: true });
          } else {
            toast.success(data.message);
            navigate(`/register-entity?profileId=${data.profile_uuid}`, {
              replace: true,
            });
          }
        },
        onError: (error) => {
          if (error.response.status === 500) {
            toast.error(
              `Something went wrong, try again later ${error.response.status}`
            );
          } else {
            toast.error(error.response.data.detail);
          }
        },
      }
    );
  };

  const handleCopyMagicLink = () => {
    try {
      navigator.clipboard.writeText(baseUrl + "/" + entity?.magic_link);
      toast.success("Copied to clipboard");
    } catch {
      toast.error("Failed to copy");
    }
  };
  let isMainLoading = subUsersStatsLoading;

  useEffect(() => {
    subUsersStats(
      { pk: id },
      {
        onSuccess: (res) => {
          setStats(res);
        },
      }
    );
  }, [id]);

  return (
    <div className="font-pro">
      <div className="flex justify-between items-center">
        <div className="flex items-center gap-x-5 py-5">
          <img
            src={profile?.profile_picture || "/new-logo.svg"}
            alt=""
            className="w-[45px] h-[45px] rounded-full flex-shrink-0"
          />
          <p className="font-semibold text-[20px]">
            {first_name + " " + last_name}
          </p>
        </div>
        <div className="flex items-center gap-3">
          <Button className="gap-x-2" onClick={handleLoginAsSubuser}>
            <LogIn className="size-4 " />
            <p className="font-pro font-medium text-sm ">Login as user</p>
          </Button>
          <Button className="gap-x-2" onClick={handleCopyMagicLink}>
            <Copy className="size-4" />
            <p className="font-pro font-medium text-sm ">MagicLink</p>
          </Button>
        </div>
      </div>
      <hr className="mb-6" />
      <div>
        <p className="text-lg font-medium">Overview</p>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-5 mt-3">
          <div className="flex flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-[#FFAF1933] dark:bg-darkLightBg">
                  <Sparkles className="text-[#FFAF19] size-4" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-sm ">
                  Reviews Generated
                </p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className=""
                height={30}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[28px] text-center">
                  {stats?.total_ai_generated_reviews}
                </p>
              </>
            )}
          </div>
          <div className="flex flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-[#00b67a]/20 dark:bg-darkLightBg">
                  <SquareCheckBig className="text-[#00b67a] size-4" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-sm ">
                  Reviews Redirected
                </p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className=""
                height={30}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[28px] text-center">
                  {stats?.total_ai_posted_reviews}
                </p>
              </>
            )}
          </div>
          <div className="flex  flex-col rounded-[12px] p-5 bg-white dark:bg-darkBg shadow-sm border border-[#ebebeb] dark:border-none">
            <div className="flex items-center gap-x-3 mb-[15px]">
              {isMainLoading ? (
                <Skeleton
                  circle
                  className="w-10 h-10 3xl:w-14 3xl:h-14"
                  containerClassName="flex-1"
                  inline
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <div className="p-2 rounded-full bg-newPrimary/20 dark:bg-darkLightBg">
                  <Mic className="text-newPrimary size-4" />
                </div>
              )}

              {isMainLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  inline
                  className="w-full"
                  baseColor={darkMode && "#262626"}
                />
              ) : (
                <p className="font-pro font-medium text-sm ">Voice Reviews</p>
              )}
            </div>
            {isMainLoading ? (
              <Skeleton
                className=""
                height={30}
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <p className="font-pro font-bold text-[28px] text-center">
                  {stats?.total_voice_review_redirected}
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="flex items-center gap-3">
          <p className="text-lg font-medium">Usage</p>
        </div>

        <div className="rounded-xl overflow-hidden mt-3">
          <Table>
            <TableHeader>
              <TableRow className="text-[#888] font-medium font-pro">
                <TableHead>Credit Limit</TableHead>
                <TableHead>Credits Used</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className={`font-pro`}>
                <TableCell>{creditLimit}</TableCell>
                <TableCell>{profile?.usage}</TableCell>
                {/* <TableCell>
                  {totalAmount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                  })}
                </TableCell> */}
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div>
      {/* <ConfirmLoginUserModal
        isOpenModal={isOpenConfirmModal}
        setIsOpenModal={setIsOpenConfirmModal}
        handleAction={() => handleLoginAsSubuser()}
      /> */}
      {/* <div className="mt-10">
        <p className="text-lg font-medium">Invoice</p>
        <div className="rounded-xl overflow-hidden mt-5">
          <Table>
            <TableHeader>
              <TableRow className="text-[#888] font-medium font-pro">
                <TableHead>Billing Date</TableHead>
                <TableHead>Total Reviews Redirected</TableHead>
                <TableHead>Amount</TableHead>
                <TableHead>Status</TableHead>
                <TableHead>Download</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <TableRow className={`font-pro`}>
                <TableCell>May 5, 2024</TableCell>
                <TableCell>1000</TableCell>
                <TableCell>1000</TableCell>
                <TableCell>Pending</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </div>
      </div> */}
    </div>
  );
};

export default Overview;

/* eslint-disable react/prop-types */
import { Switch } from "../../../components/ui/switch";
import IconButton from "../../dashboard/component/IconButton";
import { GripVertical, SquarePen, Trash2 } from "lucide-react";
import { Reorder, useDragControls } from "framer-motion";
import { useState } from "react";
import { DeleteQuestionModal } from "../../dashboard/component/modal";
import EditQuestionModal from "../../dashboard/component/EditQuestionModal";

const QuestionCardForQuestionSet = ({
  item,
  extra,
  handleToggleQuestion,
  questionIndex,
}) => {
  // const dragControls = useDragControls();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  return (
    <div
      key={item?.question?.id}
      id={item?.question?.id}
      className="rounded-[15px] border border-[#EBEBEB] dark:border-none bg-white dark:bg-darkBg p-5 flex items-center gap-x-5"
    >
      <div className=" w-full flex items-center gap-x-5 select-none">
        <div className="w-full">
          <div className="flex justify-end items-center lg:hidden mb-5">
            <div className="flex items-center gap-3">
              <Switch
                checked={item?.is_enabled}
                onCheckedChange={() => {
                  handleToggleQuestion(item?.id);
                }}
              ></Switch>
              <div className="flex items-center gap-x-1">
                <IconButton>
                  <SquarePen
                    className="text-[#888] dark:text-[#c3c3c3] w-5 h-5 cursor-pointer"
                    onClick={() => setOpenEditModal(true)}
                  />
                </IconButton>
                <IconButton>
                  <Trash2
                    className="text-red-500 w-5 h-5 cursor-pointer"
                    onClick={() => setOpenDeleteModal(true)}
                  />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-start pb-4 w-full">
            <p className="text-[#333] dark:text-white font-pro font-medium text-base break-words">
              {"Q." + (questionIndex + 1)} {item?.question?.question}
            </p>

            <div className="lg:flex items-center gap-3 hidden">
              <Switch
                checked={item?.is_enabled}
                onCheckedChange={() => {
                  handleToggleQuestion(item?.id);
                }}
              ></Switch>

              <div className="flex items-center gap-x-1">
                <IconButton>
                  <SquarePen
                    className="text-[#888] dark:text-[#c3c3c3] w-5 h-5 cursor-pointer"
                    onClick={() => setOpenEditModal(true)}
                  />
                </IconButton>
                <IconButton>
                  <Trash2
                    className="text-red-500 w-5 h-5 cursor-pointer"
                    onClick={() => {
                      setOpenDeleteModal(true);
                    }}
                  />
                </IconButton>
              </div>
            </div>
          </div>
          <div className=" py-2 grid grid-cols-1 md:grid-cols-2 lg:flex gap-[19px] lg:flex-wrap">
            {item?.question?.response_options?.map((answer, i) => (
              <button
                key={i}
                className={`flex items-center px-4 py-2 border rounded-lg w-full lg:w-fit text-[#4A4A4A] dark:text-white dark:border-[#454545]  font-pro text-sm font-medium border-[#E0E0E0]`}
              >
                <span
                  className={`w-5 h-5 border rounded-full mr-2 flex-shrink-0 dark:border-[#454545] `}
                ></span>
                {answer}
              </button>
            ))}
          </div>
          <div className="flex md:flex-row flex-col items-start gap-5 mt-5">
            {item?.question?.category && (
              <div className="flex flex-wrap gap-3 md:w-1/2">
                <div
                  className={`py-1.5 rounded-lg w-fit h-fit bg-[#e1e8fe] font-pro text-[13px] font-normal text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                >
                  Category
                </div>
                <div
                  className={`py-1.5 rounded-lg w-fit h-fit bg-[#ffaf19]/25  font-pro text-[13px] font-normal text-center px-5 dark:bg-[#262626] dark:text-[#ffc200]`}
                >
                  {item?.question?.category}
                </div>
              </div>
            )}
            {item?.question?.keyword && (
              <div className="flex flex-wrap gap-3 md:w-1/2">
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#e1e8fe] font-pro text-[13px] font-normal text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                >
                  Keyword
                </div>
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#FD5E3B]/25  font-pro text-[13px] font-normal text-center px-5 dark:bg-[#262626] dark:text-[#ff6464]`}
                >
                  {item?.question?.keyword}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openEditModal && (
        <EditQuestionModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          key={item?.question?.id}
          item={item?.question}
        />
      )}
      {openDeleteModal && (
        <DeleteQuestionModal
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          questionIndex={item?.question?.id}
        />
      )}
    </div>
  );
};

export default QuestionCardForQuestionSet;

import React from "react";
import style from "./GetStarted.module.scss";
import Mainnav from "../../components/mainNavbar/MainNav";
import { LazyLoadImage } from "react-lazy-load-image-component";
const PaymentSuccess = () => {
  const price = localStorage.getItem("price") || 199;
  return (
    <div className={`w-full flex flex-col h-screen ${style.getstarted}`}>
      <div className="w-full min-h-screen max-w-screen-2xl mx-auto">
        <Mainnav />
        <div
          className={`first-line:mt-20 self-center max-w-[588px] w-[90%] pb-9 mx-auto flex flex-col justify-center items-center bg-[#FFF] rounded-3xl ${style.maincont} mt-3`}
          style={{
            filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
          }}
        >
          <LazyLoadImage
            src="/spinner.svg"
            alt="loading icon"
            className={` max-w-[190px] w-full`}
          />
          <h1
            className="
      text-left text-2xl md:text-6xl text-[#21243D] font-bold opensans
      "
          >
            ${price}
          </h1>

          <p className=" text-[#373737] text-base md:text-3xl mt-3 text-center opensans md:w-[50%]">
            Your payment is Processing !
          </p>
          <button
            className="mt-3 text-white bg-[#4F46BA] max-w-[169px] w-full py-[14px] rounded-md"
            onClick={() => {
              window.location.href = "/";
            }}
          >
            {/* Generate your first review */}
            Go to Home
          </button>
        </div>
        <p className="text-center mt-3 monteserrat text-[#1C1C1C] font-bold w-[90%] mx-auto">
          If you have any questions check our{" "}
          <span className="text-[#4F46BA]"> Knowledge Centre</span>
        </p>
      </div>
    </div>
  );
};

export default PaymentSuccess;

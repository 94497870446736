import Ads from "../../ads/ads";
import Layout from "../component/Layout";

const BuyCard = () => {
  return (
    <Layout title={"Buy card"}>
      <Ads />
    </Layout>
  );
};

export default BuyCard;

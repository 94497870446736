/* eslint-disable react/prop-types */
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";

const ContactDialog = ({ isOpenDialog, setIsOpenDialog, dialogMessage }) => {
  return (
    <Dialog
      open={isOpenDialog}
      onOpenChange={() => setIsOpenDialog(!isOpenDialog)}
    >
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Contact Us</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <p>{dialogMessage}</p>
          <div className="flex justify-end items-end gap-x-3 mt-3">
            <Button onClick={() => setIsOpenDialog(false)}>Okay</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ContactDialog;

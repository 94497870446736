import React from "react";
import WordBreak from "../../../../components/wordbreak";

export const ReviewsIllustration = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[60vh] dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/reviews.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[18px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
        Generate Review
      </h1>
      <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
        No Reviews Posted Yet!
      </p>
    </div>
  );
};

export const DashboardReviewsIllustrations = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full min-h-[300px] dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/dashboardreviews.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[10px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
        Generate Review
      </h1>
      <p className="mt-1 text-[#888888] dark:text-white text-center font-pro">
        No Reviews Posted Yet!
      </p>
    </div>
  );
};

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import style from "./pricing.module.scss";
import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { useGetPlans } from "../../query/useSubscription";
import WordBreak from "../../components/wordbreak";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../../context/authContext";
import { motion } from "framer-motion";
import Plans from "./Plans";

const Pricing = () => {
  const navigate = useNavigate();

  const { token } = useAuth();
  const handleNavigate = () => {
    window.scrollTo(0, 0);
    token
      ? navigate("/dashboard/payment?savedCard=false")
      : navigate("/sign-up");
  };
  const { data: plans } = useGetPlans();
  const UpdatedPlans = Array.isArray(plans?.data)
    ? plans.data.sort((a, b) => a.price - b.price)
    : [];
  const [selectedPlan, setSelectedPlan] = useState(0);

  // Currency symbol will be rendered in "en-US" locale for every currency.
  const planCurrency = UpdatedPlans[selectedPlan]?.currency;

  const cardPrice = () => {
    switch (planCurrency) {
      case "AED":
        return <span>30 AED</span>;
      case "USD":
        return <span>10 &#36;</span>;
      case "GBP":
        return <span>7 &#163;</span>;
      case "INR":
        return <span>500 &#8377;</span>;
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Helmet>
        <title>Pricing - Magicreview</title>
        <link rel="canonical" href="https://www.magicreview.ai/pricing" />
      </Helmet>

      <Mainnav />
      <div className="font-poppins container text-black">
        <motion.h1
          className="text-2xl lg:text-[50px]  text-[#333333] text-center font-semibold font-poppins lg:leading-tight lg:-tracking-[1.296px] mt-[20px] lg:mt-[50px] mb-2"
          initial={{ opacity: 0.5, y: 50 }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          viewport={{ once: true }}
          transition={{ duration: 0.2, ease: "easeIn" }}
        >
          Pricing
        </motion.h1>

        <motion.h3
          className="text-[#2D2D2D] font-poppins text-[17px] font-normal text-center mb-2"
          initial={{
            opacity: 0,
            y: 30,
          }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.2, delay: 0.2 }}
        >
          Pick the plan that suits your needs the most.
        </motion.h3>
        {/* <h1 className="text-center font-semibold text-2xl lg:text-[55px] lg:leading-[64px] lg:-tracking-[6%]">
          Pricing
        </h1>
        <p className="text-center lg:text-xl lg:leading-[58px] lg:-tracking-[1%]">
          Pick the plan that suits your needs the most.
        </p> */}
        <div className="my-20 w-full bg-[#F7F9FF] rounded-[19px] flex flex-col lg:flex-row lg:justify-between lg:items-center px-10 lg:px-16 lg:py-10 py-8 gap-5">
          <div className="w-fit space-y-1 lg:w-[48%]">
            <p className="w-fit font-medium text-lg text-newPrimary">Try Now</p>
            <p className="w-fit font-semibold text-[28px] text-[#333] leading-[42px]">
              Learn How MagicReview Can <WordBreak /> Grow Your Business{" "}
            </p>
            <p className="w-fit text-lg text-[#888]">
              AI generated reviews with a 14-day free trial
            </p>
          </div>
          <div className="lg:w-[48%] lg:flex lg:justify-center gap-5 space-y-5">
            <button
              className="flex items-center justify-center font-semibold gap-2 px-6 py-3 hover:text-white hover:bg-newPrimary rounded-[12px] border border-newPrimary text-newPrimary transition-all duration-300 bg-white"
              onClick={() => {
                navigate("/contact-us");
                window.scrollTo(0, 0);
              }}
            >
              Schedule a call
            </button>
            <button
              className="flex items-center justify-center font-semibold gap-2 px-6 py-3 text-white bg-newPrimary rounded-[12px] border border-newPrimary hover:text-newPrimary transition-all duration-300 hover:bg-white"
              onClick={handleNavigate}
            >
              Start your free trial
            </button>
          </div>
        </div>
        <Plans />
      </div>
      <Footer />
    </div>
  );
};

export default Pricing;

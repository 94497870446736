import React from "react";
import WordBreak from "../../../../components/wordbreak";
export const ReferralIllustrations = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
      <img
        src="/illustrations/referrals.svg"
        alt="Questions"
        className="w-[100px] h-[100px] mx-auto mt-10"
      />
      <h1 className="mt-[20px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
      Referral to Friend
      </h1>
      <p className="mt-[10px] text-[#888888] dark:text-white text-center font-pro">
      Your Referrals will appear here!
      </p>
    </div>
  );
};

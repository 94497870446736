import {
  ArrowsPointingOutIcon,
  ChevronDownIcon,
} from "@heroicons/react/20/solid";
import { useAuth } from "../../context/authContext";
import { useUser } from "../../query/useAuthentication";
import { useState } from "react";

const Nav = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { logout } = useAuth();

  const { data: user } = useUser();

  return (
    <div className="flex justify-end items-center sticky top-0 z-[500] bg-white py-10">
      <div className="relative">
        <div
          className="flex items-center gap-x-3 cursor-pointer"
          onClick={() => setIsDropdownOpen((prev) => !prev)}
        >
          <img
            src={user?.profile?.profile_picture || "/new-logo.svg"}
            alt=""
            className="w-8 h-8 rounded-full"
            referrerPolicy="no-referrer"
          />
          <p className="font-montserrat leading-[28px] text-[#10182899] text-[15px] font-semibold">
            {user?.email}
          </p>
          <ChevronDownIcon
            className={`h-5 w-5 transform transition-transform ${
              isDropdownOpen ? "rotate-180" : ""
            }`}
            aria-hidden="true"
          />
        </div>
        {isDropdownOpen && (
          <div className="w-full p-5 rounded-md shadow-md absolute right-0 top-10 flex justify-center items-center">
            <button className="hover:text-secondary" onClick={logout}>
              Logout
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Nav;

import { useState } from "react";
import Layout from "../component/Layout";
import { toast } from "sonner";
import {
  useGetReferrals,
  usePostUpdateReferralCode,
  useUser,
} from "../../../query/useAuthentication";
import { dayFormatter } from "../../../utils/formatter";
import Skeleton from "react-loading-skeleton";
import { Check, Copy, Pencil, X } from "lucide-react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../../components/ui/table";
import { useDarkMode } from "../../../context/DarkModeProvider";
import { LogoLoading } from "../../../components/loading/loading";
import { ReferralIllustrations } from "../../onboarding/components/illustrations/ReferralIllustrations";
const Referral = () => {
  const baseUrl = import.meta.env.VITE_API_URL;
  const [originalReferralCode, setOriginalReferralCode] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [index, setIndex] = useState(0);
  const { darkMode } = useDarkMode();

  const handleCopy = () => {
    navigator.clipboard.writeText(referralCode);
    toast.success("Copied to clipboard");
  };

  const handlePrev = () => {
    if (index > 0) {
      setIndex((prev) => prev - 10);
    }
  };

  const handleNext = () => {
    setIndex((prev) => prev + 10);
  };

  const { data, isLoading } = useGetReferrals({
    index: index,
  });

  const { refetch } = useUser({
    onSuccess: (res) => {
      setReferralCode(res?.profile?.referral_code);
      setOriginalReferralCode(res?.profile?.referral_code);
    },
  });

  const { mutate: updateReferralCode, isLoading: updateLoading } =
    usePostUpdateReferralCode();

  const handleUpdateReferralCode = () => {
    updateReferralCode(
      {
        new_code: referralCode,
      },
      {
        onSuccess: (res) => {
          setIsEditable(false);
          toast.success("Referral code updated successfully");
          refetch();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (updateLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Referrals"}>
      <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]">
        <div className="flex items-center gap-x-5">
          <p className="text-base font-semibold">Referral code:</p>

          <div className="relative lg:w-[160px] font-montserrat text-sm  text-[#2a49b8]">
            <input
              type="text"
              // placeholder="MagicReview Link"
              className="w-full rounded-[8px] text-sm font-medium border border-[#f6f6ff] bg-white dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary  placeholder:text-sm pl-3 lg:pr-5 py-2"
              disabled={!isEditable}
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
            />
            {!isEditable && (
              <Copy
                className="absolute top-1/2 -translate-y-1/2 right-3 cursor-pointer w-4 h-4 dark:text-secondary"
                onClick={handleCopy}
              />
            )}
          </div>
          {isEditable ? (
            <div className="flex items-center gap-x-2">
              <div
                className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary dark:border text-[#2a49b8]"
                onClick={handleUpdateReferralCode}
                role="button"
              >
                {/* {updateLoading ? (
                  <div className="flex items-center justify-center">
                    <div className="w-4 h-4 border-t-2 border-b-2 border-[#2a49b8] rounded-full animate-spin"></div>
                  </div>
                ) : (
                  <Check className="w-4 h-4 " />
                )} */}
                <Check className="w-4 h-4 " />
              </div>
              <div
                className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545]  dark:border"
                onClick={() => {
                  setIsEditable(false);
                  setReferralCode(originalReferralCode);
                }}
              >
                <X className="w-4 h-4 text-red-500" />
              </div>
            </div>
          ) : (
            <div
              className="p-2 bg-white rounded-[8px] cursor-pointer dark:bg-darkLightBg dark:border-[#454545] dark:text-secondary text-[#2a49b8] dark:border"
              onClick={() => {
                setIsEditable(true);
              }}
            >
              <Pencil className="w-4 h-4 " />
            </div>
          )}
        </div>

        <div className="overflow-x-auto mt-7">
          {data?.referrals?.length <= 0 ? (
            <ReferralIllustrations />
          ) : (
            <>
              <div className="rounded-xl overflow-hidden">
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead className="text-[#808080] dark:text-white text-[15px]">
                        Account
                      </TableHead>
                      <TableHead className="text-[#808080] dark:text-white text-[15px]">
                        Date
                      </TableHead>
                      <TableHead className="text-[#808080] dark:text-white text-[15px]">
                        Plan
                      </TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody className="divide-y">
                    {isLoading ? (
                      <TableRow>
                        {new Array(3).fill("").map((_, i) => (
                          <TableCell key={i}>
                            <Skeleton
                              height={30}
                              baseColor={darkMode && "#262626"}
                            />
                          </TableCell>
                        ))}
                      </TableRow>
                    ) : data?.referrals?.length > 0 ? (
                      data?.referrals?.map((referral, i) => (
                        <TableRow key={i} className="text-[15px]">
                          <TableCell className="">
                            <div className="flex justify-start items-center gap-x-2">
                              <img
                                src={
                                  referral?.profile_url
                                    ? referral?.profile_url
                                    : "/new-logo.svg"
                                }
                                alt=""
                                className="w-10 h-10 rounded-full"
                                referrerPolicy="no-referrer"
                              />
                              <p className="break-words">
                                {referral?.first_name +
                                  " " +
                                  referral?.last_name}
                              </p>
                            </div>
                          </TableCell>
                          <TableCell>
                            {referral.date && dayFormatter(referral.date)}
                          </TableCell>
                          <TableCell>{referral.current_plan}</TableCell>
                        </TableRow>
                      ))
                    ) : null}
                  </TableBody>
                </Table>
              </div>
              {/* <Card padding={0}>
                <IndexTable
                  loading={false}
                  itemCount={data?.referrals?.length}
                  headings={[
                    { title: <span className="text-lg">Account</span> },
                    { title: <span className="text-lg">Date</span> },
                    { title: <span className="text-lg">Plan</span> },
                  ]}
                  selectable={false}
                >
                  {isLoading ? (
                    <IndexTable.Row>
                      {new Array(3).fill("").map((_, i) => (
                        <IndexTable.Cell key={i}>
                          <Skeleton
                            height={30}
                            baseColor={darkMode && "#262626"}
                          />
                        </IndexTable.Cell>
                      ))}
                    </IndexTable.Row>
                  ) : data?.referrals?.length > 0 ? (
                    data?.referrals?.map((referral, i) => (
                      <IndexTable.Row key={i}>
                        <IndexTable.Cell className="">
                          <div className="flex justify-start items-center gap-x-2">
                            <img
                              src={
                                referral.profile_url
                                  ? baseUrl + referral.profile_url
                                  : "/new-logo.svg"
                              }
                              alt=""
                              className="w-10 h-10 rounded-full"
                              referrerPolicy="no-referrer"
                            />
                            <p className="break-words">{referral.first_name}</p>
                          </div>
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          {referral.date && dayFormatter(referral.date)}
                        </IndexTable.Cell>
                        <IndexTable.Cell>
                          {referral.current_plan}
                        </IndexTable.Cell>
                      </IndexTable.Row>
                    ))
                  ) : null}
                </IndexTable>
              </Card> */}
            </>
          )}
        </div>
        <div className="w-full lg:w-[630px] flex items-center justify-between mt-7">
          {index > 0 ? (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handlePrev}
            >
              Prev
            </button>
          ) : (
            <div></div>
          )}
          {data?.total > 0 && index + 10 < data?.total && (
            <button
              className="bg-secondary hover:bg-white border border-secondary rounded-[6px] py-2.5 hover:text-secondary text-white px-5"
              // disabled
              onClick={handleNext}
            >
              Next
            </button>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Referral;

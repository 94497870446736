import {
  getPlansAPi,
  postSubscribeApi,
  postVerifyPayment,
} from "../api/subscribeApi";
import { useMutation, useQuery } from "@tanstack/react-query";

export const useGetPlans = (config) => {
  return useQuery({
    queryKey: ["getPlans"],
    queryFn: () => getPlansAPi(),
    ...config,
  });
};

export const usePostSubscribe = (config) => {
  return useMutation({
    mutationKey: ["postSubscribe"],
    mutationFn: (data) => postSubscribeApi(data),
    ...config,
  });
};

export const usePostVerifyPayment = (config) => {
  return useMutation({
    mutationKey: ["postVerifyPayment"],
    mutationFn: (data) => postVerifyPayment(data),
    ...config,
  });
};

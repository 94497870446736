import { Asterisk, ChevronDown, CircleAlert } from "lucide-react";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import {
  useGetIndustryTypes,
  useRegiserEntity,
} from "../../query/useAuthentication";
import { LogoLoading } from "../../components/loading/loading";
import { toast } from "sonner";
import { isValidPhoneNumber } from "react-phone-number-input";
import { PhoneInput } from "../../components/ui/phone-input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";
import { useDarkMode } from "../../context/DarkModeProvider";

const RegisterEntity = () => {
  const { setDarkMode } = useDarkMode();
  const industryRef = useRef(null);
  const [params] = useSearchParams();
  const profileId = params.get("profileId");
  const navigate = useNavigate();
  const [industry, setIndustry] = useState("hotel");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [website, setWebsite] = useState("");
  const [phone, setPhone] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [referralCode, setReferralCode] = useState("");
  const [openSelectIndustry, setOpenSelectIndustry] = useState(false);

  const profileExtraFields =
    sessionStorage.getItem("profileExtraFields") &&
    JSON.parse(sessionStorage.getItem("profileExtraFields"));

  const { data: industryTypes } = useGetIndustryTypes();
  const { mutate: registerEntity, isLoading: registerEntityLoading } =
    useRegiserEntity();

  const handleSubmit = () => {
    registerEntity(
      {
        id: profileId,
        first_name: firstName,
        last_name: lastName,
        entity: {
          name: companyName,
          industry: industry,
          company_link: website || null,
        },
        phone_number: phone,
        referral_code: referralCode || null,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          localStorage.setItem("token", res.token);
          sessionStorage.setItem("username", firstName + " " + lastName);
          navigate(`/dashboard/get-started?new=true`);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else if (err.response.data.phone_number) {
            toast.error(err.response.data.phone_number[0]);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
    setPhoneError("");

    // Validate the phone number
    // if (!isValidPhoneNumber(value)) {
    //   setPhoneError("Invalid phone number");
    // } else {
    //   setPhoneError("");
    // }
  };

  useEffect(() => {
    if (profileExtraFields) {
      setFirstName(profileExtraFields?.first_name);
      setLastName(profileExtraFields?.last_name);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkMode", false);
    setDarkMode(false);
  }, []);

  if (registerEntityLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full min-h-screen container flex lg:items-center lg:justify-center  lg:bg-[#F7F7F7]">
      <div className="w-full lg:max-w-7xl flex flex-col md:justify-center md:items-center bg-white rounded-[20px] p-5 lg:p-10 font-poppins ">
        <div className="flex flex-col w-full  mb-5">
          <div
            className="w-fit mx-auto cursor-pointer"
            onClick={() => {
              navigate("/", {
                state: {
                  prev: "register-entity",
                  profileId: profileId,
                },
              });
            }}
          >
            <img
              src="/new-logo-with-sidetext.svg"
              alt="logo"
              className=" w-auto"
            />
          </div>
          <h1 className="text-[22px] leading-8 md:-tracking-[0.5px] font-semibold text-[#4a4a4a] mt-[20px]">
            Register your entity
          </h1>
          <p className="text-[#4a4a4a] text-sm mt-1">
            Enter your details below to register your entity
          </p>
        </div>
        <form className=" w-full flex flex-col lg:grid lg:grid-cols-2 gap-5 my-5">
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="firstname"
              className="text-[#757575] font-medium flex items-center gap-x-1"
            >
              First Name <Asterisk className="size-3.5 text-red-500" />
            </label>
            <input
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              id="firstname"
              placeholder="e.g. john"
              className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary dark:text-black"
            />
          </div>
          <div className="flex flex-col gap-1 w-full">
            <label
              htmlFor="lastname"
              className="text-[#757575] font-medium flex items-center gap-x-1"
            >
              Last Name <Asterisk className="size-3.5 text-red-500" />
            </label>
            <input
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              id="lastname"
              placeholder="e.g. doe"
              className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary dark:text-black"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="company"
              className="text-[#757575] font-medium flex items-center gap-x-1"
            >
              Company / Business Name{" "}
              <Asterisk className="size-3.5 text-red-500" />
            </label>
            <input
              value={companyName}
              onChange={(e) => {
                setCompanyName(e.target.value);
                localStorage.setItem("companyName", e.target.value);
              }}
              id="company"
              placeholder="e.g. xyz"
              className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary dark:text-black"
            />
          </div>
          <div className="flex flex-col gap-1">
            <label
              htmlFor="industry"
              className="text-[#757575] font-medium flex items-center gap-x-1"
            >
              Industry <Asterisk className="size-3.5 text-red-500" />
            </label>
            <Select
              value={industry}
              onValueChange={(item) => setIndustry(item)}
            >
              <SelectTrigger className="border-[1px] border-[#E7E4E4] dark:border-[#454545] dark:bg-darkBg rounded-[8px] py-3 px-[15px] text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base w-full disabled:text-[#a7a5a5] h-12 focus:ring-2 focus:ring-newPrimary  focus:ring-offset-1">
                <SelectValue placeholder="Select industry type" />
              </SelectTrigger>
              <SelectContent>
                {industryTypes
                  ?.filter((item) => item?.industry_identifier !== "other")
                  ?.map((item) => (
                    <SelectItem
                      key={item?.id}
                      value={item?.industry_identifier}
                    >
                      {item?.display_text}
                    </SelectItem>
                  ))}
                {industryTypes
                  ?.filter((item) => item?.industry_identifier === "other")
                  ?.map((item) => (
                    <SelectItem
                      key={item?.id}
                      value={item?.industry_identifier}
                    >
                      {item?.display_text}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            {/* <div className="w-full relative" ref={industryRef}>
              <div
                className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary flex justify-between items-center"
                onClick={() => setOpenSelectIndustry((prev) => !prev)}
              >
                <p className="text-sm">
                  {
                    industryTypes?.find(
                      (item) => item?.industry_identifier === industry
                    )?.display_text
                  }
                </p>
                <ChevronDown
                  className={`w-5 h-5 ${openSelectIndustry && "rotate-180"} transition-all duration-200`}
                />
              </div>
              {openSelectIndustry && (
                <div className="w-full px-5 py-3 rounded-lg absolute top-12 left-0 bg-[#f6f6ff] border text-[#615e83] font-poppins space-y-3 z-[60] text-sm text-left lg:h-[200px] overflow-y-auto scrollbar-thin">
                  {industryTypes
                    ?.filter((item) => item?.industry_identifier !== "other")
                    ?.map((item) => (
                      <p
                        className="w-full cursor-pointer"
                        onClick={() => {
                          setOpenSelectIndustry(false);
                          setIndustry(item?.industry_identifier);
                        }}
                        key={item?.id}
                      >
                        {item?.display_text}
                      </p>
                    ))}
                  {industryTypes
                    ?.filter((item) => item?.industry_identifier === "other")
                    ?.map((item) => (
                      <p
                        className="w-full cursor-pointer"
                        onClick={() => {
                          setOpenSelectIndustry(false);
                          setIndustry(item?.industry_identifier);
                        }}
                        key={item?.id}
                      >
                        {item?.display_text}
                      </p>
                    ))}
                </div>
              )}
            </div> */}
          </div>
          <div className="flex flex-col gap-1">
            <label className="text-[#757575] font-medium flex items-center gap-x-1">
              Phone Number <Asterisk className="size-3.5 text-red-500" />
            </label>
            <PhoneInput
              className=""
              value={phone}
              onChange={handlePhoneChange}
              defaultCountry="GB"
            />
            {/* {phone?.length > 5 && phoneError && (
              <p className="text-xs text-red-500 mt-2 flex gap-1">
                <CircleAlert className="size-4" /> {phoneError}
              </p>
            )} */}
          </div>

          <div className="flex flex-col gap-1">
            <label htmlFor="website" className="text-[#757575] font-medium">
              Website Link
            </label>
            <input
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              id="website"
              placeholder="e.g. www.company.in"
              className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary dark:text-black"
            />
          </div>

          <div className="flex flex-col gap-1 relative">
            <label
              htmlFor="referral_code"
              className="text-[#757575] font-medium"
            >
              Referral Code
            </label>
            <input
              value={referralCode}
              onChange={(e) => setReferralCode(e.target.value)}
              id="referral_code"
              placeholder="e.g. d5xe3PH2m6"
              className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 placeholder:text-[#81818F] w-full text-sm h-12 focus:ring-offset-1 focus:ring-newPrimary dark:text-black"
            />
          </div>

          <button
            type="submit"
            onClick={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
            disabled={
              !firstName?.trim() ||
              !lastName?.trim() ||
              !companyName?.trim() ||
              !industry?.trim() ||
              !phone?.trim()
            }
            className="bg-newPrimary border border-newPrimary  text-white py-2.5 rounded-full mt-3 transition-all duration-300  font-montserrat text-base font-medium active:scale-95 col-span-2 max-w-md w-full mx-auto"
          >
            Register
          </button>
        </form>
        <p className="text-gray-600 text-center text-sm">
          Already have an account?{" "}
          <Link to="/sign-in" className="text-[#2a49b8] font-semibold">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default RegisterEntity;

/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  useDeleteSubUserTheme,
  useGetSubUserTheme,
  usePutUpdateSubUserTheme,
} from "../../../query/useMaster";
import { toast } from "sonner";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import { Button } from "../../../components/ui/button";

const Theme = ({ id }) => {
  const [bgColor, setBgColor] = useState("");
  const [btnColor, setBtnColor] = useState("");
  const {
    refetch: refetchEntityTheme,
    isLoading: subUserThemeLoading,
    isFetching: subUserThemeFetching,
  } = useGetSubUserTheme(id, {
    onSuccess: (res) => {
      setBgColor(res?.data?.bg_color);
      setBtnColor(res?.data?.button_color);
    },
  });
  const { mutate: updateEntityTheme, isLoading: updateEntityThemeLoading } =
    usePutUpdateSubUserTheme();
  const { mutate: deleteEntityTheme, isLoading: deleteEntityThemeLoading } =
    useDeleteSubUserTheme();

  const handleUpdateEntityTheme = () => {
    updateEntityTheme(
      {
        id: id,
        data: {
          bg_color: bgColor,
          button_color: btnColor,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          refetchEntityTheme();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteEntityTheme = () => {
    deleteEntityTheme(id, {
      onSuccess: (res) => {
        toast.success(res.message);
        refetchEntityTheme();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  if (subUserThemeLoading || subUserThemeFetching) {
    return <Loading height={"50vh"} />;
  }

  if (updateEntityThemeLoading || deleteEntityThemeLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="font-pro">
      <div className="flex justify-between items-center flex-wrap gap-[20px] py-5">
        <p className="text-lg font-medium">Select Theme / Change Review</p>
      </div>
      <hr />
      <div
        className="
             grid grid-cols-1 md:grid-cols-2 gap-y-5 md:gap-x-5 lg:gap-x-10 mt-10
          "
      >
        <div className="w-full">
          <label className=" text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
            Background color
          </label>
          <input
            className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
            type="color"
            // placeholder={placeholder}
            value={bgColor}
            onChange={(e) => setBgColor(e.target.value)}
          />
        </div>
        <div className="w-full">
          <label className=" text-sm font-pro font-medium mb-[10px] flex gap-1 dark:text-[#bfbfbf]">
            Button color
          </label>
          <input
            className="border-[1px] border-[#E7E4E4] rounded-[8px] px-3 h-10 text-base font-pro placeholder:text-[#A7A5A5] placeholder:text-base mb-[20px] w-full disabled:text-[#a7a5a5] cursor-pointer"
            type="color"
            // placeholder={placeholder}
            value={btnColor}
            onChange={(e) => setBtnColor(e.target.value)}
          />
        </div>
      </div>
      <div className="w-full flex justify-end items-center gap-5 flex-wrap mt-5">
        <Button
          onClick={handleDeleteEntityTheme}
          disabled={deleteEntityThemeLoading}
        >
          Reset
        </Button>
        <Button
          onClick={handleUpdateEntityTheme}
          disabled={updateEntityThemeLoading}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

export default Theme;

const IconPlayOne = (props) => {
  return (
    <svg
      width="48"
      height="49"
      viewBox="0 0 48 49"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M40.6563 7.65197C36.0432 3.10516 30.4343 0.831757 23.8298 0.831757C17.3913 0.831757 11.799 3.10516 7.0531 7.65197C2.4731 12.232 0.183105 17.8408 0.183105 24.4785C0.183105 31.1162 2.4731 36.7084 7.0531 41.2552C9.3431 43.5784 11.8903 45.3374 14.6947 46.5322C17.4991 47.7269 20.5442 48.3243 23.8298 48.3243C30.5339 48.3243 36.1427 45.968 40.6563 41.2552C45.2032 36.7084 47.4766 31.1245 47.4766 24.5034C47.4766 17.8823 45.2032 12.2652 40.6563 7.65197ZM20.7433 19.102V31.5476L30.899 25.3248L20.7433 19.102Z"
        fill="#4F75FA"
      />
      <path
        d="M35.5334 24.7165L20.0049 33.682L20.0049 15.7511L35.5334 24.7165Z"
        fill="white"
      />
    </svg>
  );
};

export default IconPlayOne;

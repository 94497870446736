import Apimanager from "./axios";

export const SubscribeApi = async (data) => {
  const result = await Apimanager.post("/userapi/subscribe", {
    data: data,
  });
  return result;
};

export const getPlansAPi = async () => {
  const res = await Apimanager.get("/subscriptions/plans");
  return res;
};

export const postSubscribeApi = async (data) => {
  const res = await Apimanager.post("/subscriptions/subscribe", data);
  return res.data;
};

export const postVerifyPayment = async (data) => {
  const res = await Apimanager.post("/subscriptions/verify_payment", data);
  return res.data;
};

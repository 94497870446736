/* eslint-disable react/prop-types */
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";

const ViewPaymentModal = ({
  isOpenModal,
  setIsOpenModal,
  credits,
  currency,
  amount,
  transactionNumber,
  message,
  screenShot,
}) => {
  return (
    <Dialog open={isOpenModal} onOpenChange={() => setIsOpenModal()}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Payment Details</DialogTitle>
        </DialogHeader>
        <div className="px-6">
          <div className="space-y-3 font-pro max-h-[70vh] overflow-y-auto scrollbar-thin">
            <div className="space-y-1">
              <p>No. of credits</p>
              <Input value={credits} readOnly disabled />
            </div>
            <div className="space-y-1">
              <p>Amount</p>
              <Input
                value={
                  currency &&
                  amount?.toLocaleString("en-US", {
                    style: "currency",
                    currency: currency,
                  })
                }
                readOnly
                disabled
              />
            </div>
            <div className="space-y-1">
              <p>Transaction No.</p>
              <Input value={transactionNumber} readOnly disabled />
            </div>
            <div className="space-y-1">
              <p>Message</p>
              <Input value={message} readOnly disabled />
            </div>
            <div className="space-y-1">
              <p>Screenshot</p>
              {screenShot ? (
                <img src={screenShot} alt="screenShot" className="w-80" />
              ) : (
                <p className="text-center">No screenshot</p>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ViewPaymentModal;

/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import Joyride, { STATUS } from "react-joyride";
import { useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { LoadingButton } from "../../../components/loading/loading";
import { Button } from "../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../components/ui/dialog";
import {
  useDeleteQuestionCategories,
  useDeleteQuestionKeyword,
  useGetQuestionCategories,
  useGetQuestionKeywords,
  useGetQuestionSets,
  usePostAddEntityQuestion,
} from "../../../query/useEntity";
import CategoryDropdownForModal from "./CategoryDropdownForModal";
import CustomTooltipForJoyride from "./CustomTooltipForJoyride";
import KeywordDropdownForModal from "./KeywordDropdownForModal";
import { DeleteModal } from "./modal";
import QuestionSetDropdownForModal from "./QuestionSetDropdownForModal";
import ResponseOptionsForModal from "./ResponseOptionsForModal";

const AddQuestionModal = ({ openAddModal, setOpenAddModal, questionSet }) => {
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const [question, setQuestion] = useState({
    question: "",
    category_link: null,
    keyword_link: null,
    response_options: ["Excellent", "Good", "Average", "Bad"],
    has_multiple_responses: false,
    show_as: "CHIPS",
    sets: [],
    is_mandatory: false,
  });
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedKeyword, setSelectedKeyword] = useState("");
  const [selectedQuestionSets, setSelectedQuestionSets] = useState([]);
  const [isOpenDeleteCategory, setIsOpenDeleteCategory] = useState(false);
  const [deleteCategorySlug, setDeleteCategorySlug] = useState("");
  const [isOpenDeleteKeyword, setIsOpenDeleteKeyword] = useState(false);
  const [deleteKeywordSlug, setDeleteKeywordSlug] = useState("");
  const [error, setError] = useState("");

  const joyRideStepsForAddQuestions = [
    {
      title: "Question Set",
      content: "Create new question sets or select existing ones.",
      target: ".question-set",
      disableBeacon: true,
    },
    {
      title: "Category & Keyword",
      content:
        "Add or select categories and keywords. These are optional but can help target specific areas.",
      target: ".category",
    },
    {
      title: "Enter Question",
      content:
        "Click to start adding a question that you would like to ask your customers.",
      target: ".add-question",
    },
    {
      title: "Add Responses",
      content: "Add new, delete, or edit existing responses of your choice.",
      target: ".add-responses",
    },
    {
      title: "Import Bulk Responses",
      content:
        "Upload a .txt file with line-separated responses for quick bulk import.",
      target: ".bulk-responses",
    },
    {
      title: "Display Responses",
      content:
        "Show responses as chips or in dropdown format, allowing multiple selections per question.",
      target: ".display-responses",
      referrer: "Close Add Question",
      referrerFunc: function () {
        setOpenAddModal(false);
      },
    },
  ];

  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForAddQuestions,
    stepIndex: 0,
  });

  useEffect(() => {
    if (referer === "verify-email") {
      setStep((prev) => {
        return { ...prev, run: true };
      });
    }
  }, []);

  const { data: categories, refetch: refetchCategory } =
    useGetQuestionCategories();
  const { mutate: deleteCategory, isLoading: deleteCategoryLoading } =
    useDeleteQuestionCategories();

  const { data: keywords, refetch: refetchKeyword } = useGetQuestionKeywords();
  const { mutate: deleteKeyword, isLoading: deleteKeywordLoading } =
    useDeleteQuestionKeyword();

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();

  const { mutate, isLoading } = usePostAddEntityQuestion();
  const queryClient = useQueryClient();

  const handleDeleteCategory = () => {
    deleteCategory(deleteCategorySlug, {
      onSuccess: (res) => {
        toast.success("Deleted category successfully");
        refetchCategory();
        setIsOpenDeleteCategory(false);
        const deleteCategory = deleteCategorySlug?.split("-")?.join(" ");
        if (deleteCategory === selectedCategory?.toLowerCase()) {
          setSelectedCategory(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.category_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleDeleteKeyword = () => {
    deleteKeyword(deleteKeywordSlug, {
      onSuccess: (res) => {
        toast.success("Deleted keyword successfully");
        refetchKeyword();
        setIsOpenDeleteKeyword(false);
        const deleteKeyword = deleteKeywordSlug?.split("-")?.join(" ");
        if (deleteKeyword === selectedKeyword?.toLowerCase()) {
          setSelectedKeyword(null);
          setQuestion((prev) => {
            const current = { ...prev };
            current.keyword_link = null;
            return current;
          });
        }
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handlePush = () => {
    mutate(question, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["getDefaultQuestions"] });
        queryClient.invalidateQueries({
          queryKey: ["getDefaultQuestionsForSets"],
        });
        setOpenAddModal(false);
        toast.success("Question added successfully");
        setError("");
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else if (err.response.data.category_link) {
          toast.error(err.response.data.category_link[0]);
        } else if (err.response.data.keyword_link) {
          toast.error(err.response.data.keyword_link[0]);
        } else if (err.response.data["question:"]) {
          toast.error(err.response.data["question:"][0]);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  useEffect(() => {
    if (questionSet && selectedQuestionSets?.length === 0) {
      setSelectedQuestionSets([{ name: questionSet }]);
      setQuestion((prev) => {
        let current = { ...prev };
        current.sets = [{ name: questionSet }];
        return current;
      });
    }
  }, []);

  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  if (isLoading) {
    return <LoadingButton />;
  }

  return (
    <>
      <Dialog
        // popup
        // dismissible={isOpenDeleteCategory || isOpenDeleteKeyword ? false : true}
        open={openAddModal}
        onOpenChange={() => {
          setOpenAddModal(false);
        }}
        // size={"5xl"}
        // className="z-[1000]"
        // theme={{
        //   content: {
        //     base: "relative p-4 h-auto overflow-y-hidden",
        //     inner: "bg-white dark:bg-darkBg rounded-lg",
        //   },
        //   sizes: {
        //     sm: "max-w-sm",
        //     md: "max-w-md",
        //     lg: "max-w-lg",
        //     xl: "max-w-xl",
        //     "2xl": "max-w-2xl",
        //     "3xl": "max-w-3xl",
        //     "4xl": "max-w-4xl",
        //     "5xl": "max-w-5xl",
        //     "6xl": "max-w-6xl",
        //     "7xl": "max-w-7xl",
        //   },
        //   header: {
        //     base: "flex items-start justify-between rounded-t-lg border-b p-5 dark:border-gray-600 bg-[#f0f5ff] dark:bg-darkLightBg",
        //     close: {
        //       base: "ml-auto inline-flex items-center rounded-full bg-transparent p-1 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900",
        //     },
        //   },
        // }}
      >
        <DialogContent className="lg:max-w-4xl">
          <DialogHeader>
            <DialogTitle className="">
              <h1 className=" text-[20px] font-medium font-pro ">
                Add Question
              </h1>
            </DialogTitle>
          </DialogHeader>
          <div className="px-6 flex flex-col scrollbar-none font-pro ">
            <div className="h-[70vh] overflow-y-auto scrollbar-thin p-1">
              <QuestionSetDropdownForModal
                questionSets={questionSets}
                selectedQuestionSets={selectedQuestionSets}
                setSelectedQuestionSets={setSelectedQuestionSets}
                setQuestion={setQuestion}
                error={error}
                setError={setError}
              />
              <div className="category">
                <CategoryDropdownForModal
                  selectedCategory={selectedCategory}
                  setSelectedCategory={setSelectedCategory}
                  setQuestion={setQuestion}
                  isOpenDeleteCategory={isOpenDeleteCategory}
                  setIsOpenDeleteCategory={setIsOpenDeleteCategory}
                  setDeleteCategorySlug={setDeleteCategorySlug}
                  categories={categories}
                  refetchCategory={refetchCategory}
                />
                <KeywordDropdownForModal
                  selectedKeyword={selectedKeyword}
                  setSelectedKeyword={setSelectedKeyword}
                  setQuestion={setQuestion}
                  isOpenDeleteKeyword={isOpenDeleteKeyword}
                  setIsOpenDeleteKeyword={setIsOpenDeleteKeyword}
                  setDeleteKeywordSlug={setDeleteKeywordSlug}
                  keywords={keywords}
                  refetchKeyword={refetchKeyword}
                />
              </div>

              <ResponseOptionsForModal
                question={question}
                setQuestion={setQuestion}
              />

              <div className="flex flex-wrap lg:gap-2 justify-between lg:justify-end mt-5">
                <Button
                  className="px-10"
                  variant="secondary"
                  onClick={() => {
                    setOpenAddModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  className="px-10"
                  onClick={() => {
                    handlePush();
                  }}
                  disabled={isLoading || selectedQuestionSets?.length === 0}
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
      <DeleteModal
        openModal={isOpenDeleteCategory}
        setOpenModal={setIsOpenDeleteCategory}
        title={`Are you sure want to delete the category "${categories?.find((item) => item?.category_slug === deleteCategorySlug)?.category}"`}
        handleDelete={handleDeleteCategory}
        isLoading={deleteCategoryLoading}
      />
      <DeleteModal
        openModal={isOpenDeleteKeyword}
        setOpenModal={setIsOpenDeleteKeyword}
        title={`Are you sure want to delete the keyword "${keywords?.find((item) => item?.keyword_slug === deleteKeywordSlug)?.keyword}"`}
        handleDelete={handleDeleteKeyword}
        isLoading={deleteKeywordLoading}
      />
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        // stepIndex={step.stepIndex}
        scrollToFirstStep
        showProgress
        showSkipButton
        // disableScrolling={true}
        styles={{
          options: {
            primaryColor: "#7190FF",
            zIndex: 10000,
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
    </>
  );
};

export default AddQuestionModal;

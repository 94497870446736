/* eslint-disable react/prop-types */

const InputBox = ({ title, value, setValue, disabled }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <label htmlFor={title} className="font-poppins font-medium">
        {title}
      </label>
      <input
        type="text"
        id={title}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        className="p-3 rounded-lg border border-[#e3e3e3]"
        disabled={disabled}
      />
    </div>
  );
};

export default InputBox;

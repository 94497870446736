// import { Table } from "flowbite-react";
import {
  Table,
  TableHeader,
  TableRow,
  TableHead,
  TableBody,
  TableCell,
} from "../../../../components/ui/table";
import {
  useGetCharges,
  useGetInvoiceHistory,
  usePostDownloadInvoice,
} from "../../../../query/usePayment";
import { dayFormatter, numberFormatter } from "../../../../utils/formatter";
import Skeleton from "react-loading-skeleton";
import { Download } from "lucide-react";
import { toast } from "sonner";
import { useState } from "react";
import { useDarkMode } from "../../../../context/DarkModeProvider";
import { InvoiceIllustrations } from "../../../onboarding/components/illustrations/InvoiceIllustrations";
const Invoice = () => {
  const { darkMode } = useDarkMode();
  const [selectedDownload, setSelectedDownload] = useState("");
  const { data: charges, isLoading } = useGetCharges();
  const { mutate: downloadInvoice, isLoading: invoiceLoading } =
    usePostDownloadInvoice();

  const handleDownload = (id, date) => {
    setSelectedDownload(id);
    downloadInvoice(id, {
      onSuccess: (res) => {
        const blob = new Blob([res.data], { type: res.data.type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Invoice ${date && dayFormatter(date)}.pdf`;
        link.click();
        toast.success("Invoice downloaded successfully");
      },
      onError: (err) => {
        if (err.response.data.message) {
          toast.error(err.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  return (
    <div className="flex flex-col mt-5 w-full dark:bg-darkLightBg rounded-[16px] py-5 sm:py-[31px] min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]">
      <div className="overflow-x-auto">
        {charges?.results?.length <= 0 ? (
          <InvoiceIllustrations />
        ) : (
          // <div className="w-full min-h-[400px] flex flex-col justify-center items-center space-y-3">
          //   <img src="/empty/no-invoice.svg" alt="" />
          //   <p className="font-pro text-[#888] text-sm">
          //     Your invoices will appear here
          //   </p>
          // </div>
          <div className="rounded-xl overflow-hidden">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead className="text-[#808080] dark:text-white text-[15px]">
                    Date
                  </TableHead>
                  <TableHead className="text-[#808080] dark:text-white text-[15px]">
                    Amount
                  </TableHead>
                  <TableHead className="text-[#808080] dark:text-white text-[15px]">
                    Status
                  </TableHead>
                  <TableHead className="text-[#808080] dark:text-white text-[15px]">
                    Download
                  </TableHead>
                </TableRow>
              </TableHeader>
              <TableBody className="divide-y">
                {isLoading ? (
                  <TableRow>
                    {new Array(4).fill("").map((_, i) => (
                      <TableCell key={i} className="bg-white dark:bg-darkBg">
                        <Skeleton
                          height={30}
                          baseColor={darkMode && "#262626"}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ) : charges?.results?.length > 0 ? (
                  charges?.results?.map((charge, i) => (
                    <TableRow key={i} className="text-[15px]">
                      <TableCell className="">
                        {charge?.created_at && dayFormatter(charge?.created_at)}
                      </TableCell>
                      <TableCell>
                        {charge?.currency}{" "}
                        {charge?.unit_amount / charge?.currency_unit_multiplier}{" "}
                        {"("}
                        {charge?.plan_text}
                        {")"}
                      </TableCell>
                      <TableCell className="capitalize">
                        {charge?.status}
                      </TableCell>
                      <TableCell>
                        {invoiceLoading && selectedDownload === charge?.uuid ? (
                          <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
                        ) : (
                          <Download
                            className="text-[#a6a6a6] cursor-pointer"
                            onClick={() => {
                              handleDownload(charge?.uuid, charge?.created_at);
                            }}
                          />
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                ) : null}
              </TableBody>
            </Table>
          </div>
          // <Card padding={0}>
          //   <IndexTable
          //     loading={false}
          //     itemCount={charges?.results?.length}
          //     headings={[
          //       { title: <span className="text-lg">Date</span> },
          //       { title: <span className="text-lg">Amount</span> },
          //       { title: <span className="text-lg">Status</span> },
          //       { title: <span className="text-lg">Download</span> },
          //     ]}
          //     selectable={false}
          //   >
          //     {isLoading ? (
          //       <IndexTable.Row>
          //         {new Array(4).fill("").map((_, i) => (
          //           <IndexTable.Cell
          //             key={i}
          //             className="bg-white dark:bg-darkBg"
          //           >
          //             <Skeleton height={30} baseColor={darkMode && "#262626"} />
          //           </IndexTable.Cell>
          //         ))}
          //       </IndexTable.Row>
          //     ) : (
          //       charges?.results?.map((charge, i) => (
          //         <IndexTable.Row key={i}>
          //           <IndexTable.Cell className="">
          //             {charge?.created_at && dayFormatter(charge?.created_at)}
          //           </IndexTable.Cell>
          //           <IndexTable.Cell>
          //             {charge?.currency}{" "}
          //             {charge?.unit_amount / charge?.currency_unit_multiplier}{" "}
          //             {"("}
          //             {charge?.plan_text}
          //             {")"}
          //           </IndexTable.Cell>
          //           <IndexTable.Cell className="capitalize">
          //             {charge?.status}
          //           </IndexTable.Cell>
          //           <IndexTable.Cell>
          //             {invoiceLoading && selectedDownload === charge?.uuid ? (
          //               <div className="w-5 h-5 border-t-2 border-b-2 border-[#a6a6a6a] rounded-full animate-spin"></div>
          //             ) : (
          //               <Download
          //                 className="text-[#a6a6a6] cursor-pointer"
          //                 onClick={() => {
          //                   handleDownload(charge?.uuid, charge?.created_at);
          //                 }}
          //               />
          //             )}
          //           </IndexTable.Cell>
          //         </IndexTable.Row>
          //       ))
          //     )}
          //   </IndexTable>
          // </Card>
        )}
      </div>
    </div>
  );
};

export default Invoice;

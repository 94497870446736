/* eslint-disable react/prop-types */
import { Link } from "react-router-dom";
import { useState, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { TiSocialLinkedin } from "react-icons/ti";
import MainLogo from "../mainlogo";
import { usePostContact } from "../../query/useContact";
import LoaderIcon from "react-loader-icon";
import { toast } from "sonner";
import { FaInstagram } from "react-icons/fa";
import { RiTwitterXLine } from "react-icons/ri";
import WordBreak from "../wordbreak";
import { emailRegex, phoneRegex } from "../../routes/contact/contact";
const Footer = ({ pricing }) => {
  const form = useRef();
  const location = useLocation();
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    message: "",
    phone_number: "",
    company: "",
  });
  const { mutate } = usePostContact();

  const handleFormChange = (e) => {
    const { name, value } = e.target;

    // Phone number validation regex

    // Email validation regex

    // Check if the input field is the phone number and if it matches the regex
    if (name === "phone_number" && !phoneRegex.test(value)) {
      // Invalid phone number, don't update the state
      return;
    }
    setFormFields((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      formFields.name.trim() === "" ||
      formFields.company.trim() === "" ||
      formFields.phone_number.trim() === "" ||
      formFields.email.trim() === "" ||
      formFields.message.trim() === "" ||
      isLoading
    ) {
      toast.error("Kindly provide all the inputs!");
      return;
    }

    setLoading(true);
    if (!emailRegex.test(formFields.email)) {
      toast.error("Invalid email");
      setLoading(false);
      return;
    }

    let data = {
      name: formFields.name,
      email: formFields.email,
      message: formFields.message,
      phone_number: formFields.phone_number,
      company: formFields.company,
    };

    mutate(data, {
      onSuccess: (res) => {
        setLoading(false);
        toast.success("Message sent successfully");
        form.current.reset();
        setFormFields({
          name: "",
          email: "",
          message: "",
          phone_number: "",
          company: "",
        });
      },
      onError: () => {
        setLoading(false);
        toast.error("An error occured");
      },
    });
  };
  const handleNavigate = (url) => {
    navigate(url);
    window.scrollTo(0, 0);
  };

  return (
    <div className="w-full font-poppins bg-newPrimary">
      <div
        className={`container ${pricing && "lg:pt-40 pt-12"} lg:py-20 py-12 space-y-12`}
      >
        {/* upper portion */}
        <div
          className={`
        ${location.pathname === "/contact-us" ? "hidden" : "flex"}
         items-center md:flex-row flex-col md:space-y-0 space-y-10  justify-between`}
        >
          <div className="space-y-3 md:w-full">
            <h2 className="font-semibold text-white text-[24px] md:text-[35px] lg:text-[50px]">
              Learn How <WordBreak /> MagicReview Can <WordBreak /> Grow Your
              Business
            </h2>
            <h4 className="text-white font-light">Schedule a Call Today!</h4>
          </div>
          <div
            className={`md:w-[80%] w-full ${location.pathname === "/try-it-now" && "hidden"}`}
          >
            <form
              className="space-y-3 flex flex-col w-full"
              onSubmit={handleSubmit}
              ref={form}
            >
              <div className="flex xxs:flex-row flex-col gap-2 w-full">
                <input
                  type="text"
                  name="name"
                  value={formFields.name}
                  onChange={handleFormChange}
                  placeholder="Name"
                  className="w-full border-none rounded-[10px] px-6 py-3"
                />
                <input
                  type="text"
                  name="email"
                  value={formFields.email}
                  onChange={handleFormChange}
                  placeholder="Email"
                  className="w-full border-none rounded-[10px] px-6 py-3"
                />
              </div>
              <div className="flex xxs:flex-row flex-col gap-2">
                <input
                  type="text"
                  name="company"
                  value={formFields.company}
                  onChange={handleFormChange}
                  placeholder="Company"
                  className="w-full border-none rounded-[10px] px-6 py-3"
                />
                <input
                  type="text"
                  name="phone_number"
                  value={formFields.phone_number}
                  onChange={handleFormChange}
                  placeholder="Contact Number"
                  className="w-full border-none rounded-[10px] px-6 py-3"
                />
              </div>
              <textarea
                className="w-full border-none rounded-[10px] px-6 py-3 resize-none"
                placeholder="Message"
                rows={4}
                name="message"
                value={formFields.message}
                onChange={handleFormChange}
              ></textarea>

              <button
                className="bg-[#4366E1] text-white py-4 rounded-[12px] w-full font-semibold"
                type="submit"
              >
                {isLoading ? (
                  <LoaderIcon type="cylon" color="#FFF" size={25} />
                ) : (
                  "Contact Me"
                )}
              </button>
            </form>
          </div>
        </div>
        {/* lower portion */}
        <div className="space-y-8">
          <div className="flex md:flex-row flex-col md:space-y-0 space-y-5 justify-between items-start">
            <div className="space-y-[9px] flex flex-col justify-start">
              <MainLogo color={"white"} className={"-ml-[10px]"} />
              <p className="text-white font-light">15906130</p>
              <p className="text-white font-light">
                30, Churchill Place, <WordBreak /> London, E14 5RE <br />
              </p>
            </div>
            <div className="flex lg:gap-14 text-center gap-4 text-white">
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => handleNavigate("/")}
              >
                Home
              </span>
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => handleNavigate("/how-it-works")}
              >
                How It Works
              </span>
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => handleNavigate("/blog")}
              >
                Blog
              </span>
              <span
                className="cursor-pointer hover:opacity-80"
                onClick={() => handleNavigate("/about-us")}
              >
                About Us
              </span>
            </div>
          </div>
          <div className="w-full bg-white h-[1px]"></div>
          <div className="flex items-center flex-col md:flex-row justify-between">
            <div className="flex justify-center items-center gap-3">
              <Link
                target="_blank"
                to={"https://www.linkedin.com/company/magicreviewai/"}
              >
                <TiSocialLinkedin className="text-white cursor-pointer hover:scale-90 transition-all duration-300 ease-in-out text-3xl" />
              </Link>
              <Link
                target="_blank"
                to={"https://www.instagram.com/magicreview.ai/"}
              >
                <FaInstagram className="text-white cursor-pointer hover:scale-90 transition-all duration-300 ease-in-out text-xl" />
              </Link>
              <Link target="_blank" to={"#"}>
                <RiTwitterXLine className="text-white cursor-pointer hover:scale-90 transition-all duration-300 ease-in-out text-xl" />
              </Link>
            </div>
            <div className="flex gap-4 items-center justify-center text-white mt-5">
              <span
                className="hover:opacity-80 cursor-pointer"
                onClick={() => handleNavigate("/privacy-policy")}
              >
                Privacy Policy
              </span>
              <div className="w-[1px] bg-white h-10"></div>
              <Link
                className="hover:opacity-80 cursor-pointer"
                to={"/terms-and-conditions"}
                onClick={() => window.scrollTo(0, 0)}
                // onClick={() => handleNavigate("/terms-and-conditions")}
              >
                Terms Of Service
              </Link>
            </div>
          </div>
        </div>

        {/* <img src="/magicreview-text.png" alt="" /> */}
        <div className="relative">
          {/* Text */}
          <h1 className="text-[13vw] text-white font-semibold text-center">
            MagicReview
          </h1>

          {/* Blur Overlay */}
          <div className="absolute inset-x-0 bottom-0 h-1/2 bg-gradient-to-t from-newPrimary to-transparent blur-3xl"></div>
        </div>
        {/* <h1 className="text-[13vw] text-white font-semibold">MagicReview</h1> */}
      </div>
    </div>
  );
};

export default Footer;

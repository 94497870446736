import { useMutation, useQuery } from "@tanstack/react-query";
import { getQuestionsForVsl, postGenerateReviewsForVsl } from "../api/vsl";

export const useGetQuestionsForVsl = ({ industryType }, config) => {
  return useQuery({
    queryKey: ["getQuestionsForVsl", industryType],
    queryFn: () => getQuestionsForVsl({ industryType: industryType }),
    ...config,
  });
};

export const usePostGenerateReviewsForVsl = () => {
  return useMutation({
    mutationKey: ["postGenerateReviews"],
    mutationFn: (data) => postGenerateReviewsForVsl(data),
  });
};

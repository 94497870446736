import { Link } from "react-router-dom";
import WordBreak from "../../components/wordbreak";

export default function Custom404() {
  return (
    <section className="flex flex-col space-y-10 justify-center items-center container py-[5rem] h-full">
      <img alt="not found image" src="/404.svg" className="w-full max-w-[400px]"/>
      <div className="space-y-6 text-center">
        <div className="font-publicsans font-[600] text-5xl">
          Page not Found
        </div>
        <p className="font-publicsans font-[400] text-lg">
          The page you&apos;re looking for might be renamed removed.{" "}
          <WordBreak />
          or might never exist on this planet.
        </p>
      </div>
      <Link to={"/"}>
        <button className=" bg-newPrimary text-white px-14 py-3 rounded-md border-[1px] text-semibold hover:bg-white hover:text-newPrimary hover:border-newPrimary transition-colors duration-300 ease-in-out">
          Go Home
        </button>
      </Link>
    </section>
  );
}
/* eslint-disable react/prop-types */
import { Modal } from "flowbite-react";
import { LoadingButton } from "../../../../components/loading/loading";
import Cropper from "react-easy-crop";
import { useState } from "react";
import getCroppedImg from "../../../../utils/getCroppedImage";
import { toast } from "sonner";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import { Button } from "../../../../components/ui/button";

const ImageCropper = ({
  openModal,
  setOpenModal,
  handleUpdateUserProfile,
  profilePictureUrl,
  isLoading,
  croppedImage,
  setCroppedImage,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const handleCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(
        profilePictureUrl,
        croppedAreaPixels,
        rotation
      );
      setCroppedImage(croppedImage);
    } catch (e) {
      toast.error("Cannot crop the image");
    }
  };

  const onClose = () => {
    setCroppedImage(null);
    setOpenModal(false);
  };
  return (
    <Dialog open={openModal} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader className={"hidden"}>
          <DialogTitle></DialogTitle>
        </DialogHeader>
        <div className="px-6 min-h-[500px]">
          {croppedImage ? (
            <div className="absolute top-0 left-0 right-0 bottom-[100px] flex items-center justify-center">
              <img
                src={croppedImage}
                alt=""
                className="w-60 h-60 object-cover rounded-full"
              />
            </div>
          ) : (
            <div className="absolute top-0 left-0 right-0 bottom-[120px] rounded-t-lg overflow-hidden">
              <Cropper
                image={profilePictureUrl}
                cropShape="round"
                crop={crop}
                rotation={rotation}
                zoom={zoom}
                aspect={1 / 1}
                onCropChange={setCrop}
                onRotationChange={setRotation}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
              />
            </div>
          )}

          <div className="absolute bottom-[15px] left-0 w-full px-5 lg:px-14 space-y-3">
            {!croppedImage && (
              <div className="flex items-center gap-2">
                <p className="font-pro lg:text-lg dark:text-white">Zoom:</p>
                <input
                  type="range"
                  value={zoom}
                  max={3}
                  step={0.1}
                  onChange={(e) => {
                    if (e.target.value > 1) {
                      setZoom(e.target.value);
                    }
                  }}
                  className={`w-full audioplayer h-1`}
                  style={{
                    background: `linear-gradient(to right, #4F75FA 0%, #4F75FA ${(zoom / 3) * 100}%, #F7F9FF ${(zoom / 3) * 100}%, #F7F9FF 100%)`,
                    WebkitAppearance: "none",
                    MozAppearance: "none",
                    appearance: "none",
                  }}
                />
              </div>
            )}
            <div className="flex  gap-2 justify-between items-center ">
              <Button
                className="w-full"
                onClick={
                  croppedImage ? handleUpdateUserProfile : handleCroppedImage
                }
              >
                {isLoading ? <LoadingButton /> : croppedImage ? "Save" : "Crop"}
              </Button>

              <Button variant="secondary" onClick={onClose} className="w-full">
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImageCropper;

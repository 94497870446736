const IconDownload = (props) => {
  return (
    <svg
      width="47"
      height="47"
      viewBox="0 0 47 47"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M39.8406 6.99243C35.352 2.56842 29.8946 0.356422 23.4685 0.356422C17.2039 0.356422 11.7627 2.56842 7.1449 6.99243C2.6886 11.4487 0.460449 16.9061 0.460449 23.3645C0.460449 29.8229 2.6886 35.2641 7.1449 39.6881C9.37304 41.9485 11.8515 43.66 14.5801 44.8225C17.3088 45.985 20.2716 46.5663 23.4685 46.5663C29.9915 46.5663 35.4488 44.2736 39.8406 39.6881C44.2646 35.2641 46.4766 29.831 46.4766 23.3887C46.4766 16.9464 44.2646 11.481 39.8406 6.99243ZM20.4653 18.1332V30.2427L30.3467 24.1879L20.4653 18.1332Z"
        fill="#4F75FA"
      />
      <path
        d="M32.125 26.375V30.2083C32.125 30.7167 31.9231 31.2042 31.5636 31.5636C31.2042 31.9231 30.7167 32.125 30.2083 32.125H16.7917C16.2833 32.125 15.7958 31.9231 15.4364 31.5636C15.0769 31.2042 14.875 30.7167 14.875 30.2083V26.375"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7083 21.5834L23.4999 26.375L28.2916 21.5834"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.5 26.375V14.875"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IconDownload;

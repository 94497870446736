/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";
import {
  RadioGroup,
  RadioGroupItem,
} from "../../../../components/ui/radio-group";
import { Input } from "../../../../components/ui/input";
import { Button } from "../../../../components/ui/button";
import { usePostGmbAccConfirm } from "../../../../query/useAuthentication";
import { LogoLoading } from "../../../../components/loading/loading";
import { toast } from "sonner";
import { QueryClient } from "@tanstack/react-query";

const GmbIntegration = ({
  openModal,
  setOpenModal,
  gmbInfo,
  refreshToken,
  refetchGmbInfo,
}) => {
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [seeMore, setSeeMore] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const locations = gmbInfo?.locations_data?.locations;
  const accounts = gmbInfo?.accounts_data?.accounts;

  const queryClient = new QueryClient();

  const { mutate: gmbAccConfirm, isLoading: gmbAccConfirmLoading } =
    usePostGmbAccConfirm();

  const handleGmbAccConfirm = () => {
    const locationId = selectedLocation?.split("/")?.[1];
    gmbAccConfirm(
      {
        refresh_token: refreshToken,
        account_id: gmbInfo?.account_id,
        location_id: locationId,
      },
      {
        onSuccess: (res) => {
          setOpenModal(false);
          refetchGmbInfo();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (gmbAccConfirmLoading) {
    return <LogoLoading />;
  }

  return (
    <>
      <Dialog
        open={openModal}
        onOpenChange={() => {
          setOpenModal(false);
        }}
      >
        <DialogContent className="lg:max-w-2xl">
          <DialogHeader className={openConfirm ? "block" : "hidden"}>
            <DialogTitle>Confirm Setup</DialogTitle>
          </DialogHeader>
          {openConfirm ? (
            <div className="px-6">
              <p className="font-pro text-sm">
                Please confirm to setup the google integration for the account
                that you have selected.
              </p>
              <div className="flex justify-end items-center gap-x-3 mt-7">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenConfirm(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={handleGmbAccConfirm}>Confirm</Button>
              </div>
            </div>
          ) : (
            <div className="px-6 pt-6 font-poppins ">
              <p className=" font-semibold text-[22px]">
                {accounts?.[0]?.accountName}
              </p>
              <hr className="my-3" />
              <div className="space-y-3 h-[60vh] overflow-y-auto scrollbar-thin">
                <RadioGroup
                  defaultValue={selectedLocation}
                  onValueChange={(e) => setSelectedLocation(e)}
                >
                  {locations?.map((item) => (
                    <div key={item?.name} className="flex gap-3">
                      <RadioGroupItem
                        value={item?.name}
                        id={item?.name}
                      ></RadioGroupItem>
                      <label
                        htmlFor={item?.name}
                        className="w-full lg:min-w-[450px] cursor-pointer flex flex-col gap-3 p-5 bg-[#f0f5ff] dark:bg-darkLightBg rounded-[10px]"
                      >
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px]">Name</p>
                          <Input value={item?.title} readOnly />
                        </div>
                        <div className="flex flex-col gap-2">
                          <p className="font-medium text-[15px]">Location</p>
                          <Input
                            value={item?.storefrontAddress?.administrativeArea}
                            readOnly
                          />
                        </div>
                        {seeMore === item?.name && (
                          <>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px]">
                                Region Code
                              </p>
                              <Input
                                value={item?.storefrontAddress?.regionCode}
                                readOnly
                              />
                            </div>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px]">
                                Maps URL
                              </p>
                              <Input value={item?.metadata?.mapsUri} readOnly />
                            </div>
                            <div className="flex flex-col gap-2">
                              <p className="font-medium text-[15px]">
                                Review URL
                              </p>
                              <Input
                                value={item?.metadata?.newReviewUri}
                                readOnly
                              />
                            </div>
                          </>
                        )}
                        {seeMore === item?.name ? (
                          <Button
                            variant="link"
                            onClick={() => setSeeMore(null)}
                          >
                            See Less
                          </Button>
                        ) : (
                          <Button
                            variant="link"
                            onClick={() => setSeeMore(item?.name)}
                          >
                            See More
                          </Button>
                        )}
                      </label>
                    </div>
                  ))}
                </RadioGroup>
              </div>
              <div className="flex justify-end items-center gap-x-3 mt-7">
                <Button
                  variant="secondary"
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  Cancel
                </Button>
                <Button onClick={() => setOpenConfirm(true)}>Continue</Button>
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default GmbIntegration;

/* eslint-disable no-unused-vars */
import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WordBreak from "../../components/wordbreak";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import HeadlessFaq from "../../components/faqs/HeadlessFaq";
import IconRotateArrow from "../../assets/icons/IconRotateArrow";
import { FeedbackInput } from "../../components/feedback";
import IconSearch from "../../assets/icons/IconSearch";
import { v4 } from "uuid";
import { useState } from "react";
const Blog = () => {
  const navigate = useNavigate();

  const handleBlog4 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/why-ratings-reviews-important-business");
  };
  const handleBlog5 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-automate-google-reviews-collection-magicreview");
  };

  const handleBlog6 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/7-tips-finding-best-review-platforms-business");
  };

  const handleBlog8 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/what-is-magicreview");
  };

  const handleBlog7 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-get-more-google-reviews");
  };

  const handleBlog9 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/7-powerful-ways-boost-your-google-reviews");
  };

  const handleBlog10 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-get-more-google-reviews-for-restaurants");
  };

  const handleBlog11 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/increase-restaurant-sales-without-ads");
  };

  // const handleBlog12 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/blog/ai-review-cards-in-uae");
  // };

  // const handleBlog13 = () => {
  //   window.scrollTo(0, 0);
  //   navigate("/blog/how-to-boost-google-reviews");
  // };

  const handleBlog12 = () => {
    window.scroll(0, 0);
    navigate("/blog/ai-review-cards-in-uae");
  };

  const handleBlog13 = () => {
    window.scroll(0, 0);
    navigate("/blog/how-to-boost-google-reviews");
  };

  const handleBlog14 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/5-ways-to-increase-your-sales-and-visibility");
  };

  const handleBlog15 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/traditional-vs-ai-powered-reviews");
  };

  const handleBlog16 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/top-15-restaurant-review-websites");
  };

  const handleBlog17 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-respond-to-google-reviews");
  };

  const handleBlog18 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-get-and-share-your-google-review-link");
  };

  const handleBlog19 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/why-google-maps-ranking-important-local-business");
  };

  const handleBlog20 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/how-to-create-online-reputation-management-strategy");
  };
  const handleBlog22 = () => {
    window.scrollTo(0, 0);
    navigate("/blog/benefits-nfc-review-cards");
  };

  const data = [
    {
      title: "5 Benefits of NFC Technology Review Cards",
      image: "/blog22cover.png",
      click: handleBlog22,
      date: "Jun 14, 2024",
    },
    {
      title: "Why Ratings And Reviews Are Important For A Business?",
      image: "/blog1.jpg",
      click: handleBlog4,
      date: "July 05, 2023",
    },
    {
      title: "How to Automate Google Reviews Collection | Magicreview",
      image: "how-to-automate-google-reviews-collection.jpg",
      click: handleBlog5,
      date: "July 05, 2023",
    },
    {
      title: "7 Tips for Finding the Best Review Platforms for Your Business",
      image: "/blog7.jpg",
      click: handleBlog6,
      date: "February 27, 2024",
    },
    {
      title: "What is MagicReview ?",
      image: "/blog8.png",
      click: handleBlog8,
      date: "February 28, 2024",
    },
    {
      title: "How to Get More Google Reviews with Minimal Effort",
      image: "/blog9.png",
      click: handleBlog7,
      date: "February 29, 2024",
    },
    {
      title: "7 Powerful Ways to Boost Your Google Reviews in 2024",
      image: "7-powerful-ways-to-boost-your-google-reviews-in-2024.jpg",
      click: handleBlog9,
      date: "March 05, 2024",
    },
    {
      title: "How Restaurants Can Get More Google Reviews",
      image: "how-restaurants-can-get-more-google-reviews.jpg",
      click: handleBlog10,
      date: "March 09, 2024",
    },
    {
      title: "How to Increase Restaurant Sales Without Advertising",
      image: "/how-to-increase-restaurant-sales.jpg",
      click: handleBlog11,
      date: "March 14, 2024",
    },
    {
      title:
        "How MagicReview Is Revolutionizing the UAE Market with AI Review Cards",
      image: "/how-magicreview-is-revolutionizing-the-uae-market.png",
      click: handleBlog12,
      date: "March 18, 2024",
    },
    {
      title:
        "How To Boost Google Reviews For Your Business - The Ultimate Guide",
      image: "/how-to-boost-google-reviews.jpg",
      click: handleBlog13,
      date: "March 22, 2024",
    },
    {
      title: "5 Ways MagicReview Can Increase Your Sales & Visibility",
      image: "/5-ways-magicreview-can-increase-your-sales-and-visibility.png",
      click: handleBlog14,
      date: "March 26, 2024",
    },
    {
      title:
        "Traditional Review Methods vs. AI-Powered Reviews - What is Better?",
      image: "/traditional-review-vs-ai-review.jpg",
      click: handleBlog15,
      date: "March 29, 2024",
    },
    {
      title: "The Top 15 Restaurant Review Websites in 2024",
      image: "/top-15-restaurant-websites-reviews.png",
      click: handleBlog16,
      date: "April 01, 2024",
    },
    {
      title: "How to Respond to Google Reviews",
      image: "/get-and-share-google-review-link.png",
      click: handleBlog17,
      date: "April 10, 2024",
    },
    {
      title: "6 Best Methods - How To Get & Share Your Google Review Link",
      image: "/6-best-methods-get-and-share-google-review-link.png",
      click: handleBlog18,
      date: "April 16, 2024",
    },
    {
      title: "Why is Google Maps Ranking Important for Your Local Business?",
      image: "/why-is-google-map-ranking-important.jpg",
      click: handleBlog19,
      date: "May 23, 2024",
    },
    {
      title: "How to Create an Online Reputation Management Strategy in 2024",
      image: "/how-to-create-online-reputation.jpg",
      click: handleBlog20,
      date: "May 24, 2024",
    },
  ];

  const [activeBlogs, setActiveBlogs] = useState({
    name: "All",
    data,
  });

  const [input, setInput] = useState("");
  const [filter, setFilter] = useState("");

  const showSearchedBlog = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="flex flex-col min-h-screen w-full bg-white text-black">
      <Helmet>
        <title>Blog - Magicreview</title>
        <meta name="title" content="Blog - Magicreview" />
        <meta
          name="description"
          content="Discover how MagicReview utilizes AI to collect more reviews. Explore our insightful blogs on boosting customer engagement and enhancing digital presence."
        />
        <link rel="canonical" href="https://www.magicreview.ai/blog" />
      </Helmet>
      <div className={`w-full h-full`}>
        <Mainnav />
        <div className="container py-10 space-y-14">
          <div className="flex flex-row flex-wrap justify-between text-center">
            <h2 className="font-semibold text-3xl font-poppins tracking-wide text-start">
              Explore <WordBreak /> Our Blogs
            </h2>
          </div>
          <div className="bg-[#cecdcd] w-full h-[1px]"></div>
          <div className="w-full flex flex-row flex-wrap justify-between">
            <div className="flex items-center relative">
              <input
                value={input}
                onChange={(e) => showSearchedBlog(e)}
                className="relative border-none focus:outline-none focus:ring-0  font-poppins bg-[#F6F6FB] px-5 py-3 rounded-[41px]"
                type="text"
                placeholder="Search"
              />
              <IconSearch className="absolute top-1/2 right-2 transform -translate-y-1/2" />
            </div>
            <div className="flex flex-wrap items-center gap-3 mt-3">
              <button
                onClick={(e) => setFilter("")}
                className={`rounded-full ${filter == "" && "bg-newPrimary text-white"} bg-[#F6F6FB] font-poppins font-normal px-4 py-2 transition-all duration-500 ease-in-out text-nowrap`}
              >
                All
              </button>
              <button
                onClick={(e) => setFilter("ai")}
                className={`rounded-full ${filter == "ai" && "bg-newPrimary text-white"} bg-[#F6F6FB]  font-poppins font-normal px-4 py-2 transition-all duration-500 ease-in-out text-nowrap`}
              >
                Ai Reviews
              </button>
              <button
                onClick={(e) => setFilter("google")}
                className={`rounded-full ${filter == "google" && "bg-newPrimary text-white"} bg-[#F6F6FB]  font-poppins font-normal px-4 py-2 transition-all duration-500 ease-in-out text-nowrap`}
              >
                Google Reviews
              </button>
            </div>
          </div>
          <div className="flex flex-row flex-wrap justify-start items-center gap-10 space-y-5 py-10">
            {activeBlogs?.data
              ?.filter(
                (item) =>
                  item.title.toLowerCase().indexOf(filter.toLowerCase()) > -1
              )
              ?.filter(
                (item) =>
                  item.title.toLowerCase().indexOf(input.toLowerCase()) > -1
              )
              ?.map((item, id) => {
                return (
                  <div
                    key={id}
                    onClick={item?.click}
                    className="w-full md:w-[45%] lg:w-[31%] h-full space-y-3 overflow-hidden cursor-pointer"
                  >
                    <div className="w-full h-64 overflow-hidden rounded-md">
                      <img
                        className="w-full h-full object-cover hover:scale-105 transition-all duration-500 ease-in-out"
                        src={item?.image}
                        alt="blog img"
                      />
                    </div>
                    <h3 className="font-poppins font-semibold text-lg leading-normal tracking-wide">
                      {item?.title}
                    </h3>
                    <p className="font-poppins text-sm text-[#101828]">
                      {item?.date}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Blog;

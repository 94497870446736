import { motion } from "framer-motion";
import {
  ChevronRight,
  Pencil,
  Search,
  Settings,
  Trash2,
  Sparkles,
  Play,
  SquarePlus,
} from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import { v4 as uuidv4 } from "uuid";
import Loading, {
  LoadingButton,
  LogoLoading,
} from "../../../components/loading/loading";
import WordBreak from "../../../components/wordbreak";
import useDebounce from "../../../hooks/useDebounce";
import {
  useDeleteQuestionSet,
  useGetDefaultEntityQuestions,
  useGetDefaultEntityQuestionsForSets,
  useGetQuestionSets,
  useGetSearchQuestions,
  usePostAddQuestionSet,
  usePostToggleQuestion,
} from "../../../query/useEntity";
import AddQuestionModal from "../../dashboard/component/AddQuestionModal";
import { DeleteModal } from "../../dashboard/component/modal";
import AddOrEditQuestionSet from "./AddOrEditQuestionSet";
import CustomDropdown from "./CustomDropdown";
import IconButton from "../../dashboard/component/IconButton";
import QuestionSettingsModal from "./QuestionSettingsModal";
import QuestionCard from "./QuestionCard";
import QuestionCardForQuestionSet from "./QuestionCardForQuestionSet";
import Joyride, { STATUS } from "react-joyride";
import CustomTooltipForJoyride from "../../dashboard/component/CustomTooltipForJoyride";
import AIQuestions from "./AIQuestions";
import QuestionPreview from "./QuestionPreview";
import { QuestionsIllustrations } from "./illustrations/QuestionsIllustrations";
const Questions = () => {
  const questionSetRef = useRef(null);
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const referer = params.get("referrer");
  const [questions, setQuestions] = useState([]);
  const [questionsForSets, setQuestionsForSets] = useState([]);
  const [extra, setExtra] = useState();
  const [openAddModal, setOpenAddModal] = useState(false);
  const [category, setCategory] = useState();
  const [filter, setFilter] = useState(null);
  const [sort, setSort] = useState("recent");
  const [selectedQuestionSet, setSelectedQuestionSet] = useState(null);
  const [searchQuestion, setSearchQuestion] = useState("");
  const [isOpenAddOrEditQuestionSet, setIsOpenAddOrEditQuestionSet] =
    useState(false);
  const [isOpenDeleteQuestionSet, setIsOpenDeleteQuestionSet] = useState(false);
  const [questionSetId, setQuestionSetId] = useState();
  const [toUpdateQuestionSet, setToUpdateQuestionSet] = useState();
  const [questionSetName, setQuestionSetName] = useState("");
  const [isOpenQuestionSettings, setIsOpenQuestionSettings] = useState(false);
  const [isOpenAIModal, setIsOpenAIModal] = useState(false);
  const location = useLocation();
  const dashboard = location.pathname.includes("dashboard");
  const joyRideStepsForQuestions = [
    {
      title: "Add questions",
      content: "Click to add your first question",
      target: ".add-button",
      disableBeacon: true,
      // referrer: "Open Add Question",
      // referrerFunc: function () {
      //   setOpenAddModal(true);
      // },
    },
    {
      title: "Question settings",
      content: "Manage question sets for an easy collection of reviews",
      target: ".question-settings",
      // referrerFunc: function () {
      //   navigate("/dashboard/staffs?referrer=verify-email");
      // },
      // referrer: "Go to magiclink section",
    },
    {
      title: "Keywords",
      content:
        "Keywords are specific words to help your customers find particular topics or items. For instance, as an educational centre, your Keywords may include science courses, beginner level courses, and more.",
      target: ".keywords",
      placement: "bottom",
    },
    {
      title: "Categories",
      content:
        "Categories are broad groups to organise questions with similar topics together. For instance, as an educational centre, your categories may include courses, levels, and more.",
      target: ".categories",
      placement: "bottom",
    },
    {
      title: "Question set(s)",
      content:
        "Question set(s) are a collection of related questions about a topic. For instance, as an educational centre, you may collect reviews via different question set(s) according to intermediate level science courses, advanced level science courses, and more.",
      target: ".question-set",
    },
  ];
  const [step, setStep] = useState({
    run: false,
    steps: joyRideStepsForQuestions,
    stepIndex: 0,
  });
  const handleJoyrideCallback = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStep((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  const joyRideStepsForGetStarted = [
    {
      icon: <Sparkles />,
      title: "Add your first question",
      content: "Add / view all questions here. Start to add a question.",
      target: ".questions",
      disableBeacon: true,
      placement: "right",
    },
    {
      icon: <Sparkles />,
      title: "Click to add new question",
      content:
        "Click on “Add Question” button to start adding your first question.",
      target: ".add-button",
    },
    {
      icon: <Sparkles />,
      title: "Click to copy Magiclink",
      content: "Go to your browser and paste the link to view review page.",
      target: ".copy-link",
    },
  ];
  const [stepGetStart, setStepGetStart] = useState({
    run: false,
    steps: joyRideStepsForGetStarted,
    stepIndex: 0,
  });
  const handleJoyrideCallbackGetStart = (data) => {
    const { status, type, index, action } = data;
    const options = [STATUS.FINISHED, STATUS.SKIPPED];

    if (options.includes(status)) {
      setStepGetStart((prev) => {
        return { ...prev, run: false };
      });
    }
  };

  useEffect(() => {
    if (referer === "get-start") {
      setStepGetStart((prev) => ({ ...prev, run: true }));
    }
  }, [referer]);

  useEffect(() => {
    if (referer === "verify-email" && location.pathname.includes("questions")) {
      setStep((prev) => ({ ...prev, run: true }));
    }
  }, [referer, location.pathname]); // Add dependencies to avoid unnecessary re-renders

  const debouncedSearchQuestion = useDebounce(searchQuestion, 500);

  const {
    data: questionsFromNormalApi,
    isLoading: isQuestionsNormalLoading,
    refetch: refetchNormalQuestions,
    isFetching: isQuestionsFromNormalApiFetching,
  } = useGetDefaultEntityQuestions(
    {
      filter: filter,
      sort: sort,
    },
    {
      onSuccess: (res) => {
        setQuestions(res?.data);
        setExtra(res?.extra?.From);
      },
      enabled: !searchQuestion && selectedQuestionSet === null,
      refetchOnWindowFocus: false, // Prevent refetching when window/tab gains focus
      refetchOnMount: false, // Prevent refetching when the component mounts
      refetchOnReconnect: false, // Prevent refetching when reconnect
    }
  );

  const {
    data: questionsFromSetApi,
    isLoading: isQuestionsSetsLoading,
    refetch: refetchSetsQuestions,
    isFetching: isQuestionsFromSetApiFetching,
  } = useGetDefaultEntityQuestionsForSets(
    {
      filter: filter,
      sort: sort,
      set: selectedQuestionSet,
    },
    {
      onSuccess: (res) => {
        setQuestionsForSets(res?.data);
        setExtra(res?.extra?.From);
      },
      enabled: !searchQuestion && Boolean(selectedQuestionSet),
      refetchOnWindowFocus: false, // Prevent refetching when window/tab gains focus
      refetchOnMount: false, // Prevent refetching when the component mounts
      refetchOnReconnect: false, // Prevent refetching when reconnect
    }
  );

  const { isLoading: isSearchQuestionLoading } = useGetSearchQuestions(
    {
      search: debouncedSearchQuestion,
    },
    {
      onSuccess: (res) => {
        setQuestions(res?.data);
        setFilter(null);
      },
    }
  );

  const { data: questionSets, refetch: refetchQuestionSets } =
    useGetQuestionSets();

  const { mutate: toggleQuestion } = usePostToggleQuestion();
  const { mutate: deleteQuestionSet, isLoading: deleteQuestionSetLoading } =
    useDeleteQuestionSet();

  const { mutate: addQuestionSet, isLoading: addQuestionSetLoading } =
    usePostAddQuestionSet();

  const handleAddModal = () => {
    setOpenAddModal(true);
  };

  const handleToggleQuestion = (id) => {
    toggleQuestion(id, {
      onSuccess: (res) => {
        toast.success(res?.message);
        refetchQuestions();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const handleAddQuestionSet = () => {
    if (!questionSetName) {
      toast.error("Question set name is required");
      return;
    }
    addQuestionSet(
      {
        name: questionSetName,
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchQuestionSets();
          setQuestionSetName("");
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteQuestionSet = () => {
    deleteQuestionSet(questionSetId, {
      onSuccess: (res) => {
        toast.success(res?.message);
        setIsOpenDeleteQuestionSet(false);
        refetchQuestionSets();
      },
      onError: (err) => {
        if (err?.response?.data?.message) {
          toast.error(err?.response?.data?.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${err.response.status}`
          );
        }
      },
    });
  };

  const questionsFromApi = selectedQuestionSet
    ? questionsFromSetApi
    : questionsFromNormalApi;

  const refetchQuestions = selectedQuestionSet
    ? refetchSetsQuestions
    : refetchNormalQuestions;

  const isQuestionsLoading = selectedQuestionSet
    ? isQuestionsSetsLoading
    : isQuestionsNormalLoading;

  const categories =
    questionsFromApi?.tabs &&
    questionsFromApi?.tabs["category"] &&
    questionsFromApi?.tabs["category"]?.map((item) => item?.category);

  const keywords =
    questionsFromApi?.tabs &&
    questionsFromApi?.tabs["keyword"] &&
    questionsFromApi?.tabs["keyword"]?.map((item) => item?.keyword);

  const companyName = localStorage.getItem("companyName");
  const filteredQuestions = useMemo(() => {
    return questions?.filter((q) =>
      filter === "category"
        ? q.category === category
        : filter === "keyword"
          ? q.keyword === category
          : q
    );
  }, [questions, category, filter]);
  const filteredQuestionsForSets = useMemo(() => {
    return questionsForSets?.filter((q) =>
      filter === "category"
        ? q.question.category === category
        : filter === "keyword"
          ? q.question.keyword === category
          : q
    );
  }, [questionsForSets, category, filter]);

  useEffect(() => {
    if (!categories?.includes(category) && filter === "category") {
      setCategory(categories?.[0]);
    }
    if (!keywords?.includes(category) && filter === "keyword") {
      setCategory(keywords?.[0]);
    }
  }, [categories, keywords, filter]);

  useEffect(() => {
    if (!searchQuestion) {
      refetchQuestions();
      setFilter(null);
    }
  }, [searchQuestion]);

  const defaultSet = useMemo(() => {
    return questionSets?.find((item) => item?.is_default);
  }, [questionSets]);

  const selectedQuestionSetName = useMemo(() => {
    if (selectedQuestionSet) {
      return questionSets?.find((item) => item?.id === selectedQuestionSet)
        ?.name;
    }
  }, [questionSets, selectedQuestionSet]);

  useEffect(() => {
    setSelectedQuestionSet(defaultSet?.id);
  }, [defaultSet]);

  const [isOpenPreviewModal, setIsOpenPreviewModal] = useState(false);

  if (addQuestionSetLoading) {
    return <LogoLoading />;
  }

  return (
    <div
      className={`w-full py-5 ${
        !dashboard && "lg:py-[70px]"
      } ${"dark:bg-darkLightBg rounded-[16px] py-5 lg:py-10 min-h-[calc(100vh-140px)] px-5 lg:px-[30.2px]"}`}
    >
      <Joyride
        callback={handleJoyrideCallback}
        run={step.run}
        steps={step.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      <Joyride
        callback={handleJoyrideCallbackGetStart}
        run={stepGetStart.run}
        steps={stepGetStart.steps}
        continuous={true}
        scrollToFirstStep
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: "#7190FF",
          },
          tooltip: {
            position: "center",
          },
        }}
        tooltipComponent={CustomTooltipForJoyride}
      />
      {openAddModal && (
        <AddQuestionModal
          openAddModal={openAddModal}
          setOpenAddModal={setOpenAddModal}
          questionSet={selectedQuestionSetName}
          key={uuidv4()}
        />
      )}
      {isOpenAIModal && (
        <AIQuestions
          openAddModal={isOpenAIModal}
          setOpenAddModal={setIsOpenAIModal}
          key={uuidv4()}
          questionSet={selectedQuestionSet}
        />
      )}

      {isOpenAddOrEditQuestionSet && (
        <AddOrEditQuestionSet
          isOpenModal={isOpenAddOrEditQuestionSet}
          setIsOpenmodal={setIsOpenAddOrEditQuestionSet}
          data={toUpdateQuestionSet}
          setData={setToUpdateQuestionSet}
          refetchQuestionSets={refetchQuestionSets}
          key={uuidv4()}
        />
      )}

      {isOpenDeleteQuestionSet && (
        <DeleteModal
          openModal={isOpenDeleteQuestionSet}
          setOpenModal={setIsOpenDeleteQuestionSet}
          title={`Are you sure want to delete the question set "${questionSets?.find((set) => set?.id === questionSetId)?.name}"?`}
          handleDelete={handleDeleteQuestionSet}
          isLoading={deleteQuestionSetLoading}
        />
      )}

      {isOpenQuestionSettings && (
        <QuestionSettingsModal
          isOpenModal={isOpenQuestionSettings}
          setIsOpenModal={setIsOpenQuestionSettings}
        />
      )}

      {isOpenPreviewModal && (
        <QuestionPreview
          isOpenModal={isOpenPreviewModal}
          setIsOpenModal={setIsOpenPreviewModal}
          questionSetId={selectedQuestionSet}
          key={selectedQuestionSet}
        />
      )}

      <>
        {!dashboard && (
          <motion.h1
            className="md:text-center text-[#333333] text-[36px] lg:text-[68px] font-bold lg:leading-[63.2px] -tracking-[1.3px] mb-[21px] font-opensans"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, ease: "easeIn" }}
          >
            Hello!
            <br className="md:hidden" /> {companyName}
          </motion.h1>
        )}
        {!dashboard && (
          <motion.p
            className="text-[#10182899] font-montserrat md:leading-[28px] text-[15px] mb-[20px] text-center hidden md:block"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.4, ease: "easeIn" }}
          >
            Describe your existing or new business in detail, and receive{" "}
            <WordBreak /> feedback oriented questions.
          </motion.p>
        )}
      </>
      <div className="flex flex-wrap gap-5 lg:justify-between">
        <div
          className="lg:w-[20.7%] w-full rounded-[12px]  lg:sticky lg:top-[127.8px] lg:overflow-y-auto lg:max-h-[calc(100vh-127.8px)] font-pro scrollbar-none myscrollbar lg:px-1 pt-1"
          ref={questionSetRef}
          style={{
            overflowY: "auto",
          }}
        >
          <div className="pb-10">
            <button
              className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-[14px] font-normal py-[10px] w-full dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200  mb-[15px] add-button flex items-center justify-center gap-2 px-5"
              onClick={() => handleAddModal()}
            >
              <SquarePlus className="w-5 h-5" /> Add question
            </button>
            <button
              className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-[14px] font-normal  gap-2 py-[10px] w-full dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200  mb-[15px] flex items-center justify-center px-5"
              onClick={() => setIsOpenAIModal(true)}
            >
              <Sparkles className="w-5 h-5" />
              <p> AI Question Generator</p>
            </button>
            <button
              className={` w-full flex items-center justify-center px-6 text-[14px] font-normal text-center py-[10px] rounded-[8px] border border-newPrimary  ${selectedQuestionSet === null ? "bg-newPrimary text-white " : "bg-white dark:bg-darkBg dark:text-darkGray text-newPrimary"} mb-[30px]`}
              onClick={() => {
                // setIsOpenQuestionSetBox(false);
                setSelectedQuestionSet(null);
              }}
            >
              Question manager
            </button>

            <div className="text-base h-full w-full bg-white dark:bg-darkBg py-5 px-3 rounded-[12px] border border-[#EBEBEB] dark:border-none">
              <p className="text-[#525252] dark:text-darkGray text-center w-full pb-3 lg:pb-5 border-b font-medium text-base question-set">
                Question set
              </p>
              <div className="w-full py-3">
                {questionSets
                  ?.filter((item) => item?.is_default)
                  ?.map((item) => (
                    <div
                      key={item?.id}
                      className={`flex items-center justify-between group px-[18px] py-[6px] rounded-[7px] ${selectedQuestionSet === item?.id && "bg-[#F6F6FF] dark:bg-darkLightBg text-secondary"}`}
                    >
                      <p
                        className="w-full cursor-pointer group-hover:text-secondary text-[15px] font-normal"
                        onClick={() => {
                          setSelectedQuestionSet(item?.id);
                        }}
                      >
                        {item?.name === "default" ? "Default" : item?.name}
                      </p>
                      <div className="flex items-center">
                        <IconButton>
                          <Pencil
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                              setIsOpenAddOrEditQuestionSet(true);
                              setToUpdateQuestionSet(item);
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
                {questionSets
                  ?.filter((item) => !item?.is_default)
                  ?.map((item) => (
                    <div
                      key={item?.id}
                      className={`flex items-center justify-between group px-[18px] py-[6px] rounded-[7px] ${selectedQuestionSet === item?.id && "bg-lightpurple dark:bg-darkLightBg text-secondary"}`}
                    >
                      <p
                        className="w-full cursor-pointer group-hover:text-secondary capitalize text-[15px] font-normal"
                        onClick={() => {
                          setSelectedQuestionSet(item?.id);
                        }}
                      >
                        {item?.name}
                      </p>
                      <div className="flex items-center">
                        <IconButton>
                          <Pencil
                            className="w-4 h-4 cursor-pointer"
                            onClick={() => {
                              setIsOpenAddOrEditQuestionSet(true);
                              setToUpdateQuestionSet(item);
                            }}
                          />
                        </IconButton>
                        <IconButton>
                          <Trash2
                            className="w-4 h-4 cursor-pointer text-red-500"
                            onClick={() => {
                              setIsOpenDeleteQuestionSet(true);
                              setQuestionSetId(item?.id);
                            }}
                          />
                        </IconButton>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            <div>
              <input
                type="text"
                value={questionSetName}
                onChange={(e) => setQuestionSetName(e.target.value)}
                className="rounded-[7px] border py-2 w-full border-[#ebebeb] dark:border-darkGray text-sm mt-5 h-[45px] placeholder:text-[#a6a6a6] dark:bg-darkBg dark:border-none"
                placeholder="Create new set"
              />
              <button
                className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-sm text-center  w-full hover:bg-white dark:hover:bg-darkBg hover:text-newPrimary transition-all duration-200 py-2 mt-5"
                onClick={handleAddQuestionSet}
                disabled={addQuestionSetLoading}
              >
                {/* {addQuestionSetLoading ? <LoadingButton /> : "Add"} */}
                Add
              </button>
            </div>
            <div
              className="flex items-center justify-between text-newPrimary mt-5 group cursor-pointer question-settings"
              onClick={() => setIsOpenQuestionSettings(true)}
            >
              <div className="flex items-center gap-x-3">
                <Settings />
                <p className="font-pro group-hover:underline text-base">
                  Question Settings
                </p>
              </div>
              <ChevronRight />
            </div>
          </div>
        </div>
        <div
          className="lg:w-[76.5%] w-full rounded-[12px]  lg:sticky lg:top-[127.8px] lg:overflow-y-auto lg:max-h-[calc(100vh-127.8px)] font-pro scrollbar-none myscrollbar lg:px-1 pt-1"
          style={{
            overflowY: "auto",
          }}
        >
          <div className="relative flex w-full gap-2 lg:col-span-2 mb-[15px] p-0">
            <input
              type="text"
              className="px-6 py-[9px] rounded-[8px] w-full border border-[#CCD7FF] dark:border-[#404040] dark:bg-darkBg placeholder:text-newPrimary dark:placeholder:text-[#a6a6a6] text-newPrimary  bg-lightpurple pl-12 font-pro text-sm font-normal"
              placeholder="Search"
              value={searchQuestion}
              onChange={(e) => setSearchQuestion(e.target.value)}
            />
            {/* <button
             className="rounded-full text-white bg-newPrimary border border-newPrimary text-base text-center px-6 py-[11px]  dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200"
            onClick={() => setIsOpenPreviewModal(true)}

            >Preview</button> */}
            <Search className="absolute top-1/2 -translate-y-1/2 left-5 w-5 h-5 text-newPrimary dark:text-[#a6a6a6] dark:border-none" />
          </div>
          {!searchQuestion && (
            <>
              <div
                className={`flex items-center w-full gap-3 bg-white dark:bg-darkBg px-5 py-[4.5px] rounded-[8px] overflow-x-auto scrollbar-none relative set font-pro text-[#525252] mb-5`}
              >
                {[
                  {
                    value: null,
                    name: "All",
                  },
                  {
                    value: "category",
                    name: "Categories",
                  },
                  {
                    value: "keyword",
                    name: "Keywords",
                  },
                  {
                    value: "question",
                    name: "Only questions",
                  },
                ].map((item) => (
                  <>
                    <button
                      // bg-[#F0F5FF]
                      key={item.value}
                      className={`px-3 py-2 rounded-full flex-shrink-0 text-[#525252] dark:text-darkGray ${filter === item?.value ? "bg-[#f0f5ff] dark:bg-darkLightBg text-newPrimary dark:text-newPrimary font-medium " : ""} text-[15px]
                      ${item.value === "keyword" && "keywords"}
                      ${item.value === "category" && "categories"}
                          `}
                      onClick={() => setFilter(item.value)}
                    >
                      {item.name}
                    </button>
                    {item.value !== "question" && (
                      <div className="border-l-2 h-8"></div>
                    )}
                  </>
                ))}
              </div>
            </>
          )}

          {isQuestionsLoading ||
          isQuestionsFromSetApiFetching ||
          isQuestionsFromNormalApiFetching ||
          (isSearchQuestionLoading && searchQuestion) ? (
            <Loading height={"50vh"} />
          ) : (
            <div className=" w-full">
              {
                <>
                  {searchQuestion || !selectedQuestionSet ? (
                    <>
                      {filteredQuestions?.length > 0 ? (
                        <>
                          <div className="flex lg:justify-between items-center flex-wrap gap-5 w-full mb-5">
                            {dashboard && selectedQuestionSet && (
                              <CustomDropdown
                                value={sort}
                                setValue={setSort}
                                values={[
                                  {
                                    value: "recent",
                                    name: "Recently Added",
                                  },
                                  {
                                    value: "enabled",
                                    name: "Enabled",
                                  },
                                ]}
                                isToggle={false}
                                isSort={true}
                              />
                            )}
                            {searchQuestion ? null : (
                              <>
                                {filter === "category" ? (
                                  <CustomDropdown
                                    value={category}
                                    setValue={setCategory}
                                    values={
                                      questionsFromApi?.tabs &&
                                      questionsFromApi?.tabs["category"]
                                    }
                                    isToggle={true}
                                    setId={selectedQuestionSet}
                                  />
                                ) : filter === "keyword" ? (
                                  <>
                                    <CustomDropdown
                                      value={category}
                                      setValue={setCategory}
                                      values={
                                        questionsFromApi?.tabs &&
                                        questionsFromApi?.tabs["keyword"]
                                      }
                                      isToggle={false}
                                    />
                                  </>
                                ) : null}
                              </>
                            )}
                          </div>
                          <div
                            className="space-y-5"
                            // values={questions}
                            // onReorder={setQuestions}
                          >
                            {filteredQuestions?.map((item, questionIndex) => (
                              <QuestionCard
                                key={item?.id}
                                item={item}
                                questionIndex={questionIndex}
                                handleToggleQuestion={handleToggleQuestion}
                              />
                            ))}
                          </div>
                        </>
                      ) : (
                        <div className="flex flex-col items-center justify-center w-full h-[43vh] bg-white rounded-lg font-pro">
                          <img
                            src="/empty/no-question.svg"
                            alt=""
                            className="w-[153px] h-[118px]"
                          />
                          <p className="text-[13px] font-normal text-[#3d3d3d] dark:text-white mt-5">
                            No questions in the list!
                          </p>
                          <p className="text-[13px] font-normal  text-[#888] dark:text-white text-center mt-3">
                            Click on “Add questions” to add questions to your
                            list
                          </p>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      {filteredQuestionsForSets?.length > 0 ? (
                        <>
                          <div className="flex lg:justify-between items-center flex-wrap gap-5 w-full mb-8">
                            {dashboard && selectedQuestionSet && (
                              <CustomDropdown
                                value={sort}
                                setValue={setSort}
                                values={[
                                  {
                                    value: "recent",
                                    name: "Recently Added",
                                  },
                                  {
                                    value: "enabled",
                                    name: "Enabled",
                                  },
                                ]}
                                isToggle={false}
                                isSort={true}
                              />
                            )}
                            {searchQuestion ? null : (
                              <>
                                {filter === "category" ? (
                                  <CustomDropdown
                                    value={category}
                                    setValue={setCategory}
                                    values={
                                      questionsFromApi?.tabs &&
                                      questionsFromApi?.tabs["category"]
                                    }
                                    isToggle={true}
                                    setId={selectedQuestionSet}
                                  />
                                ) : filter === "keyword" ? (
                                  <>
                                    <CustomDropdown
                                      value={category}
                                      setValue={setCategory}
                                      values={
                                        questionsFromApi?.tabs &&
                                        questionsFromApi?.tabs["keyword"]
                                      }
                                      isToggle={false}
                                    />
                                  </>
                                ) : (
                                  <button
                                    className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-sm font-medium text-center hover:bg-white dark:hover:bg-darkBg hover:text-newPrimary transition-all duration-200 px-5 py-2 font-pro flex items-center gap-2"
                                    onClick={() => setIsOpenPreviewModal(true)}
                                  >
                                    <Play className="w-4 h-4" />
                                    Preview
                                  </button>
                                )}
                              </>
                            )}
                          </div>
                          <div
                            className="space-y-5"
                            // values={questions}
                            // onReorder={setQuestions}
                          >
                            {filteredQuestionsForSets?.map(
                              (item, questionIndex) => (
                                <QuestionCardForQuestionSet
                                  key={item?.quesiton?.id}
                                  item={item}
                                  questionIndex={questionIndex}
                                  handleToggleQuestion={handleToggleQuestion}
                                />
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <QuestionsIllustrations />
                      )}
                    </>
                  )}
                </>
              }
              {/* {(filteredQuestions?.length <= 0 ||
                filteredQuestionsForSets?.length <= 0) && (
                <div className="flex flex-col items-center justify-center w-full h-[43vh] bg-white dark:bg-darkLightBg rounded-lg font-pro">
                  <img
                    src="/empty/no-question.svg"
                    alt=""
                    className="w-[153px] h-[118px]"
                  />
                  <p className="font-medium text-[#3d3d3d] mt-5">
                    No Questions in the list!
                  </p>
                  <p className="text-sm  text-[#888] text-center mt-3">
                    Click on “Add Question” Button to get started <br /> on
                    adding your questions to the list
                  </p>
                </div>
              )} */}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Questions;

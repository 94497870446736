import { Link } from "react-router-dom";
import { useDarkMode } from "../../../../context/DarkModeProvider";
import { ChevronRight } from "lucide-react";
import Skeleton from "react-loading-skeleton";
import { useGetEntityReviews } from "../../../../query/useReview";
import { dayFormatter } from "../../../../utils/formatter";
import { useEffect, useState } from "react";
import DashboardReviewCard from "./DashboardReviewCard";
import { DashboardReviewsIllustrations } from "../../../onboarding/components/illustrations/Reviews";

const RecentReviews = () => {
  const { darkMode } = useDarkMode();
  const [selectedReview, setSelectedReview] = useState(null);
  const entityId =
    localStorage.getItem("entityUuid") && localStorage.getItem("entityUuid");

  const { data: reviews, isLoading: isReviewsLoading } = useGetEntityReviews({
    entityId: entityId,
    limit: 5,
  });

  const flattenedReviews = reviews?.pages?.flatMap((page) => page?.results);

  useEffect(() => {
    if (flattenedReviews) {
      setSelectedReview(flattenedReviews?.[0]);
    }
  }, [reviews]);

  return (
    <div className="grid grid-cols-1 lg:grid-cols-12 gap-5">
      <div className="lg:col-span-5">
        <div className="flex flex-col w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm">
          <header className="flex justify-between items-center">
            {isReviewsLoading ? (
              <Skeleton
                containerClassName="flex-1"
                baseColor={darkMode && "#262626"}
              />
            ) : (
              <>
                <div className="font-pro">
                  <p className="text-lg font-semibold">Recent reviews</p>
                </div>
                <Link
                  to="/dashboard/reviews"
                  className="w-fit h-10 bg-[#f6f6ff] dark:bg-darkLightBg rounded-lg flex justify-center items-center px-5 text-[#615e83] dark:text-secondary font-pro text-sm gap-x-2"
                >
                  <p className="text-[10px] font-medium">View all</p>

                  <ChevronRight className="w-3 h-3" />
                </Link>
              </>
            )}
          </header>

          {!isReviewsLoading && flattenedReviews?.length <= 0 ? (
            <DashboardReviewsIllustrations />
          ) : (
            <>
              {isReviewsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  baseColor={darkMode && "#262626"}
                  height={200}
                />
              ) : (
                <div className="divide-y mt-2 h-[200px] overflow-y-auto scrollbar-thin">
                  {flattenedReviews?.map((item) => (
                    <div
                      key={item?.time}
                      className={`flex justify-between items-center py-2 px-2 cursor-pointer ${selectedReview?.time === item?.time && "bg-accent"}`}
                      onClick={() => setSelectedReview(item)}
                    >
                      <div>
                        <div className="flex gap-4 items-center">
                          <img
                            src={item?.profile_photo_url || "/new-logo.svg"}
                            alt=""
                            className="w-[30px] h-[30px]"
                            referrerPolicy="no-referrer"
                          />
                          <div>
                            <h1 className="font-pro text-base font-medium mb-1">
                              {item?.author_name || "MagicReview"}
                            </h1>
                            <p className="text-[#777] text-[13px] font-pro ">
                              {item?.time && dayFormatter(item?.time)}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="flex gap-[3px]">
                        <img
                          src="/yellowstar.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                        <img
                          src="/yellowstar.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                        <img
                          src="/yellowstar.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                        <img
                          src="/yellowstar.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                        <img
                          src="/yellowstar.svg"
                          alt=""
                          className="w-[24px] h-[24px]"
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="lg:col-span-7  w-full gap-5 font-pro font-medium">
        <div className="flex flex-col w-full p-6 rounded-[12px] bg-white dark:bg-darkBg h-full shadow-sm">
          {!isReviewsLoading && flattenedReviews?.length <= 0 ? (
            <DashboardReviewsIllustrations />
          ) : (
            <>
              {isReviewsLoading ? (
                <Skeleton
                  containerClassName="flex-1"
                  baseColor={darkMode && "#262626"}
                  height={200}
                />
              ) : (
                <DashboardReviewCard
                  review={selectedReview}
                  key={selectedReview?.id}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default RecentReviews;

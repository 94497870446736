import { useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "sonner";
import PasswordInput from "../../components/input/passwordinput";
import {
  useGetIndustryTypes,
  useGoogleSignIn,
  useSignUp,
} from "../../query/useAuthentication";
import { useGetEntitySuggestions } from "../../query/useEntity";
import { emailRegex } from "../contact/contact";
import useDebounce from "../../hooks/useDebounce";
import { useSearchParams } from "react-router-dom";
import { LoadingButton, LogoLoading } from "../../components/loading/loading";
import { Asterisk, ChevronDown } from "lucide-react";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import TagsInput from "react-tagsinput";
import { GoogleLogin } from "@react-oauth/google";
export const passwordRegex =
  /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;

const SignUp = () => {
  const industryRef = useRef(null);
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [openSelectIndustry, setOpenSelectIndustry] = useState(false);

  const { mutate: signup, isLoading: isSignUpLoading } = useSignUp();
  const { mutate: googleLogin, isLoading: googleLoginLoading } =
    useGoogleSignIn();

  const handleSubmit = async () => {
    if (!emailRegex.test(email)) {
      toast.error("Invalid email");
      return;
    }

    if (!passwordRegex.test(password)) {
      setIsPasswordValid(false);
      toast.error("Invalid password");
      return;
    } else {
      setIsPasswordValid(true);
    }

    if (password !== confirmPassword) {
      toast.error("Password does not match");
      return;
    }

    let data = {
      email: email,
      password: password,
    };

    signup(data, {
      onSuccess: (data) => {
        toast.success(data.message);
        if (data.verificiation_id) {
          toast.success(data.message);
          sessionStorage.setItem("verificationId", data.verificiation_id);
          navigate(`/verify-email?profileId=${data.profile_uuid}`);
        } else {
          toast.success(data.message);
          navigate(`/register-entity?profileId=${data.profile_uuid}`);
        }
        // sessionStorage.setItem("verificationId", data.verificiation_id);
        // navigate(`/verify-email?profileId=${data.profile_uuid}`, {
        //   state: {
        //     email: email,
        //   },
        // });
      },
      onError: (error) => {
        if (error?.response?.data?.entity?.google_review_link) {
          toast.error(
            error.response.data.entity.google_review_link[0].split(".")[0] +
              " for google review link."
          );
        } else if (error?.response?.data?.entity?.company_link) {
          toast.error(
            error.response.data.entity.company_link[0].split(".")[0] +
              " for website link."
          );
        } else if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(
            `Something went wrong, try again later ${error.response.status}`
          );
        }
      },
    });
  };

  const handleGoogleLogin = (res) => {
    googleLogin(
      {
        id_token: res.credential,
      },
      {
        onSuccess: (res) => {
          if (res.token) {
            localStorage.setItem("token", res.token);
            toast.success("Logged in successfully");
            navigate("/dashboard");
          } else if (res.profile_uuid) {
            toast.success(res.message);
            navigate(`/register-entity?profileId=${res.profile_uuid}`);
            sessionStorage.setItem(
              "profileExtraFields",
              JSON.stringify(res.extra_fields)
            );
          } else if (res.status === "error") {
            toast.error(res.message);
          }
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useOnClickOutside({ ref: industryRef, setIsOpen: setOpenSelectIndustry });

  if (isSignUpLoading || googleLoginLoading) {
    return <LogoLoading />;
  }

  return (
    <div className="w-full min-h-screen container flex lg:items-center lg:justify-center  lg:bg-[#F7F7F7]">
      <Helmet>
        <link rel="canonical" href="https://www.magicreview.ai/sign-up" />
      </Helmet>
      <div className="w-full lg:max-w-lg flex flex-col md:justify-center md:items-center bg-white rounded-[20px] p-5 lg:p-10 font-poppins ">
        <div className="flex flex-col w-full  mb-5">
          <Link to="/">
            <img
              src="/new-logo-with-sidetext.svg"
              alt="logo"
              className=" w-auto mx-auto"
            />
          </Link>
          <h1 className="text-[22px] leading-8 md:-tracking-[0.5px] font-semibold text-[#4a4a4a] mt-[20px] ">
            Create your account
          </h1>
          <p className="text-[#4a4a4a] text-sm mt-1">
            Enter your details below to create a new account
          </p>
          <div className="flex justify-center mt-7">
            <GoogleLogin
              onSuccess={(res) => handleGoogleLogin(res)}
              onError={(err) => console.log(err)}
              size="large"
              shape="pill"
              width={300}
              text="continue_with"
            />
          </div>
          <div className="flex items-center gap-3 my-5">
            <div className="w-full h-[1px] bg-[#ebebeb]"></div>
            <div className="text-sm text-[#999] flex-shrink-0">or</div>
            <div className="w-full h-[1px] bg-[#ebebeb]"></div>
          </div>
          <form className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <label
                htmlFor="email"
                className=" text-[#757575] font-medium flex items-center gap-1"
              >
                Email <Asterisk className="size-3.5 text-red-500" />
              </label>
              <input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                id="email"
                placeholder="e.g. mail@domain.com"
                className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans placeholder:text-[#81818F] h-[45px] w-full text-sm text-black"
              />
            </div>
            <PasswordInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label={"New Password"}
              isPasswordValid={isPasswordValid}
            />
            <PasswordInput
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label={"Confirm Password"}
            />

            <button
              type="submit"
              onClick={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              disabled={
                isSignUpLoading ||
                !email.trim() ||
                !password.trim() ||
                !confirmPassword.trim()
              }
              className="bg-newPrimary border border-newPrimary  text-white py-2.5 rounded-full mt-3 transition-all duration-300  font-montserrat text-base font-medium active:scale-95"
            >
              Create Account
            </button>
          </form>
        </div>

        <p className="text-gray-600 text-center text-sm">
          Already have an account?{" "}
          <Link to="/sign-in" className="text-[#2a49b8] font-semibold">
            Sign in
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignUp;

import Mainnav from "../../components/mainNavbar/MainNav";
import Footer from "../../components/footer/footer";
import { useState, useRef, useEffect } from "react";
import { scrollToSection } from "../../components/navigate";
import { Helmet } from "react-helmet";
const Privacy = () => {
  const introductionRef = useRef(null);
  const informationRef = useRef(null);
  const useInfoRef = useRef(null);
  const sharingRef = useRef(null);
  const choicesRef = useRef(null);
  const complianceRef = useRef(null);
  const changesRef = useRef(null);
  const contactRef = useRef(null);
  const [activeSection, setActiveSection] = useState(null);

  const isElementInView = (el) => {
    const rect = el.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };
  const handleScroll = () => {
    const sections = [
      introductionRef.current,
      informationRef.current,
      useInfoRef.current,
      sharingRef.current,
      choicesRef.current,
      complianceRef.current,
      changesRef.current,
      contactRef.current,
    ];
    for (let i = 0; i < sections.length; i++) {
      if (sections[i] && isElementInView(sections[i])) {
        setActiveSection(i + 1); // Set the active section (1-based index)
        break;
      }
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Helmet>
        <link rel="canonical" href="https://www.magicreview.ai/privacy" />
      </Helmet>
      <div className="w-full h-full">
        <Mainnav />

        <div className="w-full container">
          <div className="md:sticky md:top-[88px] md:bg-white md:z-10 md:pt-20 md:pb-7">
            <h3 className="text-center text-newPrimary font-opensans text-base font-bold">
              Privacy & Policy
            </h3>
            <h1 className="text-[#333] text-center text-2xl md:text-5xl font-bold mt-4 font-opensans md:leading-[63.2px] md:-tracking-[1.296px]">
              How magicreview handles your data
            </h1>
            <p className="mt-4 text-[#10182899] text-center font-montserrat font-medium text-base">
              Updated April 03, 2023
            </p>
          </div>

          <div className="w-full flex mt-[100px]">
            {/* side menu */}
            <div className="w-[30%] sticky top-1/2 h-full max-h-[418px] hidden md:flex">
              <div className="w-full md:flex flex-col pb-10">
                <button
                  onClick={() => scrollToSection(introductionRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 1
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Introduction
                </button>
                <button
                  onClick={() => scrollToSection(informationRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 2
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Information We Collect
                </button>
                <button
                  onClick={() => scrollToSection(useInfoRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 3
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  How We Use Your Information
                </button>
                <button
                  onClick={() => scrollToSection(sharingRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 4
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Sharing Your Information
                </button>
                <button
                  onClick={() => scrollToSection(choicesRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 5
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Your Choices
                </button>
                <button
                  onClick={() => scrollToSection(complianceRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 6
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Compliance with Laws
                </button>
                <button
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                  ${
                    activeSection === 7
                      ? "border-[#333333] text-[#1b1b1b]"
                      : "border-[#E1E1E1] text-[#7E7E7E]"
                  } hover:border-[#333333] transition-all duration-500 ease-in-out 
                 `}
                >
                  Changes to this Privacy Policy
                </button>
                <button
                  onClick={() => scrollToSection(contactRef)}
                  className={`font-opensans  font-medium text-base py-2 border-l-2 pl-10 text-left 
                   ${
                     activeSection === 8
                       ? "border-[#333333] text-[#1b1b1b]"
                       : "border-[#E1E1E1] text-[#7E7E7E]"
                   } hover:border-[#333333] transition-all duration-500 ease-in-out 
                  `}
                >
                  Contact Us
                </button>
              </div>
            </div>

            <div className="w-full md:w-[70%] flex flex-col">
              <div
                id="1"
                className="w-full md:w-[91%] mb-[60px]"
                ref={introductionRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Introduction
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  At magicreview, your privacy is our top priority. We are
                  dedicated to safeguarding the privacy and security of your
                  personal information. This Privacy Policy is designed to
                  provide you with comprehensive insights into how we collect,
                  utilize, and fortify the data you entrust to us when utilizing
                  our cutting-edge review collection card and advanced analytics
                  platform. Your trust is of utmost importance to us, and we are
                  committed to ensuring the confidentiality, integrity, and
                  responsible handling of your information throughout your
                  interaction with our services. Please take the time to
                  carefully review this policy to better understand how your
                  data is managed and protected by magicreview.
                </p>
              </div>
              <div
                id="2"
                className="w-full md:w-[91%] mb-[60px]"
                ref={informationRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Information We Collect
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Personal Information:
                  </span>{" "}
                  We may collect personally identifiable information, including
                  but not limited to your name, email address, phone number, and
                  contact information when you interact with our platform.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Interaction Data:{" "}
                  </span>
                  When you use magicreview, we may gather data related to your
                  interactions, such as reviews, feedback, and interactions with
                  our digital card. This data may include customer comments,
                  ratings, and the content of your reviews.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Usage Information:{" "}
                  </span>
                  We collect usage information about your interaction with our
                  platform, including but not limited to IP addresses, browser
                  information, device type, and geographic location data
                </p>
              </div>
              <div
                id="3"
                className="w-full md:w-[91%] mb-[60px]"
                ref={useInfoRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  How We Use Your Information
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Providing and Improving Services:
                  </span>{" "}
                  : We use your information to provide and enhance our services.
                  This includes personalizing review suggestions based on your
                  interactions to improve the quality and relevance of feedback.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Analytics:
                  </span>
                  We analyze user behavior and data through our analytics
                  dashboard to gain insights into review trends, sentiment
                  analysis, customer demographics, review distribution, and
                  response rates. These insights help us and our clients better
                  understand and improve customer experiences
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Communication:
                  </span>
                  We may use your contact information to communicate with you
                  about our services, updates, promotions, and important
                  announcements. You can opt-out of promotional communications
                  at any time.
                </p>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  <span className="text-[#101828E5] font-semibold">
                    Security:{" "}
                  </span>
                  We take data security seriously and employ industry-standard
                  security measures, including encryption, access controls, and
                  regular security assessments, to protect your information and
                  our platform.
                </p>
              </div>
              <div
                id="4"
                className="w-full md:w-[91%] mb-[60px]"
                ref={sharingRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Sharing Your Information
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  We do not sell your personal information to third parties.
                  However, we may share your information with trusted service
                  providers, partners, or affiliates who assist us in delivering
                  our services. These entities are bound by confidentiality
                  agreements and are only authorized to use your data for
                  specific purposes related to our services.
                </p>
              </div>
              <div
                id="5"
                className="w-full md:w-[91%] mb-[60px]"
                ref={choicesRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Your Choices
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7 mb-10">
                  You have choices regarding your personal information:
                </p>
                <ul className="list-disc list-inside text-[#10182899] font-montserrat text-base leading-7">
                  <li>
                    You can opt-out of receiving promotional communications from
                    us.
                  </li>
                  <li>
                    You can access, correct, or delete your personal information
                    by contacting us at [contact email]
                  </li>
                  <li>
                    You can choose not to provide certain information, but this
                    may limit your ability to use certain features of our
                    platform.
                  </li>
                </ul>
              </div>
              <div
                id="6"
                className="w-full md:w-[91%] mb-[60px]"
                ref={complianceRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Compliance with Laws
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  We adhere to applicable data protection laws, including the
                  General Data Protection Regulation (GDPR), the California
                  Consumer Privacy Act (CCPA), and other relevant privacy laws
                  and regulations.
                </p>
              </div>
              <div
                id="7"
                className="w-full md:w-[91%] mb-[60px]"
                ref={changesRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Changes to this Privacy Policy
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  We may update this Privacy Policy to reflect changes in our
                  practices, services, or legal requirements. We will notify you
                  of any material changes through prominent notices on our
                  website or via email.
                </p>
              </div>
              <div
                id="8"
                className="w-full md:w-[91%] mb-[60px]"
                ref={contactRef}
              >
                <h1 className="font-opensans text-[#3B3B3B] text-lg md:text-2xl mb-[50px] md:-tracking-[0.54px]">
                  Contact Us
                </h1>
                <p className="text-[#10182899] font-montserrat text-base leading-7">
                  If you have questions or concerns about this Privacy Policy or
                  our data practices, please contact us at magicreview@gmail.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;

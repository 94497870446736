/* eslint-disable react/prop-types */
import { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import { Transition } from "@headlessui/react";
import { Asterisk } from "lucide-react";

const PasswordInput = ({
  label,
  value,
  onChange,
  isPasswordValid = true,
  loginPage = false,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  return (
    <div className="flex flex-col gap-1 w-full">
      <div className="flex justify-between">
        <label
          htmlFor="password"
          className=" text-[#757575] font-medium flex items-center gap-1"
        >
          {label} {!loginPage && <Asterisk className="size-3.5 text-red-500" />}
        </label>
      </div>
      <Transition
        show={true}
        enter="transition-opacity duration-75"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-150"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="relative">
          <input
            type={showPassword ? "text" : "password"}
            value={value}
            onChange={onChange}
            id="password"
            placeholder="************"
            className="border border-[#ebebeb] px-3 py-2 rounded-lg focus:outline-none focus:ring-2 focus:[#4F46BA] focus:border-transparent opensans placeholder:text-[#81818F] h-[45px] w-full text-black text-sm"
          />

          <div className="absolute inset-y-0 right-4 pl-3 flex items-center z-50">
            <button
              type="button"
              onClick={togglePasswordVisibility}
              className="text-gray-500 focus:outline-none"
            >
              {showPassword ? (
                <EyeSlashIcon className="h-5 w-5" />
              ) : (
                <EyeIcon className="h-5 w-5" />
              )}
            </button>
          </div>
        </div>

        {!isPasswordValid && (
          <p className="text-red-500 text-sm mt-1">
            Password must contain at least one uppercase letter, one lowercase
            letter, one number, and be at least 8 characters long.
          </p>
        )}
      </Transition>
    </div>
  );
};

export default PasswordInput;

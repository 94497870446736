import { useState, useEffect } from "react";

const CountdownProgressBar = () => {
  const [progress, setProgress] = useState(100);
  const duration = 4;

  useEffect(() => {
    if (progress > 0) {
      const interval = setInterval(() => {
        setProgress((prevProgress) => prevProgress - 100 / (duration * 10));
      }, 100);
      return () => clearInterval(interval);
    }
  }, [progress]);

  return (
    <div className="w-full rounded bg-slate-300">
      <div
        className="h-[3px] bg-secondary"
        style={{
          width: `${100 - progress}%`,
          transition: "width 0.1s linear",
        }}
      />
    </div>
  );
};

export default CountdownProgressBar;

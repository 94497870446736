/* eslint-disable react/prop-types */
import { useNavigate } from "react-router";
import { Button } from "../../../../components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from "../../../../components/ui/dialog";

const GmbIntegrationModal = ({ openModal, setOpenModal }) => {
  const navigate = useNavigate();
  return (
    <Dialog
      open={openModal}
      onOpenChange={() => {
        setOpenModal(!openModal);
        navigate("/dashboard");
      }}
    >
      <DialogContent className="">
        <DialogHeader>
          <DialogTitle>Link</DialogTitle>
        </DialogHeader>
        <div className="px-6 font-pro">
          <p>You need to link your Google My Business account.</p>
          <div className="flex items-center justify-end gap-3 mt-5">
            <Button
              onClick={() => {
                navigate("/dashboard/settings/integrations?openGmb=true");
              }}
            >
              Link
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setOpenModal(false);
                navigate("/dashboard");
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default GmbIntegrationModal;

import React, { useEffect, useState } from "react";
import Layout from "../component/Layout";
import { useInView } from "react-intersection-observer";
import {
  useDeletePositivePrompt,
  useGetEntityReviews,
  useGetPositivePrompt,
  usePostPositivePrompt,
  usePutPositivePrompt,
} from "../../../query/useReview";
import Skeleton from "react-loading-skeleton";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { LoaderIcon, Sparkles, SquarePlus } from "lucide-react";
import { ReviewsIllustration } from "../../onboarding/components/illustrations/Reviews";
import { useDarkMode } from "../../../context/DarkModeProvider";
import ReviewCardAutoReply from "./components/ReviewCardAutoReply";
import {
  useDeleteGmbReviewReply,
  useGetAutoReplyToggle,
  useGetGmbInfo,
  useGetGmbReviews,
  useGetGmbReviewsInfinity,
  usePostAutoReplyToggle,
  usePostGenerateAiReply,
  usePutEditGmbReviewReply,
} from "../../../query/useAuthentication";
import {
  dayFormatter,
  dayFormatterLong,
  timeFormatter,
} from "../../../utils/formatter";
import { useQueryClient } from "@tanstack/react-query";
import { Textarea } from "../../../components/ui/textarea";
import { Button } from "../../../components/ui/button";
import { toast } from "sonner";
import Loading, { LogoLoading } from "../../../components/loading/loading";
import { DeleteModal } from "../component/modal";
import { useSearchParams } from "react-router-dom";
import { Input } from "../../../components/ui/input";
import ConditionCard from "./components/ConditionCard";
import Google from "../../../assets/reviewservices/google.svg";
import AddConditionModal from "./components/AddConditionModal";
import GmbIntegrationModal from "./components/GmbIntegrationModal";
import { useRef } from "react";

const wordsToNumber = {
  ZERO: 0,
  ONE: 1,
  TWO: 2,
  THREE: 3,
  FOUR: 4,
  FIVE: 5,
};

const AutoReply = () => {
  const { inView, ref } = useInView();
  const { darkMode } = useDarkMode();
  const [params] = useSearchParams();
  const reviewId = params.get("reviewId");

  const queryClient = useQueryClient();
  const [filter, setFilter] = useState(
    sessionStorage.getItem("aiFilter") === "false" ? false : true
  );
  const [reviewReply, setReviewReply] = useState("");
  const [originalReviewReply, setOriginalReviewReply] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
  const [tab, setTab] = useState("ai");
  const [isOpenCondition, setIsOpenCondition] = useState(false);
  const [isOpenLinkModal, setIsOpenLinkModal] = useState(false);
  const [resRequired, setResRequired] = useState(false);

  const { data: gmbInfo } = useGetGmbInfo();

  const { mutate: editReviewReply, isLoading: editReviewReplyLoading } =
    usePutEditGmbReviewReply();

  const { mutate: deleteReviewReply, isLoading: deleteReviewReplyLoading } =
    useDeleteGmbReviewReply();

  const { refetch: autoReplyRefetch, isLoading: autoReplyLoading } =
    useGetAutoReplyToggle({
      onSuccess: (res) => {
        setFilter(res.auto_reply);
        sessionStorage.setItem("aiFilter", res.auto_reply);
      },
    });

  const { mutate: autoReplyToggle, isLoading: autoReplyToggleLoading } =
    usePostAutoReplyToggle();

  const { mutate: aiReply, isLoading: aiReplyLoading } =
    usePostGenerateAiReply();

  const handleAutoReplyToggle = () => {
    autoReplyToggle(
      {},
      {
        onSuccess: (res) => {
          toast.success(res.message);
          autoReplyRefetch();
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleEditReviewReply = () => {
    editReviewReply(
      {
        review_id: selectedReview?.review_id,
        comment: reviewReply,
      },
      {
        onSuccess: (res) => {
          toast.success("Your reply has been posted");
          queryClient.invalidateQueries(["getGmbReviewsInfinity"]);
          setIsEditable(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleDeleteReviewReply = () => {
    deleteReviewReply(
      {
        review_id: selectedReview?.review_id,
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          queryClient.invalidateQueries(["getGmbReviewsInfinity"]);
          setIsOpenDeleteModal(false);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const handleAiReply = () => {
    aiReply(
      {
        review_id: selectedReview?.review_id,
      },
      {
        onSuccess: (res) => {
          setIsEditable(true);
          setReviewReply(res.ai_reply);
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  const {
    data: gmbReviews,
    hasNextPage,
    fetchNextPage,
    isLoading: gmbReviewsLoading,
  } = useGetGmbReviewsInfinity(
    { reply: resRequired },
    {
      initialData: queryClient.getQueryData(["getGmbReviewsInfinity", true]),
    }
  );
  const flattenedGmbReviews = gmbReviews?.pages?.flatMap(
    (page) => page?.results
  );

  const itemRefs = useRef({});

  // Attach refs to items dynamically
  const assignRef = (id) => (el) => {
    if (el) itemRefs.current[id] = el;
  };
  useEffect(() => {
    if (hasNextPage && inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (selectedReview) {
      setOriginalReviewReply(selectedReview?.review_reply);
      setReviewReply(selectedReview?.review_reply);
    }
  }, [selectedReview]);

  useEffect(() => {
    if (reviewId) {
      const selected = flattenedGmbReviews?.find(
        (item) => item?.review_id === reviewId
      );
      setSelectedReview(selected);
      if (selected?.review_reply) {
        setIsEditable(true);
      } else {
        setIsEditable(false);
      }
    }
    // } else {
    //   setSelectedReview(flattenedGmbReviews?.[0]);
    // }
  }, []);

  useEffect(() => {
    if (!reviewId) {
      setSelectedReview(flattenedGmbReviews?.[0]);
    }
  }, [flattenedGmbReviews?.[0]]);

  useEffect(() => {
    if (reviewId && itemRefs.current[reviewId]) {
      itemRefs.current[reviewId].scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const [title, setTitle] = useState("");
  const [condition, setCondition] = useState("");
  const [isOpenAddCondtion, setIsOpenAddCondition] = useState(false);

  const { data: conditions, refetch: refetchPositive } = useGetPositivePrompt();
  const { mutate: addPositivePrompt, isLoading: addPositivePromptLoading } =
    usePostPositivePrompt();

  const handleAddPositivePrompt = () => {
    addPositivePrompt(
      {
        title: title,
        condition: condition,
      },
      {
        onSuccess: (res) => {
          toast.success(res?.message);
          refetchPositive();
          setTitle("");
          setCondition("");
        },
        onError: (err) => {
          if (err.response.data.message) {
            toast.error(err.response.data.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  useEffect(() => {
    if (!gmbInfo?.GMB_INTEGRATION) {
      setIsOpenLinkModal(true);
    } else {
      setIsOpenLinkModal(false);
    }
  }, [gmbInfo]);

  if (aiReplyLoading || addPositivePromptLoading) {
    return <LogoLoading />;
  }

  return (
    <Layout title={"Responder Agent"}>
      <div className="w-full dark:bg-darkLightBg rounded-[16px] py-5 md:py-[31px] min-h-[calc(100vh-140px)] mt-5 my-5 px-5 lg:px-[30.2px] font-pro">
        {gmbInfo?.GMB_INTEGRATION && (
          <>
            <div className="mb-5 flex flex-col lg:flex-row gap-5 items-center justify-between">
              <div className="font-pro font-medium text-sm  gap-3 flex items-center">
                <button
                  className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg ${tab === "ai" && "border-newPrimary text-newPrimary"}`}
                  onClick={() => setTab("ai")}
                >
                  AI Responder
                </button>
                <button
                  className={`border rounded-lg py-2 px-3 bg-white dark:bg-darkLightBg ${tab === "prompt" && " border-newPrimary text-newPrimary"}`}
                  onClick={() => setTab("prompt")}
                >
                  Auto-Reply Rules
                </button>
              </div>
              <div
                className={`flex bg-white dark:bg-darkLightBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border mb-5 ${autoReplyToggleLoading && "cursor-progress"}`}
              >
                <button
                  className={` ${filter === true ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                  onClick={handleAutoReplyToggle}
                >
                  Auto Reply
                </button>
                <button
                  className={`${filter === false ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                  onClick={handleAutoReplyToggle}
                >
                  Draft Mode
                </button>
              </div>
              {/* <div className="lg:w-2/5 w-full flex gap-3 items-center">
            <button className="border-b-2 text-[#4d4d4d] text-sm font-medium text-center pb-2 flex items-center gap-2.5">
              All{" "}
              <span className="text-base font-bold">
                {gmbReviews?.pages?.[0]?.total_results}
              </span>
            </button>
            <button className="border-b-2 border-b-newPrimary text-newPrimary text-sm font-medium text-center pb-2 flex items-center gap-2.5">
              Response Required{" "}
              <span className="text-base font-bold">
                {gmbReviews?.pages?.[0]?.total_reviews_to_reply}
              </span>
            </button>
          </div> */}
              {/* <div className="lg:w-3/5 w-full flex gap-3 items-center justify-between">
            <div
              className={`flex bg-white dark:bg-darkLightBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border mb-5 ${autoReplyToggleLoading && "cursor-progress"}`}
            >
              <button
                className={` ${filter === true ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                onClick={handleAutoReplyToggle}
              >
                Auto Reply
              </button>
              <button
                className={`${filter === false ? "bg-newPrimary text-white rouned-[10px] px-5 py-2 rounded-[10px] relative z-10 " : "px-2 py-2"}`}
                onClick={handleAutoReplyToggle}
              >
                Draft Mode
              </button>
            </div>
            <button
              className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-[14px] font-normal py-[10px]  dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200  mb-[15px] flex items-center justify-center gap-2 px-5 w-fit"
              onClick={() => {}}
            >
              <SquarePlus className="w-5 h-5" /> Add condition
            </button>
          </div> */}
            </div>

            {tab === "ai" && (
              <>
                <div className="lg:w-2/5 w-full flex gap-3 items-center mb-5">
                  <button
                    className={`border-b-2  text-sm font-medium text-center pb-2 flex items-center gap-2.5 ${!resRequired ? "border-b-newPrimary text-newPrimary" : "text-[#4d4d4d]"}`}
                    onClick={() => setResRequired(false)}
                  >
                    All{" "}
                    <span className="text-base font-bold">
                      {gmbReviews?.pages?.[0]?.total_results}
                    </span>
                  </button>
                  <button
                    className={`border-b-2  text-sm font-medium text-center pb-2 flex items-center gap-2.5 ${resRequired ? "border-b-newPrimary text-newPrimary" : "text-[#4d4d4d]"}`}
                    onClick={() => setResRequired(true)}
                  >
                    Response Required{" "}
                    <span className="text-base font-bold">
                      {gmbReviews?.pages?.[0]?.total_reviews_to_reply}
                    </span>
                  </button>
                </div>
                {gmbReviewsLoading && autoReplyLoading ? (
                  <Loading height={"50vh"} />
                ) : flattenedGmbReviews?.length > 0 ? (
                  <>
                    <div className="flex flex-col lg:flex-row gap-5">
                      <div className="w-full lg:w-2/5">
                        <div className="overflow-y-auto scrollbar-thin landscape:max-h-[60vh] portrait:max-h-[40vh]">
                          <div className="flex flex-col gap-3 overflow-y-auto pr-1 ">
                            {flattenedGmbReviews?.map((item, i) => (
                              <div
                                key={item?.review_id}
                                className={`flex justify-between items-center p-5 cursor-pointer   rounded-[12px] bg-white dark:bg-darkBg  ${selectedReview?.review_id === item?.review_id ? "border border-newPrimary" : ""}`}
                                ref={assignRef(item.review_id)} // Dynamically assign refs
                                onClick={() => setSelectedReview(item)}
                              >
                                <div>
                                  <div className="flex gap-4 items-center">
                                    <img
                                      src={
                                        item?.profile_photo_url ||
                                        "/new-logo.svg"
                                      }
                                      alt=""
                                      className="w-[30px] h-[30px]"
                                      referrerPolicy="no-referrer"
                                    />
                                    <div>
                                      <h1
                                        className={`font-pro text-base font-medium mb-1 dark:text-white `}
                                      >
                                        {item?.display_name || "MagicReview"}
                                      </h1>
                                      <p
                                        className={` text-[13px] font-pro `}
                                        title={
                                          item?.update_time &&
                                          timeFormatter(item?.update_time)
                                        }
                                      >
                                        {item?.update_time &&
                                          dayFormatter(item?.update_time)}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                {/* <div className="flex gap-[3px]">
                              {[...Array(5)].map((_, index) => (
                                <svg
                                  key={index}
                                  width="20"
                                  height="20"
                                  fill="none"
                                >
                                  <path
                                    fill={
                                      index < wordsToNumber[item?.star_rating]
                                        ? "#F9C33C"
                                        : "#DDD"
                                    }
                                    d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                                  />
                                </svg>
                              ))}
                            </div> */}
                                <div className="flex items-center gap-3">
                                  <div className="flex gap-1 font-pro font-medium items-center">
                                    <img
                                      src={Google}
                                      className="flex-shrink-0 w-7"
                                    />
                                    <svg width="20" height="20" fill="none">
                                      <path
                                        fill={"#F9C33C"}
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                                      />
                                    </svg>
                                    {wordsToNumber[item?.star_rating]}/5
                                  </div>
                                  {item?.review_reply ? (
                                    <div className="text-[#34a853] font-pro font-medium text-sm bg-[#34a853]/15 px-3 py-1 rounded-[7px]">
                                      Replied
                                    </div>
                                  ) : (
                                    <div className="text-newPrimary font-pro font-medium text-sm bg-white dark:bg-darkLightBg px-4 py-1 rounded-[7px] border border-newPrimary">
                                      Reply
                                    </div>
                                  )}
                                </div>
                              </div>
                            ))}
                            {hasNextPage && (
                              <div ref={ref} className="text-center pt-3">
                                <p className="text-center text-xs text-[#888]">
                                  Loading ...
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-3/5">
                        {/* <div
                      className={`flex bg-white dark:bg-darkLightBg w-fit font-pro font-medium text-sm rounded-[10px] text-[#4d4d4d] dark:text-white border mb-5 ${autoReplyToggleLoading && "cursor-progress"}`}
                    >
                      <button
                        className={` ${filter === false ? "bg-newPrimary text-white rouned-[10px] px-5 rounded-[10px] relative z-10 " : "px-2 py-1.5"}`}
                        onClick={handleAutoReplyToggle}
                      >
                        Draft Mode
                      </button>
                      <button
                        className={`${filter === true ? "bg-newPrimary text-white rouned-[10px] px-5 rounded-[10px] relative z-10 " : "px-2 py-1.5"}`}
                        onClick={handleAutoReplyToggle}
                      >
                        Auto Reply
                      </button>
                    </div> */}
                        <div className="dark:bg-darkBg duration-500 bg-white dark:border-none rounded-lg shadow-md flex justify-center items-center cursor-pointer p-5 lg:p-9 ">
                          <div className="flex flex-col gap-y-5 bg-white dark:bg-darkBg w-full">
                            <div className="flex flex-col gap-[35px]">
                              <div className="flex items-center justify-between flex-wrap gap-5">
                                <div className="flex gap-4 items-center">
                                  <img
                                    src={
                                      selectedReview?.profile_photo_url ||
                                      "/new-logo.svg"
                                    }
                                    alt=""
                                    className="w-[50px] h-[50px]"
                                    referrerPolicy="no-referrer"
                                  />
                                  <div>
                                    <h1 className="font-pro text-base font-medium mb-1">
                                      {selectedReview?.display_name ||
                                        "MagicReview"}
                                    </h1>
                                    <p className="text-[#777] text-[13px] font-pro ">
                                      {selectedReview?.update_time &&
                                        dayFormatterLong(
                                          selectedReview?.update_time
                                        )}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex gap-[3px]">
                                  {[...Array(5)].map((_, index) => (
                                    <svg
                                      key={index}
                                      width="20"
                                      height="20"
                                      fill="none"
                                    >
                                      <path
                                        fill={
                                          index <
                                          wordsToNumber[
                                            selectedReview?.star_rating
                                          ]
                                            ? "#F9C33C"
                                            : "#DDD"
                                        }
                                        d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 0 0 .95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 0 0-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 0 0-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 0 0-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 0 0 .951-.69l1.07-3.292-.001-.001Z"
                                      />
                                    </svg>
                                  ))}
                                </div>
                              </div>
                            </div>

                            <p className="text-[#777] dark:text-white text-sm font-pro mt-[10px]">
                              {selectedReview?.comment}
                            </p>

                            {selectedReview?.review_reply ? (
                              <div className="p-5 rounded-[12px] bg-[#f0f5ff] dark:bg-darkLightBg font-pro text-sm text-[#4a4a4a] dark:text-white">
                                {isEditable ? (
                                  <>
                                    <Textarea
                                      value={reviewReply}
                                      onChange={(e) =>
                                        setReviewReply(e.target.value)
                                      }
                                    />

                                    <div className="flex gap-1 mt-3">
                                      <Button
                                        variant="ghost"
                                        onClick={handleAiReply}
                                        className="gap-3 hover:bg-none"
                                      >
                                        <Sparkles /> Regenerate Reply
                                      </Button>
                                      <Button
                                        variant="ghost"
                                        onClick={handleEditReviewReply}
                                        className="hover:bg-none"
                                      >
                                        Confirm
                                      </Button>
                                      <Button
                                        variant="ghost"
                                        onClick={() => {
                                          setIsEditable(false);
                                          setReviewReply(originalReviewReply);
                                        }}
                                        className="hover:bg-none"
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {reviewReply}
                                    <div className="mt-3">
                                      {selectedReview?.review_reply_update_time &&
                                        dayFormatterLong(
                                          selectedReview?.review_reply_update_time
                                        )}
                                    </div>
                                    <div className="flex gap-1 mt-3">
                                      {!filter && (
                                        <>
                                          <Button
                                            variant="ghost"
                                            onClick={handleAiReply}
                                            className="gap-3 hover:bg-none"
                                          >
                                            <Sparkles /> Regenerate Reply
                                          </Button>{" "}
                                        </>
                                      )}
                                      <Button
                                        variant="ghost"
                                        onClick={() => setIsEditable(true)}
                                        className="hover:bg-none"
                                      >
                                        Edit
                                      </Button>
                                      <Button
                                        variant="ghost"
                                        onClick={() =>
                                          setIsOpenDeleteModal(true)
                                        }
                                        className="hover:bg-none"
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </>
                                )}
                              </div>
                            ) : (
                              <>
                                {isEditable ? (
                                  <>
                                    <Textarea
                                      value={reviewReply}
                                      onChange={(e) =>
                                        setReviewReply(e.target.value)
                                      }
                                    />
                                    <div className="flex justify-end gap-1.5 mt-3">
                                      {!filter ? (
                                        <>
                                          <Button
                                            variant="outline"
                                            onClick={handleAiReply}
                                            className="gap-3"
                                          >
                                            <Sparkles /> Regenerate Reply
                                          </Button>{" "}
                                          <Button
                                            onClick={handleEditReviewReply}
                                          >
                                            Post
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            onClick={() => {
                                              setIsEditable(false);
                                              setReviewReply("");
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        </>
                                      ) : (
                                        <>
                                          <Button
                                            variant="outline"
                                            onClick={handleAiReply}
                                            className="gap-3"
                                          >
                                            <Sparkles /> Generate Reply
                                          </Button>
                                          <Button
                                            variant="secondary"
                                            onClick={handleEditReviewReply}
                                          >
                                            Confirm
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            onClick={() => {
                                              setIsEditable(false);
                                              setReviewReply(
                                                originalReviewReply
                                              );
                                            }}
                                          >
                                            Cancel
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                ) : (
                                  <div className="flex justify-end gap-3">
                                    {!filter ? (
                                      <>
                                        <Button
                                          variant="outline"
                                          onClick={handleAiReply}
                                          className="gap-3"
                                        >
                                          <Sparkles /> Generate Reply
                                        </Button>{" "}
                                      </>
                                    ) : (
                                      <Button
                                        variant="outline"
                                        onClick={() => setIsEditable(true)}
                                        className="gap-3"
                                      >
                                        <Sparkles /> Reply
                                      </Button>
                                    )}
                                  </div>
                                )}
                              </>
                            )}

                            <DeleteModal
                              openModal={isOpenDeleteModal}
                              setOpenModal={setIsOpenDeleteModal}
                              title={`Are you sure you want to delete the reply?`}
                              isLoading={deleteReviewReplyLoading}
                              handleDelete={handleDeleteReviewReply}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="flex flex-col items-center justify-center w-full h-[60vh] dark:bg-darkLightBg rounded-lg font-pro">
                    <img
                      src="/illustrations/reviews.svg"
                      alt="Questions"
                      className="w-[100px] h-[100px] mx-auto mt-10"
                    />
                    <h1 className="mt-[18px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                      Waiting For New Reviews
                    </h1>
                    <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                      No Reviews To Respond Yet!
                    </p>
                  </div>
                )}
              </>
            )}
            {tab === "prompt" && (
              <div className="flex flex-col gap-5 font-pro">
                {/* <div className="lg:w-5/12 w-full">
              <div className="dark:bg-darkBg duration-500 bg-white dark:border-none rounded-lg shadow-md space-y-5 cursor-pointer p-5 lg:p-9 ">
                <div className="w-full space-y-3">
                  <label htmlFor="title" className="font-medium text-sm">
                    Title
                  </label>
                  <Input
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    id="title"
                  />
                </div>
                <div className="w-full space-y-3">
                  <label htmlFor="condition" className="font-medium text-sm">
                    Condition
                  </label>
                  <Textarea
                    value={condition}
                    onChange={(e) => setCondition(e.target.value)}
                    id="condition"
                  />
                </div>
                <div className="flex justify-end">
                  <Button className="gap-3" onClick={handleAddPositivePrompt}>
                    <SquarePlus /> Add Condition
                  </Button>
                </div>
              </div>
            </div> */}
                <button
                  className="rounded-[8px] text-white bg-newPrimary border border-newPrimary text-[14px] font-normal py-[10px]  dark:hover:bg-darkBg hover:bg-white hover:text-newPrimary transition-all duration-200  mb-[15px] flex items-center justify-center gap-2 px-5 w-fit "
                  onClick={() => {
                    setIsOpenCondition(true);
                  }}
                >
                  <SquarePlus className="w-5 h-5" /> Add condition
                </button>
                <div className="w-full">
                  <p className="mb-5 font-pro font-medium text-lg">
                    My Conditions
                  </p>
                  {conditions?.length > 0 ? (
                    <div className="w-full max-h-[60vh] overflow-y-auto scrollbar-thin pr-1 space-y-5">
                      {conditions?.map((item) => (
                        <ConditionCard key={item?.id} condition={item} />
                      ))}
                    </div>
                  ) : (
                    <div className="flex flex-col items-center justify-center w-full h-[40vh] dark:bg-darkLightBg rounded-lg font-pro">
                      <img
                        src="/illustrations/reviews.svg"
                        alt="Questions"
                        className="w-[100px] h-[100px] mx-auto mt-10"
                      />
                      <h1 className="mt-[18px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
                        Conditions
                      </h1>
                      <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
                        No Conditions Has Been Added Yet!
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <AddConditionModal
        openModal={isOpenCondition}
        setOpenModal={setIsOpenCondition}
        refetchPositive={refetchPositive}
      />
      <GmbIntegrationModal
        openModal={isOpenLinkModal}
        setOpenModal={setIsOpenLinkModal}
      />
    </Layout>
  );
};

export default AutoReply;

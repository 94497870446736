/* eslint-disable react/prop-types */
import { useRef, useEffect } from "react";
import confetti from "canvas-confetti";

const ConfettiComponent = ({ fire }) => {
  const canvasRef = useRef(null);

  useEffect(() => {
    if (fire) {
      // Create the confetti animation
      const myConfetti = confetti.create(canvasRef.current, {
        resize: true, // will fit the confetti within the canvas
        useWorker: true, // Improve performance with workers
      });

      myConfetti({
        particleCount: 100,
        spread: 160,
        origin: { y: 0.6 },
      });
    }
  }, [fire]); // fire triggers confetti effect when true

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 1010,
        pointerEvents: "none", // Allow interaction with other elements
      }}
    />
  );
};

export default ConfettiComponent;

import Skeleton from "react-loading-skeleton";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../components/ui/table";
import { dayFormatter } from "../../../utils/formatter";
import { useDarkMode } from "../../../context/DarkModeProvider";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../../components/ui/dropdown-menu";
import { Check, Ellipsis, X } from "lucide-react";
import { Button } from "../../../components/ui/button";
import { usePutCreditsRequestUpdate } from "../../../query/useMaster";
import { LogoLoading } from "../../../components/loading/loading";
import { toast } from "sonner";
import { useState } from "react";
import ScreenshotModal from "./ScreenshotModal";
import ViewPaymentModal from "./ViewPaymentModal";

/* eslint-disable react/prop-types */
const CreditsRequestTable = ({
  items,
  isLoading,
  isPendingSection,
  refetch,
}) => {
  const { darkMode } = useDarkMode();
  const [isOpenScreenshot, setIsOpenScreenshot] = useState(0);
  const { mutate: creditsRequest, isLoading: creditsRequestLoading } =
    usePutCreditsRequestUpdate();

  const handleCreditsRequest = ({ id, status }) => {
    creditsRequest(
      {
        id: id,
        data: {
          status: status,
        },
      },
      {
        onSuccess: (res) => {
          toast.success(res.message);
          isPendingSection && refetch();
        },
        onError: (err) => {
          if (err?.response?.data?.message) {
            toast.error(err?.response?.data?.message);
          } else {
            toast.error(
              `Something went wrong, try again later ${err.response.status}`
            );
          }
        },
      }
    );
  };

  if (creditsRequestLoading) {
    return <LogoLoading />;
  }

  return (
    <div>
      {items?.length <= 0 ? (
        <div className="flex flex-col items-center justify-center w-full h-[50vh] dark:bg-darkLightBg rounded-lg font-pro">
          <img
            src="/illustrations/invoice.svg"
            alt="Questions"
            className="w-[100px] h-[100px] mx-auto mt-10"
          />
          <h1 className="mt-[11px] text-[#323131] font-semibold text-[22px] dark:text-white font-pro">
            Credits Request
          </h1>
          <p className="mt-2 text-[#888888] dark:text-white text-center font-pro">
            Your credits request will appear here!
          </p>
        </div>
      ) : (
        <div className="rounded-xl overflow-hidden mt-3">
          <Table>
            <TableHeader>
              <TableRow className="text-[#888] font-medium font-pro">
                {[
                  "User",
                  "Email",
                  "Amount",
                  "Credit",
                  "Requested Date",
                  "Updated Date",
                  "Status",
                  "Payment Details",
                ]?.map((item) => (
                  <TableHead key={item}>{item}</TableHead>
                ))}
                {isPendingSection && <TableHead>Action</TableHead>}
              </TableRow>
            </TableHeader>
            <TableBody>
              {isLoading ? (
                <TableRow>
                  {new Array(9).fill("").map((_, i) => (
                    <TableCell key={i}>
                      <Skeleton height={30} baseColor={darkMode && "#262626"} />
                    </TableCell>
                  ))}
                </TableRow>
              ) : (
                <>
                  {items?.length > 0
                    ? items?.map((item) => (
                        <>
                          <TableRow className={`font-pro`} key={item?.id}>
                            <TableCell>
                              <div className="flex items-center">
                                <img
                                  className="w-6 h-6 rounded-full lg:mr-[9px] mr-[3px]"
                                  src={
                                    item?.user?.profile_picture
                                      ? item?.user?.profile_picture
                                      : "/new-logo.svg"
                                  }
                                  alt=""
                                  referrerPolicy="no-referrer"
                                />
                                <p>
                                  {item?.user?.first_name +
                                    " " +
                                    item?.user?.last_name}
                                </p>
                              </div>
                            </TableCell>
                            <TableCell>{item?.user?.email}</TableCell>
                            <TableCell>
                              {item?.currency &&
                                item?.credit_price?.toLocaleString("en-US", {
                                  style: "currency",
                                  currency: item?.currency,
                                })}
                            </TableCell>
                            <TableCell>{item?.credit}</TableCell>
                            <TableCell>
                              {item?.created_at &&
                                dayFormatter(item?.created_at)}
                            </TableCell>
                            <TableCell>
                              {item?.updated_at &&
                                dayFormatter(item?.updated_at)}
                            </TableCell>
                            <TableCell>
                              {item?.status === "approved" && (
                                <div className="w-[90px] h-6 rounded-[7px] bg-[#affebf] text-[#014b40] text-xs text-center flex justify-center items-center capitalize">
                                  {item?.status}
                                </div>
                              )}
                              {item?.status === "rejected" && (
                                <div className="w-[90px] h-6 rounded-[7px] text-[#8e0b21] bg-[#fed1d7] text-xs text-center flex justify-center items-center capitalize">
                                  {item?.status}
                                </div>
                              )}
                              {item?.status === "pending" && (
                                <div className="w-[90px] h-6 rounded-[7px] bg-[#ffd6a4] text-[#5e4200] text-xs text-center flex justify-center items-center capitalize">
                                  {item?.status}
                                </div>
                              )}
                            </TableCell>
                            <TableCell className="w">
                              <p
                                className="text-newPrimary underline cursor-pointer "
                                onClick={() => setIsOpenScreenshot(item?.id)}
                              >
                                View
                              </p>
                            </TableCell>
                            {/* <TableCell>
                              {item?.transaction_number || "-"}
                            </TableCell>
                            <TableCell>
                              {item?.screenshot ? (
                                <img
                                  className="w-6 h-6 rounded-md lg:mr-[9px] mr-[3px] cursor-pointer"
                                  src={item?.screenshot}
                                  alt=""
                                  referrerPolicy="no-referrer"
                                  onClick={() => setIsOpenScreenshot(item?.id)}
                                />
                              ) : (
                                "-"
                              )}
                            </TableCell>
                            <TableCell>{item?.message || "-"}</TableCell> */}
                            {isPendingSection && (
                              <TableCell>
                                <DropdownMenu>
                                  <DropdownMenuTrigger>
                                    <Ellipsis className="size-4 text-[#595959]" />
                                  </DropdownMenuTrigger>
                                  <DropdownMenuContent>
                                    <div className="font-pro text-xs">
                                      <Button
                                        variant="ghost"
                                        className="gap-2 text-black dark:text-white dark:hover:text-black justify-start w-full "
                                        onClick={() =>
                                          handleCreditsRequest({
                                            id: item?.id,
                                            status: "approved",
                                          })
                                        }
                                      >
                                        <Check className="size-4 " />
                                        Approve
                                      </Button>
                                      <DropdownMenuSeparator />
                                      <Button
                                        variant="ghost"
                                        className="gap-2 text-black dark:text-white dark:hover:text-black justify-start w-full"
                                        onClick={() =>
                                          handleCreditsRequest({
                                            id: item?.id,
                                            status: "rejected",
                                          })
                                        }
                                      >
                                        <X className="size-4" />
                                        Reject
                                      </Button>
                                    </div>
                                  </DropdownMenuContent>
                                </DropdownMenu>
                              </TableCell>
                            )}
                          </TableRow>
                          {isOpenScreenshot === item?.id && (
                            <ViewPaymentModal
                              isOpenModal={isOpenScreenshot === item?.id}
                              setIsOpenModal={setIsOpenScreenshot}
                              screenShot={item?.screenshot}
                              credits={item?.credit}
                              amount={item?.credit_price}
                              currency={item?.currency}
                              transactionNumber={item?.transaction_number}
                              message={item?.message}
                            />
                          )}
                        </>
                      ))
                    : null}
                </>
              )}
            </TableBody>
          </Table>
        </div>
      )}
    </div>
  );
};

export default CreditsRequestTable;

import axios from "axios";

const baseUrl = import.meta.env.VITE_API_URL;

const Apimanager = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

Apimanager.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("tempToken")
      ? sessionStorage.getItem("tempToken")
      : localStorage.getItem("token");
    if (token) {
      config.headers["Authorization"] = `Token ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Apimanager.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     if (err.response.status === 403) {
//       window.location.href = "/sign-in";
//     }
//     return Promise.reject(err);
//   }
// );

// Apimanager.interceptors.response.use(
//   (res) => {
//     return res;
//   },
//   async (err) => {
//     console.log("err", err);
//     // const originalConfig = err.config;

//     // if (originalConfig.url !== "signin" && err.response) {
//     //   if (err.response.status === 401 && !originalConfig._retry) {
//     //     originalConfig._retry = true;

//     //     try {
//     //       const refreshToken = await Apimanager.post(
//     //         "/api/v1/auth/refreshtoken",
//     //         {
//     //           refreshToken: localStorage.getItem("refreshToken"),
//     //         }
//     //       );

//     //       const accessToken = refreshToken.data;
//     //       localStorage.setItem("token", accessToken);

//     //       return Apimanager(originalConfig);
//     //     } catch (_error) {
//     //       return Promise.reject(_error);
//     //     }
//     //   }
//     // }

//     // return Promise.reject(err);
//   }
// );

export default Apimanager;

import { CookieIcon } from "lucide-react";
import { Button } from "./button";
import { useEffect, useState } from "react";
import { cn } from "../../utils/cn";
import { Link } from "react-router-dom";

export default function CookieConsent() {
  const [isOpen, setIsOpen] = useState(false); // Start closed by default
  const [hide, setHide] = useState(true); // Initially hidden

  const acceptCookies = () => {
    document.cookie =
      "cookieConsent=true; expires=Fri, 31 Dec 9999 23:59:59 GMT";
    fadeOut();
  };

  const declineCookies = () => {
    fadeOut();
  };

  const fadeOut = () => {
    setIsOpen(false);
    setTimeout(() => {
      setHide(true);
    }, 700);
  };

  useEffect(() => {
    if (!document.cookie.includes("cookieConsent=true")) {
      setIsOpen(true);
      setHide(false); // Show only if the cookie isn't set
    }
  }, []);

  if (hide) return null; // Avoid rendering the component when hidden

  return (
    <div
      className={cn(
        "fixed z-[200] bottom-0 left-0 right-0 sm:left-4 sm:bottom-4 w-full sm:max-w-md duration-700",
        isOpen
          ? "translate-y-0 opacity-100"
          : "translate-y-8 opacity-0",
        "transition-[opacity,transform]"
      )}
    >
      <div className="bg-white text-black rounded-md m-3 border border-newPrimary shadow-lg">
        <div className="grid gap-2">
          <div className="border-b border-newPrimary h-14 flex items-center justify-between p-4">
            <h1 className="text-lg font-medium">We use cookies</h1>
            <CookieIcon className="h-[1.2rem] w-[1.2rem]" />
          </div>
          <div className="p-4">
            <p className="text-sm font-normal text-start">
              We use cookies to ensure you get the best experience on our website. 
              For more information on how we use cookies, please see our cookie policy.
              <br />
              <br />
              <span className="text-xs">
                By clicking "<span className="font-medium opacity-80">Accept</span>", 
                you agree to our use of cookies.
              </span>
              <br />
              <Link to="/cookies-policy" className="text-xs underline">
                Learn more.
              </Link>
            </p>
          </div>
          <div className="flex gap-2 p-4 py-5 border-t border-newPrimary bg-white">
            <Button onClick={acceptCookies} className="w-full">
              Accept
            </Button>
            <Button
              onClick={declineCookies}
              className="w-full bg-white text-newPrimary border border-newPrimary"
            >
              Decline
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

import React, { useMemo } from "react";

/* eslint-disable react/prop-types */
const ButtonIcon = ({ children, className, title }) => {
  const memorizedChildren = useMemo(() => <>{children}</>, []);
  const memorizedClassName = useMemo(() => className, []);
  return (
    <div
      className={`${memorizedClassName} dark:hover:bg-darkLightBg hover:bg-gray-100 rounded-full w-auto h-auto p-1.5 cursor-pointer`}
      title={title}
    >
      {memorizedChildren}
    </div>
  );
};

const IconButton = React.memo(ButtonIcon);

export default IconButton;

/* eslint-disable react/prop-types */
import { Switch } from "@headlessui/react";
import IconButton from "../../dashboard/component/IconButton";
import { GripVertical, SquarePen, Trash2 } from "lucide-react";
import { Reorder, useDragControls } from "framer-motion";
import { useState } from "react";
import EditQuestionModal from "../../dashboard/component/EditQuestionModal";
import { DeleteQuestionModal } from "../../dashboard/component/modal";

const QuestionCard = ({ item, questionIndex }) => {
  // const dragControls = useDragControls();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  return (
    <div
      key={item?.id}
      id={item?.id}
      // value={item}
      // dragControls={dragControls}
      // dragListener={false}
      className="rounded-[15px] bg-white dark:bg-darkBg p-5 flex items-center gap-x-5"
    >
      {/* <GripVertical
        className="hover:cursor-grab active:cursor-grabbing"
        onPointerDown={(event) => dragControls.start(event)}
      /> */}
      <div className=" w-full flex items-center gap-x-5 select-none">
        <div className="w-full">
          <div className="flex justify-end items-center lg:hidden mb-5">
            <div className="flex items-center gap-3">
              <div className="flex items-center gap-x-1">
                <IconButton>
                  <SquarePen
                    className="text-[#888] dark:text-[#c3c3c3] w-5 h-5 cursor-pointer"
                    onClick={() => setOpenEditModal(true)}
                  />
                </IconButton>
                <IconButton>
                  <Trash2
                    className="text-red-500 w-5 h-5 cursor-pointer"
                    onClick={() => setOpenDeleteModal(true)}
                  />
                </IconButton>
              </div>
            </div>
          </div>

          <div className="flex flex-row justify-between items-start pb-4 w-full">
            <p className="text-[#333] dark:text-white font-pro font-medium text-[18px] break-words">
              {"Q." + (questionIndex + 1)} {item?.question}
            </p>

            <div className="lg:flex items-center gap-3 hidden">
              <div className="flex items-center gap-x-1">
                <IconButton>
                  <SquarePen
                    className="text-[#888] dark:text-[#c3c3c3] w-5 h-5 cursor-pointer"
                    onClick={() => setOpenEditModal(true)}
                  />
                </IconButton>
                <IconButton>
                  <Trash2
                    className="text-red-500 w-5 h-5 cursor-pointer"
                    onClick={() => setOpenDeleteModal(true)}
                  />
                </IconButton>
              </div>
            </div>
          </div>
          <div className=" py-2 grid grid-cols-1 md:grid-cols-2 lg:flex gap-[19px] lg:flex-wrap">
            {item?.response_options?.map((answer, i) => (
              <button
                key={i}
                className={`flex items-center px-4 py-2 border-2 rounded-lg w-full lg:w-fit text-[#4A4A4A] dark:text-white dark:border-[#454545] font-pro text-[14px]`}
              >
                <span
                  className={`w-5 h-5 border rounded-full mr-2 flex-shrink-0 dark:border-[#454545]`}
                ></span>
                {answer}
              </button>
            ))}
          </div>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 mt-5">
            {item?.category && (
              <div className="flex flex-wrap gap-3">
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#e1e8fe] font-pro text-sm text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                >
                  Category
                </div>
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#ffaf19]/25  font-pro text-sm text-center px-5  dark:bg-[#262626] dark:text-[#ffc200]`}
                >
                  {item?.category}
                </div>
              </div>
            )}
            {item?.keyword && (
              <div className="flex flex-wrap gap-3">
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#e1e8fe] font-pro text-sm text-center text-[#2a49b8] px-5 dark:bg-secondary/35 dark:text-white`}
                >
                  Keyword
                </div>
                <div
                  className={`py-1.5 rounded-lg w-fit bg-[#FD5E3B]/25  font-pro text-sm text-center px-5  dark:bg-[#262626] dark:text-[#ff6464]`}
                >
                  {item?.keyword}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      {openEditModal && (
        <EditQuestionModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          item={item}
          key={item?.id}
        />
      )}
      {openDeleteModal && (
        <DeleteQuestionModal
          openModal={openDeleteModal}
          setOpenModal={setOpenDeleteModal}
          questionIndex={item?.id}
        />
      )}
    </div>
  );
};

export default QuestionCard;

import Apimanager from "./axios";

export const getQuestionsForVsl = async ({ industryType }) => {
  const res = await Apimanager.get("/vsl/questions", {
    params: {
      industry_type: industryType,
    },
  });

  return res.data;
};

export const postGenerateReviewsForVsl = async (data) => {
  const res = await Apimanager.post("/vsl/generate_reviews", data);
  return res.data;
};
